import React, { useState } from "react";
import GridTable from "../../../library/custom/gridTable/GridTable";
import SearchIcon from "@mui/icons-material/Search";
import SettingTable from "../../incidents/incidentList/SettingTable";
import { TableLoader } from "../../../library/common/Loader";
import { OBSERVATION_ITEM_TYPE, PAGE_SIZE } from "../../../constant/constant";
import AddIcon from "@mui/icons-material/Add";
import NORow from "../../../library/custom/noRow/noRow";
import ReportRowManipulte from "../../admin/shared/ReportRowManipulate";
import Buttons from "../../../library/custom/button/Button";
import { checkPrivileges } from "../../../utils/rolesHelper";
import FilterList from "../../case/shared/FilterCaseList";
import FilterButton from "../../../library/custom/filterButton/filterButton";
import { getFormatedDate } from "../../../utils/helper";
import downloadIcon from "../../../assets/downloadIcon.svg";

const ObservationList = ({
  observationList,
  getObservationListData,
  isLoading,
  handleViewOnClick,
  addObservation,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState("");
  const [itemTypesId, setItemTypesId] = useState("");
  const [dateOfCaseFrom, setdateOfCaseFrom] = useState("");
  const [dateOfCaseTo, setdateOfCaseTo] = useState("");
  const [observationFilterData, setObservationFilterData] = useState({
    searchText: search,
    page: 1,
    pageSize: PAGE_SIZE,
    fromdate: "",
    todate: "",
    location: "",
    Status: status ? status : "",
    itemTypes: "",
    isSendMail: false,
  });

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [isTableLoading, setisTableLoading] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    window.close();
  };

  const dateOfCaseHandlerFrom = (e) => {
    setdateOfCaseFrom(e);
  };

  const dateOfCaseHandlerTO = (e) => {
    setdateOfCaseTo(e);
  };

  const columns = [
    { field: "insObservationId", headerName: "ID", flex: 1 },
    { field: "observationDate", headerName: "Date of Entry", flex: 1.5 },
    { field: "location", headerName: "Location/Jobsite", flex: 1.5 },
    { field: "observedTypeValue", headerName: "Item Type", flex: 1.5 },
    { field: "observedByName", headerName: "Added by", flex: 1.5 },
    { field: "statusText", headerName: "Status", flex: 1 },

    {
      field: "",
      flex: 1,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <ReportRowManipulte
            rows={rows}
            viewOnClick={(row) =>
              handleViewOnClick(
                row?.insObservationId,
                row?.observedType,
                row?.observedEmployee,
                row?.status
              )
            }
            view={checkPrivileges([12, 84, 85, 86])}
            viewLabel="View Observation"
          />
        );
      },
    },
  ];

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    getObservationListData({
      ...observationFilterData,
      page: newPage + 1,
    });
  };

  const handleautoSearch = (event) => {
    setPage(1);
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);

    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);

    setObservationFilterData({
      ...observationFilterData,
      isSendMail: false,
      searchText: event.target.value,
    });
    const data = {
      ...observationFilterData,
      searchText: event.target.value,
      page: 1,
      pageSize: PAGE_SIZE,
      location: "",
      fromdate: (dateOfCaseFrom && getFormatedDate(dateOfCaseFrom)) ?? "",
      todate: (dateOfCaseTo && getFormatedDate(dateOfCaseTo)) ?? "",
      Status: status ? status : "",
      itemTypes: itemTypesId ? itemTypesId : "",
      isSendMail: false,
    };
    getObservationListData(data);
  };

  const onRowClick = (rows) => {
    if (rows.value) {
      const { row } = rows;
      handleViewOnClick(
        row?.insObservationId,
        row?.observedType,
        row?.observedEmployee,
        row?.status
      );
    }
  };
  const onStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleApply = () => {
    setAnchorEl(null);
    window.close();
    setPage(1);
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    const data = {
      searchText: search,
      page: 1,
      pageSize: PAGE_SIZE,
      location: "",
      Status: status ? status : "",
      fromdate: (dateOfCaseFrom && getFormatedDate(dateOfCaseFrom)) ?? "",
      todate: (dateOfCaseTo && getFormatedDate(dateOfCaseTo)) ?? "",
      itemTypes: itemTypesId ? itemTypesId : "",
      isSendMail: false,
    };
    setObservationFilterData(data);
    getObservationListData(data);
  };

  const handleClear = () => {
    setItemTypesId();
    setAnchorEl(null);
    setStatus();
    setdateOfCaseFrom("");
    setdateOfCaseTo("");
    setPage(1);
    setSearch();
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    const data = {
      searchText: search,
      page: 1,
      pageSize: PAGE_SIZE,
      location: "",
      Status: "",
      itemTypes: "",
      isSendMail: false,
    };
    setObservationFilterData(data);
    getObservationListData(data);
  };

  const typeOnItemTypeChanges = (e) => {
    setItemTypesId(e?.target?.value);
  };

  const sendEmail = () => {
    const data = {
      searchText: search,
      page: 1,
      pageSize: PAGE_SIZE,
      location: "",
      Status: status ? status : "",
      fromdate: (dateOfCaseFrom && getFormatedDate(dateOfCaseFrom)) ?? "",
      todate: (dateOfCaseTo && getFormatedDate(dateOfCaseTo)) ?? "",
      itemTypes: itemTypesId ? itemTypesId : "",
      isSendMail: true,
    };
    setObservationFilterData(data);
    getObservationListData(data);
  };

  return (
    <>
      <section className="grid_main_sec">
        <div className="grid_main_header two_grid">
          <div className="grid_heading_style">View Observations</div>
          <div>
            {checkPrivileges([12, 84, 85]) && (
              <Buttons
                varientContained={true}
                label={" + Add Observation"}
                startIcon={<AddIcon />}
                onClick={() => addObservation()}
              />
            )}
          </div>
        </div>
        <div className="grid_main_body">
          <div className="div-filter-container">
            <div className="filter_of_caselist">
              <div className="searchcaseid">
                <label htmlFor="search">
                  <span>
                    <SearchIcon />
                  </span>
                  <div>
                    <input
                      id="search"
                      placeholder="Search"
                      type="text"
                      value={search}
                      className=""
                      onChange={handleautoSearch}
                      autoComplete="off"
                      data-testid="search-input"
                    />
                  </div>
                </label>
              </div>
              <div className="filterbtn">
                <FilterButton
                  label={"Filters"}
                  handleClick={(e) => handleClick(e)}
                />
                <FilterList
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  resetFilter={handleClear}
                  handleSearch={handleApply}
                  status={status}
                  observationStatus={true}
                  onStatusChange={onStatusChange}
                  itemTypes={true}
                  itemTypesId={itemTypesId}
                  typeOnItemTypeChanges={typeOnItemTypeChanges}
                  dateFilter={true}
                  listTypeValues={OBSERVATION_ITEM_TYPE}
                  dateOfCaseFrom={dateOfCaseFrom}
                  dateOfCaseHandlerFrom={dateOfCaseHandlerFrom}
                  dateOfCaseTo={dateOfCaseTo}
                  dateOfCaseHandlerTO={dateOfCaseHandlerTO}
                />
              </div>
            </div>
            <div className="div-download-email-right" onClick={sendEmail}>
              <span>
                <img
                  src={downloadIcon}
                  className="DownLoadLogo"
                  alt="DownLoad Logo"
                />
              </span>
              Download Data
            </div>
          </div>
          <div className="desc_box row_uniq">
            <div className="table_style_ui">
              {isTableLoading ? (
                ""
              ) : (
                <GridTable
                  getRowId={(r) => r.insObservationId}
                  rows={observationList?.observationData ?? []}
                  rowCount={observationList?.noOfRecords ?? 0}
                  onClick={(rows) => onRowClick(rows)}
                  columns={columns}
                  loading={isLoading}
                  page={page}
                  pagination
                  pageSize={PAGE_SIZE}
                  rowsPerPageOptions={[PAGE_SIZE]}
                  onPageChange={handlePagignation}
                  backIconButtonProps={{ disabled: false }}
                  disableSelectionOnClick
                  components={{
                    LoadingOverlay: TableLoader,
                    NoRowsOverlay: NORow,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ObservationList;
