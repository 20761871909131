import React, { useRef, useMemo, useState, useEffect } from "react";
import JoditEditor from "jodit-react";
import "./TextEditor.scss";
import { FormHelperText } from "@mui/material";
import { TEXT_EDITOR_CHARECTER_LIMIT } from "../../../constant/constant";
import { ToastContainer, toast } from "react-toastify";

export default function TextEditor({
  id = "",
  value,
  onChange,
  isError = false,
  errorMsg = "",
  placeholder,
  enableDragAndDropFileToEditor = false,
  insertImageAsBase64URI = false,
  showXPathInStatusbar = false,
  showCharsCounter = false,
  showWordsCounter = false,
  toolbarAdaptive = false,
  toolbarSticky = false,
  removeDefaultButtons = [
    "brush",
    "file",
    "image",
    "fullsize",
    "link",
    "table",
    "paint",
    "source",
    "font",
    "fontsize",
    "brush",
    "paragraph",
    "image",
    "table",
    "link",
    "hr",
    "eraser",
    "fullsize",
    "undo",
    "redo",
    "ol",
  ],
  defaultButtons = [
    "bold",
    "italic",
    "underline",
    "|",
    "ul",
    "|",
    "left",
    "center",
    "right",
    "justify",
  ],
}) {
  const editor = useRef(null);
  const [charLimitError, setCharLimitError] = useState(false);
  const [pasteError, setPasteError] = useState("");
  const [editorInstance, setEditorInstance] = useState(null);

  const config = useMemo(() => {
    let editorInstance = null;

    return {
      readonly: false,
      width: "100%",
      enableDragAndDropFileToEditor: enableDragAndDropFileToEditor,
      placeholder: placeholder || "Start typing...",
      buttons: defaultButtons,
      uploader: { insertImageAsBase64URI: insertImageAsBase64URI },
      removeButtons: removeDefaultButtons,
      showXPathInStatusbar: showXPathInStatusbar,
      showCharsCounter: showCharsCounter,
      showWordsCounter: showWordsCounter,
      toolbarAdaptive: toolbarAdaptive,
      toolbarSticky: toolbarSticky,
      limitChars: TEXT_EDITOR_CHARECTER_LIMIT,
      insertImageAsBase64URI: false,
      replaceDataURIToBlobIdInView: false,
      events: {
        afterInit: (instance) => {
          editorInstance = instance;
          setEditorInstance(instance);
        },
        keydown: (event) => {
          if (editorInstance) {
            // Get the clean text without HTML tags
            const currentText = editorInstance.value
              .replace(/<[^>]*>/g, "")
              .replace(/&nbsp;/g, " ")
              .replace(/\s+/g, " ");

            const textLength = currentText.length;
            // Allow special keys (Backspace, Delete, Arrow keys, etc.)
            const allowedKeys = [
              "Backspace",
              "Delete",
              "ArrowLeft",
              "ArrowRight",
              "Tab",
            ];
            if (allowedKeys.includes(event.key)) {
              setCharLimitError(false);
              return;
            }

            // Block input if the character limit is reached
            if (textLength >= TEXT_EDITOR_CHARECTER_LIMIT) {
              event.preventDefault();
              setCharLimitError(true);
            } else {
              setCharLimitError(false);
            }
          }
        },
      },
    };
  }, [TEXT_EDITOR_CHARECTER_LIMIT]);

  // Add Jodit event handlers
  useEffect(() => {
    if (editorInstance) {
      editorInstance.events.on("paste", handlePaste);
      return () => {
        editorInstance.events.off("paste", handlePaste);
      };
    }
  }, [editorInstance]);

  const handlePaste = (event) => {
    const items = event.clipboardData?.items || [];
    let hasImage = false;
    let pastedText = "";
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.includes("image")) {
        hasImage = true;
        break;
      }
    }
    if (hasImage) {
      event.preventDefault();
      if (!pasteError) {
        toast.error("Images not accepted.");
        setPasteError("Images not accepted.");
        setTimeout(() => setPasteError(""), 3000);
      }
      return;
    }
    if (event.clipboardData?.getData("Text")) {
      pastedText = event.clipboardData.getData("Text");
      const cleanedText = pastedText
        .replace(/<[^>]*>/g, "")
        .replace(/&nbsp;/g, "")
        .replace(/\s+/g, " ")
        .trim();

      if (cleanedText.length > TEXT_EDITOR_CHARECTER_LIMIT) {
        event.preventDefault();
        if (!pasteError) {
          toast.error("Max character limit is 2000 characters.");
          setPasteError("Max character limit is 2000 characters.");
          setTimeout(() => setPasteError(""), 3000);
        }
        return;
      }
    }

    // Reset errors if valid paste
    setCharLimitError(false);
    setPasteError("");
  };

  function debounce(func, delay) {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }

  const debouncedHandleChange = debounce((e) => {
    setCharLimitError(false);
    setPasteError("");

    const cleanedText = e
      .replace(/<[^>]*>/g, "") // Remove HTML tags
      .replace(/&nbsp;/g, " "); // Replace &nbsp; with space

    const length = cleanedText.length;

    if (length >= TEXT_EDITOR_CHARECTER_LIMIT) {
      setCharLimitError(true);
      return;
    } else {
      onChange(e);
    }
  }, 500);

  function handleChange(e) {
    debouncedHandleChange(e);
    const cleanedText = e
      .replace(/<[^>]*>/g, "") 
      .replace(/&nbsp;/g, " "); 

    const length = cleanedText.length;
    if (length > TEXT_EDITOR_CHARECTER_LIMIT) {
      setCharLimitError(true);
      return;
    }
    setCharLimitError(false);
    onChange(e);

    const currentEditorInstance = editor.current?.editor;
    const cursorPosition = currentEditorInstance?.getCursor();
    // Restore the cursor position after state update
    setTimeout(() => {
      if (currentEditorInstance) {
        currentEditorInstance.setCursor(cursorPosition);
      }
    }, 0);
  }

  return (
    <div className="overideTextArea">
      <ToastContainer />
      <div className="textAreaBody" onPaste={handlePaste}>
        {isError && value?.length === 0 && (
          <FormHelperText className="errorText">{errorMsg}</FormHelperText>
        )}

        <JoditEditor
          id={id}
          ref={editor}
          value={value}
          config={config}
          tabIndex={1}
          onChange={(e) => handleChange(e)}
        />
      </div>
      {charLimitError && (
        <p className="errorText">Max character limit reached</p>
      )}
    </div>
  );
}
