import React from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import "./basicInformation.scss";
import parse from "html-react-parser";
import { INSPECTION_HEADER_RESPONSE_TYPE } from "../../../../../constant/constant";
import { findValueByKeyWeather } from "../../../../../utils/incpectionHelperNew";

const BasicInformation = ({ basicInformationData, headerFields, weather }) => {
  const findValueByKey = (key, valueInput) => {
    const field = headerFields?.find(
      (field) => parseInt(field.key) === parseInt(key)
    );
    if (field) {
      const { value, responsetype, optionList } = field;
      if (responsetype === INSPECTION_HEADER_RESPONSE_TYPE.TEXTBOX) {
        return (
          <>
            <div className="commonColumnNew basicContainerNew">
              <div className="w-100 dynmicHeading">
                {parse(unescape(value))}:
              </div>
              <span className="w-100">
                {valueInput ? parse(unescape(valueInput)) : "N/A"}
              </span>
            </div>
          </>
        );
      } else if (
        responsetype === INSPECTION_HEADER_RESPONSE_TYPE.PILLS_BUTTON
      ) {
        return (
          <>
            <div className="commonColumnNew basicContainerNew">
              <div className="w-100">
                <div className="dynmicHeading">
                  {parse(unescape(value))}: {" "}
                  <span className="w-100">
                    {valueInput
                      ?  parse(unescape(findValueByKeyWeather(valueInput, weather)))
                      : " N/A"}
                  </span>
                </div>
              </div>
            </div>
          </>
        );
      } else if (
        parseInt(responsetype) === INSPECTION_HEADER_RESPONSE_TYPE.SINGLE_SELECT
      ) {
        const selectedOption = optionList?.find(
          (option) => parseInt(option.key) === parseInt(valueInput)
        );
        return (
          <div className="commonColumnNew">
            <div className="dynmicHeading">{value}:</div>
            <span>
              {valueInput ? parse(unescape(selectedOption?.value)) : "N/A"}
            </span>
          </div>
        );
      } else if (
        responsetype === INSPECTION_HEADER_RESPONSE_TYPE.CHECKBOX ||
        responsetype === INSPECTION_HEADER_RESPONSE_TYPE.MULTI_SELECT
      ) {
        const formattedValue = valueInput
          ?.split(",")
          ?.map((val) => val?.trim())
          ?.join(", ");
        return (
          <div className="commonColumnNew">
            <div className="dynmicHeading">{value}:</div>
            <span>
              {formattedValue ? parse(unescape(formattedValue)) : "N/A"}
            </span>
          </div>
        );
      } else if (
        responsetype === INSPECTION_HEADER_RESPONSE_TYPE.INPUT_TEXTBOX
      ) {
        return (
          <div className="commonColumnNew">
            <div className="dynmicHeading">{value}:</div>
            <span>{valueInput ? parse(unescape(valueInput)) : "N/A"}</span>
          </div>
        );
      } else if (responsetype === INSPECTION_HEADER_RESPONSE_TYPE.RADIOBUTTON) {
        return (
          <div className="commonColumnNew">
            <div className="dynmicHeading">{value}:</div>
            <span>{valueInput ? parse(unescape(valueInput)) : "N/A"}</span>
          </div>
        );
      } else {
        if (
          responsetype !== INSPECTION_HEADER_RESPONSE_TYPE.TIME ||
          responsetype !== INSPECTION_HEADER_RESPONSE_TYPE.DATE ||
          responsetype !== INSPECTION_HEADER_RESPONSE_TYPE.AUTOSEARCH
        ) {
        } else {
          return (
            <>
              <div className="commonColumnNew">
                <div className="dynmicHeading">{value}:</div>
                <span>{valueInput ? parse(unescape(valueInput)) : "N/A"}</span>
              </div>
            </>
          );
        }
      }
    }
    return "";
  };

  return (
    <div className="basicOveride">
      <Box className="basicinfoWrap">
        <Box className="basicinfoBox">
          <Box className="basicinfoleft width-100">
            <Typography className="title">Basic Information:</Typography>
            <Box className="datetime">
              <Typography className="listView">
                Inspected by:
                <span className="focusedText">
                  {basicInformationData?.inspected_By}
                </span>
              </Typography>
              <Typography className="listView">
                Asset Name:
                <span className="focusedText">
                  {basicInformationData?.asset}
                </span>
              </Typography>
            </Box>
            <Box className="datetime">
              <Typography className="listView">
                Date of Inspection:
                <span className="focusedText">
                  {basicInformationData?.dateOfIns}
                </span>
              </Typography>
              <Typography className="listView">
                Time of Inspection:
                <span className="focusedText">
                  {basicInformationData?.timeOfIns}
                </span>
              </Typography>
            </Box>

            <Box className="datetime dynmicFeild">
              {headerFields?.map((item) => (
                <>{findValueByKey(item?.key, item?.ans)}</>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default BasicInformation;