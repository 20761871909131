import React, { useEffect, useState } from "react";
import ViewSafetyLog from "../component/safetyLog/safetyLog";
import { useDispatch, useSelector } from "react-redux";
import * as lmsUserAction from "../../../redux/actions/lmsUserAction";
import * as userAction from "../../../redux/actions/userAction";
import * as incpectionAction from "../../../redux/actions/incpectionAction";
import * as jhaAction from "../../../redux/actions/jhaAction";
import { useLocation, useNavigate } from "react-router-dom";
import { ACCESS_DENIED } from "../../../constant/routeContant";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { toast, ToastContainer } from "react-toastify";
import { API_STATUS } from "../../../constant/constant";
import * as toolboxAction from "../../../redux/actions/toolBoxAction";
import * as adminAction from "../../../redux/actions/adminCommonAction";
import { ORG_ID } from "../../../constant/constant";

export default function SafetyLogContainer() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const navigationObj = location?.state;
  const [sendEmail, setSendEmail] = useState(false);
  const [tabid, setTabid] = useState("");

  const PAGE_SIZE_SIX = 6;

  const itemLogData = useSelector((state) => state.getItemList?.data);
  const itemLogDataStatus = useSelector((state) => state.getItemList);

  const allUsers = useSelector((state) => state.allUsers);
  const searchOption = useSelector((state) => state.users.data);

  function getAllUsers(searchText, type, divisionId) {
    dispatch(jhaAction.clearJha(searchText, type, divisionId));
    dispatch(userAction.searchAllUsers(searchText, type, divisionId));
  }

  const isGetItemLogLoading = useSelector(
    (state) => state.getItemList?.isLoading
  );
  const isLoading = allUsers?.isLoading;
  const coursesUserListData = useSelector((state) => state.getUserCoursesList);
  const isCoursesLoading = useSelector(
    (state) => state.getUserCoursesList?.isLoading
  );
  const lessionUserListData = useSelector((state) => state.getUserLessonsList);
  const jhaConductListData = useSelector(
    (state) => state.getSaftyLogJHAConductList
  );
  const getLessionUserListData = (data) => {
    if (data?.isSendMail) {
      setSendEmail(true);
    } else {
      setSendEmail(false);
    }
    dispatch(lmsUserAction.getUserLessonsList(data));
  };
  const isLessonLoading = useSelector(
    (state) => state.getUserLessonsList?.isLoading
  );

  const getItemLogKudosData = (data) => {
    if (data?.isSendMail) {
      setSendEmail(true);
    } else {
      setSendEmail(false);
    }
    dispatch(incpectionAction.getItemList(data));
  };

  const getItemLogCorrectiveData = (data) => {
    if (data?.isSendMail) {
      setSendEmail(true);
    } else {
      setSendEmail(false);
    }
    dispatch(incpectionAction.getItemList(data));
  };

  const getItemLogReprimandData = (data) => {
    if (data?.isSendMail) {
      setSendEmail(true);
    } else {
      setSendEmail(false);
    }
    dispatch(incpectionAction.getItemList(data));
  };

  const getCoursesUserListData = (data) => {
    if (data?.isSendMail) {
      setSendEmail(true);
    } else {
      setSendEmail(false);
    }
    dispatch(lmsUserAction.getUserCoursesList(data));
  };
  const getSaftyLogJHAConductList = (data) => {
    if (data?.isSendMail) {
      setSendEmail(true);
    } else {
      setSendEmail(false);
    }
    dispatch(jhaAction.getSaftyLogJHAConductList(data));
  };
  useEffect(() => {
    dispatch(lmsUserAction.clearCourseList(""));
    dispatch(lmsUserAction.clearLesssonList(""));
  }, []);

  useEffect(() => {
    if (coursesUserListData?.status === API_STATUS.SUCCESS && sendEmail) {
      toast("Courses has been sent to registered email ID");
    }
  }, [coursesUserListData]);

  useEffect(() => {
    if (lessionUserListData?.status === API_STATUS.SUCCESS && sendEmail) {
      toast("Lessons has been sent to registered email ID");
    }
  }, [lessionUserListData]);

  useEffect(() => {
    if (
      itemLogDataStatus?.status === API_STATUS.SUCCESS &&
      sendEmail &&
      tabid === 1
    ) {
      toast("Kudos has been sent to registered email ID");
    }
  }, [itemLogDataStatus]);

  useEffect(() => {
    if (
      itemLogDataStatus?.status === API_STATUS.SUCCESS &&
      sendEmail &&
      tabid === 2
    ) {
      toast("Reprimand has been sent to registered email ID");
    }
  }, [itemLogDataStatus]);

  useEffect(() => {
    if (
      itemLogDataStatus?.status === API_STATUS.SUCCESS &&
      sendEmail &&
      tabid === 3
    ) {
      toast("Correctives has been sent to registered email ID");
    }
  }, [itemLogDataStatus]);
  useEffect(() => {
    if (
      jhaConductListData?.status === API_STATUS.SUCCESS &&
      sendEmail &&
      tabid === 4
    ) {
      toast("Hazard Analysis has been sent to registered email ID");
    }
  }, [jhaConductListData]);

  const cleardata = () => {
    dispatch(lmsUserAction.clearCourseList(""));
    dispatch(lmsUserAction.clearLesssonList(""));
    dispatch(toolboxAction.clearMySafetyMeetingList(""));
  };
  const mySafetyMeetingListResult = useSelector(
    (state) => state.mySafetyMeetingList?.data
  );

  const getMySafetyMeetingListData = (data) => {
    dispatch(toolboxAction.clearUserDetailTalk(data));
    dispatch(toolboxAction.getMySafetyMeetingList(data));
  };

  const categoryListData = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.toolboxCategories
  );
  const getDropdownDataKeyValueData = {
    orgId: ORG_ID(),
    flags: [20],
  };

  useEffect(() => {
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
  }, []);
  return (
    <>
      {checkPrivileges([12, 135]) ? (
        <>
          <div className="dashbord_heading">
            <div className="insi-stitle">
              <p>Safety Log</p>
            </div>
          </div>
          <ToastContainer />
          <ViewSafetyLog
            getItemLogKudosData={getItemLogKudosData}
            getItemLogCorrectiveData={getItemLogCorrectiveData}
            getItemLogReprimandData={getItemLogReprimandData}
            coursesUserListData={coursesUserListData?.data}
            isCoursesLoading={isCoursesLoading}
            getCoursesUserListData={getCoursesUserListData}
            lessionUserListData={lessionUserListData.data}
            getLessionUserListData={getLessionUserListData}
            isLessonLoading={isLessonLoading}
            allUsers={allUsers && allUsers?.data}
            itemLogData={itemLogData}
            searchOption={searchOption?.users || []}
            onSearch={getAllUsers}
            isLoading={isLoading}
            isGetItemLogLoading={isGetItemLogLoading}
            PAGE_SIZE_SIX={PAGE_SIZE_SIX}
            getSaftyLogJHAConductList={getSaftyLogJHAConductList}
            jhaConductList={jhaConductListData}
            navigationObj={navigationObj}
            setTabid={setTabid}
            getToolBoxTalkListResult={mySafetyMeetingListResult}
            getToolBoxTalkListData={getMySafetyMeetingListData}
            cleardata={cleardata}
            categoryListData={categoryListData}
          />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
}
