import { useEffect } from "react";
import "./verifyEmail.scss";
import { logos } from "../../assets";

const VerifyEmail = () => {
  const queryString = window.location.search;

  useEffect(() => {
    console.log("VerifyEmail queryString", queryString);
  }, []);

  return (
    <>
      <div className="verifyEmail">
        <div className="verifyEmail__container">
          <div className="verifyEmail__container__logo">
            <img src={logos} alt="logo" />
          </div>
          <div className="verifyEmail__container__content">
            <h1>Email Verified Successfully</h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyEmail;
