import React, { useEffect, useState } from "react";
import IncidentList from "../components/incidentList";
import * as incidentAction from "../../../redux/actions/incidentAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ACCESS_DENIED, PERSONAL_INJURY } from "../../../constant/routeContant";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { ToastContainer, toast } from "react-toastify";
import { API_STATUS } from "../../../constant/constant";
import { InactiveClient } from "../../../utils/helper";

const IncidentListContiner = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const incidentList = useSelector((state) => state.incidentList);
  const incidentListDelete = useSelector((state) => state.deleteIncidentsData);
  const closeIncidentData = useSelector((state) => state.closeIncident);
  const [sendEmail, setSendEmail] = useState(false);
  const isLoader = useSelector(
    (state) =>
      state.incidentList.isLoading ||
      state.incident.isLoading ||
      state.deleteIncidentsData.isLoading ||
      state.closeIncident.isLoading
  );

  useEffect(() => {
    if (closeIncidentData?.status === "SUCCESS")
      dispatch(incidentAction.getIncidentList({ pageNumber: 1 }));
  }, [closeIncidentData, dispatch]);

  useEffect(() => {
    if (incidentListDelete?.status === "SUCCESS")
      dispatch(incidentAction.getIncidentList({ pageNumber: 1 }));
  }, [incidentListDelete, dispatch]);

  const getIncidentListData = (data) => {
    if (data?.isSendMail) {
      setSendEmail(true);
    } else {
      setSendEmail(false);
    }
    dispatch(incidentAction.getIncidentList(data));
  };
  const resetData = () => {
    getIncidentListData({ pageNumber: 1, isSendMail: false });
  };
  useEffect(() => {
    getIncidentListData({ pageNumber: 1, isSendMail: false });
  }, []);

  const createCase = () => {
    if (InactiveClient()) return;
    navigate(PERSONAL_INJURY);
  };

  const [clearMessage, setClearMessage] = useState(false);

  const deleteIncidents = (deleteIncedent) => {
    setClearMessage(true);
    dispatch(incidentAction.deleteIncidents(deleteIncedent.reportId));
  };

  const closeIncidents = (closeIncident) => {
    dispatch(
      incidentAction.closeIncident({ incidentId: closeIncident.reportId })
    );
  };

  useEffect(() => {
    if (
      incidentListDelete?.status === "SUCCESS" &&
      incidentListDelete?.isLoading === false &&
      clearMessage
    ) {
      toast("Incident Deleted successfully");
    }
  }, [incidentListDelete]);

  useEffect(() => {
    if (incidentList?.status === API_STATUS.SUCCESS && sendEmail) {
      toast("Incident has been sent to registered email ID");
    }
  }, [incidentList]);

  return (
    <>
      {checkPrivileges([1, 2, 3, 12]) ? (
        <>
          <ToastContainer />

          <IncidentList
            onClick={createCase}
            incidentList={isLoader ? [] : incidentList && incidentList.data}
            getIncidentListData={getIncidentListData}
            deleteIncidents={deleteIncidents}
            isLoader={isLoader}
            resetData={resetData}
            isFullAccess={checkPrivileges([1, 12])}
            isWriteAccess={checkPrivileges([2, 12])}
            isReadAccess={checkPrivileges([3, 12])}
            closeIncidents={closeIncidents}
          />
          <ToastContainer />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
};

export default IncidentListContiner;
