// import { MODULES_NAME } from "../constant/constant";
// import { usersAccess } from "./rolesHelper";
import ReactGA from "react-ga4";
import {
  DOCUMENT_UPLOAD_MAX_SIZE,
  SERVICE_TYPE,
  ASSET_TYPE,
  GET_ITEM_LOG,
  ORG_STATUS,
  LOGIN_ORG_ID,
  ORG_ID,
} from "../constant/constant";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useEffect, useRef } from "react";

// limit the number of charaters in input
import imageCompression from "browser-image-compression";
import _ from "lodash";
import moment from "moment";
import { toast } from "react-toastify";

export const maxLengthCheck = (object) => {
  if (object.target.value.length > object.target.maxLength) {
    object.target.value = object.target.value.slice(0, object.target.maxLength);
  }
};

// handle the API error data
export const apiError = (errorObject) => {
  return errorObject.response ? errorObject.response : errorObject;
};

export const getFormatedDate = (date) => {
  if (!date) return "";
  const today = new Date(date);
  if (isNaN(today)) return "";
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0");
  const yyyy = today.getFullYear();
  return `${mm}-${dd}-${yyyy}`;
};

// get localTime mm-dd-yyyy hh:mm from utcDate time
export const convertUTCtoLocalTimeZone = (utcDateString) => {
  if (_.isEmpty(utcDateString)) return "";
  const date = utcDateString.endsWith("Z")
    ? utcDateString
    : utcDateString + "Z";
  const utcDate = new Date(date);
  // Manually extract the date components
  const year = utcDate.getFullYear();
  const month = String(utcDate.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(utcDate.getDate()).padStart(2, "0");
  const hours = String(utcDate.getHours()).padStart(2, "0");
  const minutes = String(utcDate.getMinutes()).padStart(2, "0");

  // Combine into the desired "mm-dd-yyyy hh:mm" format
  return `${month}-${day}-${year} ${hours}:${minutes}`;
};
export const caseFormatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("/");
};

export const getCurrentDate = (swCurrent) => {
  const today = new Date();
  const padZero = (num) => (num < 10 ? "0" + num : num);
  const yyyy = today.getFullYear();
  const mm = padZero(today.getMonth() + 1); // January is 0, so add 1
  const dd = padZero(today.getDate());
  return swCurrent ? `${mm}-${dd}-${yyyy}` : `${yyyy}-${mm}-${dd}`;
};

export const getCurrentTime = () => {
  const today = new Date();
  return today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
};

export const getCurrentMinuteSecond = () => {
  const today = new Date();
  return `${convertTwoDigit(today.getHours())}:${convertTwoDigit(
    today.getMinutes()
  )}`;
};

export const convertTime = (time) => {
  const today = new Date();
  if (time) {
    const timeSplit = time?.split(":");
    return timeSplit[0] + ":" + timeSplit[1] + ":" + today?.getSeconds();
  } else {
    return time;
  }
};

const convertTwoDigit = (val) => {
  if (String(val).length === 1) {
    return `0${val}`;
  } else {
    return String(val);
  }
};

export const getDynamicFields = (dynamicFields, fieldId) => {
  const data = dynamicFields?.find((x) => x.fieldId === fieldId)?.fieldValue;
  return data;
};

export const removeWordSpace = (value) => {
  return value.replace(/ /g, "").toLowerCase();
};

export const findObject = (list, value) => {
  if (list && value) {
    const findData = list.find((dataFind) => dataFind.key == value);
    return `${findData.key}|${findData.value}`;
  }
  return "";
};

export const getFirstValue = (value) => {
  if (value && value.includes("|")) {
    return value.split("|")[0];
  }
  return "";
};

export const getSignatureValue = (value) => {
  let isSignature = false;
  if (value && value.includes("|")) {
    const signatureText = value.split("|")[0];
    isSignature = signatureText.toLowerCase() === "true";
  }
  return isSignature;
};

export function dateValiadtor(date) {
  let dateformat =
    /^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}$/;
  // Matching the date through regular expression
  if (date == "Invalid Date") return false;
  if (date.match(dateformat)) {
    let operator = date.split("-");
    // Extract the string into month, date and year
    let datepart = [];
    if (operator.length > 1) {
      datepart = date.split("-");
    }
    let month = parseInt(datepart[0]);
    let day = parseInt(datepart[1]);
    let year = parseInt(datepart[2]);

    // Create a list of days of a month
    let ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (month === 1 || month > 2) {
      if (day > ListofDays[month - 1]) {
        //to check if the date is out of range
        return false;
      }
    } else if (month === 2) {
      let leapYear = false;
      if ((!(year % 4) && year % 100) || !(year % 400)) leapYear = true;
      if (leapYear === false && day >= 29) return false;
      else if (leapYear === true && day > 29) {
        return false;
      }
    }
  } else {
    console.log("Invalid date format!");
    return false;
  }
  return "Valid date";
}

export const findValueMultipleSelect = (arrrayofObject, selectedArray) => {
  let valueArray = [];
  arrrayofObject.forEach((data) => {
    if (selectedArray.indexOf(data.value) >= 0) {
      valueArray.push(data.key);
    }
  });
  return valueArray;
};

export const getDateWithDay = (days) => {
  const d = new Date();
  d.setDate(d.getDate() + days);
  return {
    getCurrentDate: getFormatedDate(new Date()),
    futureData: getFormatedDate(d),
  };
};

export const getDateWithDayPast = (days) => {
  const d = new Date();
  d.setDate(d.getDate() - days);
  return {
    getCurrentDate: getFormatedDate(new Date()),
    pastData: getFormatedDate(d),
  };
};

// //check access to get the open item logs by user/roless
// export const checkAccessForLogs = () => {
//   const userInfo = JSON.parse(localStorage.getItem("userInfo"));
//   if (userInfo?.isClient) {
//     return true;
//   }
//   const adminAccess = usersAccess(MODULES_NAME.admin);
//   const inspectionAccess = usersAccess(MODULES_NAME.inspection);
//   const incidentAccess = usersAccess(MODULES_NAME.incident);
//   if (adminAccess.isFullAppAccess || adminAccess.isLimitedInspectorAccess) {
//     return true;
//   }
//   if (inspectionAccess.isFullAccess && !incidentAccess.isFullAccess) {
//     return true;
//   }
//   return false;
// };

export const checkAlphnumeric = (dataval) => {
  const re2 = /^[a-zA-Z0-9 ]+$/;
  //let removeWhiteSpaceDta = dataval.replace(/\s+/g, " ");

  if (re2.test(dataval)) {
    return re2.test(dataval);
  }
};
export const getFileExtension = (url) => {
  const parts = url?.split(".");
  return parts[parts?.length - 1];
};
export const randomStingByLength = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};
// Safety Open-Item column visable and hide
export const setColumnVisibilityModel1 = (newModel, localstorageName) => {
  localStorage.setItem(localstorageName, JSON.stringify(newModel));
};

export const columnVisibilityModel = {
  // safety open iTEM COLOMNS default value

  category: false,
  subCategory: false,
  questions: false,
  location: false,
  addedBy: false,
  inspectionTitle: false,
  assignedToName: false,
};
export const onlyContainsNumbers = (str) => /^\d+$/.test(str);

export const getRandomNumber = () => {
  var timestamp = new Date().toISOString().replace(/[-:.]/g, "");
  var random = ("" + Math.random()).substring(2, 8);
  var random_number = timestamp + random;
  return random_number;
};

const IMAGE_EXTENSIONS = ["png", "jpg", "jpeg", "gif", "svg", "tif"];
const DOCUMENT_EXTENSIONS = [
  "doc",
  "docx",
  "ppt",
  "pptx",
  "xls",
  "xlsx",
  "zip",
];
const PDF_EXTENSIONS = ["pdf"];
const getFileExtensionName = (url) => {
  return url?.split(".").pop();
};
export const getFileInfo = (url) => {
  const fileNameSplit = url?.split("/");
  const fileName = fileNameSplit?.length > 0 && fileNameSplit.slice(-1).pop();
  const fileExtension = getFileExtensionName(url);
  const isImage = IMAGE_EXTENSIONS.includes(fileExtension?.toLowerCase());
  const isDocument = DOCUMENT_EXTENSIONS.includes(fileExtension?.toLowerCase());
  const isPdf = PDF_EXTENSIONS.includes(fileExtension?.toLowerCase());

  return {
    fileName: fileName,
    fileExtension: fileExtension,
    isImage: isImage,
    isDocument: isDocument,
    isPdf: isPdf,
  };
};
export const logEvent = (category, action, label, url) => {
  return ReactGA.event({
    category: category,
    action: action,
    label: label,
    location: url,
  });
};

export const validateFileSize = (file) => {
  if (!file || typeof file !== "object" || typeof file.size !== "number") {
    return {
      isValid: false,
      errorMessage:
        "Invalid file input. Expected a file object with a size property.",
    };
  }
  if (file.size > DOCUMENT_UPLOAD_MAX_SIZE) {
    return {
      isValid: false,
      errorMessage: `File size exceeds the ${Math.round(
        DOCUMENT_UPLOAD_MAX_SIZE / (1024 * 1024)
      )} MB limit.`,
    };
  }

  return { isValid: true, errorMessage: "" };
};

export function convertUTCToLocal(utcTime) {
  if (!utcTime) return "NA";
  let utcDate = new Date(utcTime);

  // Get the local time equivalent
  let localDate = new Date(utcDate.toLocaleString());

  // Extract the local hours, minutes, and seconds
  let localHours = localDate.getHours();
  let localMinutes = localDate.getMinutes();
  let localSeconds = localDate.getSeconds();

  // Determine AM/PM
  let ampm = localHours >= 12 ? "PM" : "AM";

  // Convert to 12-hour format
  localHours = localHours % 12;
  localHours = localHours ? localHours : 12; // Handle the case of 0 (midnight)

  // Ensure two-digit formatting for minutes and seconds
  localMinutes = localMinutes.toString().padStart(2, "0");
  localSeconds = localSeconds.toString().padStart(2, "0");

  return `${localHours}:${localMinutes} ${ampm}`;
}
export const handleMinsonChangeRainfall = (event, setFormValue, formValue) => {
  const re = /^[0-9.]+$/;
  const inputValue = event?.target?.value;
  if (inputValue === "" || re.test(inputValue)) {
    let value = inputValue;
    let countdot = (value.match(/\./g) || []).length;

    if (countdot <= 1) {
      if (value === "") {
        setFormValue({ ...formValue, rainfall: "" });
      } else {
        if (value?.indexOf(".") === -1) {
          setFormValue({ ...formValue, rainfall: value });
        } else {
          const [integerPart, decimalPart] = value?.split(".");

          if (decimalPart.length <= 2) {
            setFormValue({ ...formValue, rainfall: value });
          } else {
            setFormValue({
              ...formValue,
              rainfall: `${integerPart}.${decimalPart?.substring(0, 2)}`,
            });
          }
        }
      }
    } else {
      setFormValue({ ...formValue, rainfall: "" });
    }
  }
};

export const getDateRange = (timeperiod) => {
  const currentDate = new Date();
  const calculatedEndDate = new Date(currentDate);
  calculatedEndDate.setDate(currentDate.getDate() - timeperiod);
  return {
    startDate: calculatedEndDate,
    endDate: currentDate,
  };
};

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getMonthNameFromDate = (dateString) => {
  if (dateString) {
    const date = new Date(dateString);
    return monthNames[date.getMonth()];
  } else {
    return "";
  }
};

export const getDivisionByServiceType = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  if (userInfo && userInfo?.divisions?.length > 0) {
    const serviceType = parseInt(SERVICE_TYPE());
    return userInfo.divisions.filter((x) => x?.serviceType === serviceType);
  }
  return [];
};

export const getAssetTypeValue = (key) => {
  const assetType = ASSET_TYPE?.find((item) => item.key === key);
  return assetType ? assetType.value : null;
};

export const getFilesForValue = (origin) => {
  switch (origin) {
    case "Inspections" || "Inspection":
      return 1;
    case "Observations":
      return 2;
    case "Incidents":
      return 3;
    default:
      return null;
  }
};

export const downloadExcel = (data, fileName = "data.xlsx") => {
  const fileExtension = fileName.split(".").pop().toLowerCase();
  if (fileExtension === "csv") {
    const csv = XLSX.utils.sheet_to_csv(XLSX.utils.json_to_sheet(data));
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
  } else {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([wbout], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
  }
};

export const downloadPDF = (data, columns, fileName = "data.pdf") => {
  const doc = new jsPDF();
  doc.autoTable(columns, data, { startY: 10 });
  doc.save(fileName);
};

export const calculatePercentageChange = (currentValue, previousValue) => {
  if (previousValue === 0) {
    const change = currentValue - previousValue;
    return `${change > 0 ? "+" : ""}${change.toFixed(0)}%`;
  } else {
    const change = ((currentValue - previousValue) / previousValue) * 100;
    return `${change > 0 ? "+" : ""}${change.toFixed(0)}%`;
  }
};

export const downloadFullPDF = async (
  pageId,
  filename,
  pages = ["pdf-content"]
) => {
  const loader = document.getElementById("pdf-loader");
  if (loader) {
    loader.style.display = "flex";
  }

  const hideInPdfSections = document.querySelectorAll(".hideinpdf");
  hideInPdfSections.forEach((section) => {
    section.style.display = "none";
  });

  const pageElement = document.getElementById(pageId);
  if (pageElement) {
    pageElement.classList.add("pdfStyles");
  }

  const getCanvasFromSection = async (sectionId) => {
    const section = document.getElementById(sectionId);
    if (!section) return null;
    return await html2canvas(section, { scale: 1.5 });
  };

  const pdf = new jsPDF("p", "mm", "a4");
  const sections = pages;
  const margin = 5;

  for (const sectionId of sections) {
    const canvas = await getCanvasFromSection(sectionId);
    if (!canvas) continue;

    const imgData = canvas.toDataURL("image/jpeg", 0.5);
    const imgWidth = 210 - 2 * margin;
    const pageHeight = 295;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;

    let position = margin;
    pdf.addImage(imgData, "JPEG", margin, position, imgWidth, imgHeight);
    heightLeft -= pageHeight - 2 * margin;

    while (heightLeft > 0) {
      pdf.addPage();
      position = heightLeft - imgHeight + margin;
      pdf.addImage(imgData, "JPEG", margin, position, imgWidth, imgHeight);
      heightLeft -= pageHeight - 2 * margin;
    }

    if (sectionId !== sections[sections.length - 1]) {
      pdf.addPage();
    }
  }
  pdf.save(filename);

  if (pageElement) {
    pageElement.classList.remove("pdfStyles");
  }

  hideInPdfSections.forEach((section) => {
    section.style.display = "";
  });

  if (loader) {
    loader.style.display = "none";
  }
};
export const useInfiniteScroll = (fetchMoreData, dependencies = []) => {
  const ref = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        const { scrollTop, clientHeight, scrollHeight } = ref.current;
        if (scrollTop + clientHeight >= scrollHeight - 5) {
          fetchMoreData();
        }
      }
    };

    const tableElement = ref.current;
    if (tableElement) {
      tableElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (tableElement) {
        tableElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, dependencies);

  return ref;
};

export function getDayNameAndDate(dateString) {
  try {
    const date = new Date(dateString);

    if (isNaN(date)) {
      throw new Error("Invalid date");
    }

    const dayOptions = { weekday: "long" };
    const dateOptions = { year: "numeric", month: "2-digit", day: "2-digit" };
    const dayName = date.toLocaleDateString("en-US", dayOptions);

    const formattedDate = date
      ?.toLocaleDateString("en-US", dateOptions)
      ?.replace(/(\d+)\/(\d+)\/(\d+)/, "$1-$2-$3");

    return `${dayName}, ${formattedDate}`;
  } catch (error) {
    console.error("Error parsing date:", error);
    return "Invalid date";
  }
}

export const compressImage = async (imageUrl) => {
  try {
    const response = await fetch(imageUrl);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const blob = await response.blob();
    const imageFile = new File([blob], "image.jpg", { type: blob.type });

    const maxSizeKB = 100;
    const maxSizeMB = maxSizeKB / 1024;

    const options = {
      maxSizeMB: maxSizeMB,
      maxWidthOrHeight: 1920,
    };

    const compressedFile = await imageCompression(imageFile, options);
    return URL.createObjectURL(compressedFile);
  } catch (error) {
    console.error("Error compressing image:", error);
    return null;
  }
};

export const sortAlphabetically = (array) => {
  return (array ? [...array] : []).sort((a, b) =>
    (a?.value?.trim() ?? "").localeCompare(b?.value?.trim() ?? "")
  );
};

export const getLabelByAssetType = (assetTypeValue) => {
  switch (assetTypeValue) {
    case 1:
      return "Project";
    case 2:
      return "Equipment";
    case 3:
      return "Vehicle";
    case 4:
      return "Custom asset";
    default:
      return "";
  }
};

export const getNoOptionsTextByAssetType = (assetTypeValue) => {
  switch (assetTypeValue) {
    case 1:
      return "No Project";
    case 2:
      return "No Equipment";
    case 3:
      return "No Vehicles";
    case 4:
      return "No Custom Assets";
    default:
      return "";
  }
};
export const getSlasFormatedDate = (dt) => {
  if (dt) {
    const [month, day, year] = dt?.split("-");
    if (!year || !month || !day) {
      throw new Error("Invalid date format. Expected format is MM-DD-YYYY.");
    }
    return `${year}/${month}/${day}`;
  }
  return "";
};
export const generateYears = (startYear, endYear) => {
  return Array.from({ length: endYear - startYear + 1 }, (_, index) => {
    const year = endYear - index;
    return { key: year, value: year.toString() };
  });
};

export const getItemType = (id) => {
  switch (id) {
    case 1:
      return GET_ITEM_LOG.Kudos;
    case 2:
      return GET_ITEM_LOG.Corrective;
    case 3:
      return GET_ITEM_LOG.Reprimand;
    case 4:
      return GET_ITEM_LOG.Action;
    default:
      return GET_ITEM_LOG.All;
  }
};

// for encoding special character in mail id
export const encodeSearchText = (searchText) => {
  return encodeURIComponent(searchText);
};

// check expiry date is greater than current date
export const isDateExpired = (date) => {
  if (date) {
    const currentDate = getFormatedDate(new Date());
    const expDate = getFormatedDate(date);
    const isExpired = moment(expDate).isBefore(currentDate);
    return isExpired;
  } else {
    return false;
  }
};

export const getEstablishmentTypeValue = (key, ESTABLISHMENT_TYPE) => {
  const entry = ESTABLISHMENT_TYPE?.find((item) => item?.key === key);
  return entry ? entry.value : null;
};

export const formatRainfall = (rainfall) => {
  const value = parseFloat(rainfall);
  if (isNaN(value)) {
    return null;
  }
  if (value === 0) {
    return 0;
  }
  if (value < 1) {
    return value < 0.1 ? 0 : Math.floor(value * 10) / 10;
  }
  return (Math.floor(value * 10) / 10).toFixed(1);
};

export const convertToISODate = (dateString) => {
  const [month, day, year] = dateString?.split("-");
  return `${year}-${month}-${day}`;
};

export const InactiveClient = () => {
  if (ORG_STATUS() === "0" && LOGIN_ORG_ID() !== ORG_ID()) {
    toast("This Client is Inactive");
    return true;
  }
  return false;
};

export const twoDigitAfterDecimal = (value) => {
  if (typeof value !== "number") return "";
  const truncatedValue = Math.floor(value * 100) / 100;
  return parseFloat(truncatedValue).toString();
};
