import React, { useEffect, useState } from "react";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import FormControl from "@mui/material/FormControl";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import { InputField } from "../../../../library/custom/textBox/InputField";
import Buttons from "../../../../library/custom/button/Button";
import { Grid } from "@mui/material";
import {
  ORG_ID,
  ISwitch,
  USER_ID,
  QUESTION_TEMPLATE_URL,
  QUESTION_GRID_COLOUMNS,
  QUESTION_RESPONSE_TYPE,
  CLIENT_SATATUS_FILTER,
} from "../../../../constant/constant";
import { INSPECTION_ADMIN } from "../../../../constant/routeContant";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { useNavigate } from "react-router-dom";
import NORow from "../../../../library/custom/noRow/noRow";
import BulkUpload from "../../../../library/custom/bulkUpload/BulkUpload";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import RadioGroup from "@mui/material/RadioGroup";
import RadioButton from "../../../../library/custom/radioButton/RadioButton";
import { getResponseTypeValue } from "../../../../utils/incpectionHelperNew";
import ServiceeFilterList from "../jha/serviceListFilter";
import FilterListIcon from "@mui/icons-material/FilterList";
import { InactiveClient } from "../../../../utils/helper";

const AddQuestion = ({
  onSubmit,
  getQuestionsList,
  getSubCategoriesList,
  getQuestionsListData,
  createUpdateQuestions,
}) => {
  const navigate = useNavigate();
  const [isSubmit, setIsSubmit] = useState(false);
  const [getQuestionsLists, setGetQuestionsLists] = useState([]);
  const [responsetype, setResponsetype] = useState(
    QUESTION_RESPONSE_TYPE.ACCEPTABLE_NOTACCEPTABLE_NOTAPPLICABLE
  );
  const [involvedPartType, setInvolvedPartType] = useState(null);
  const [optionList, setOptionList] = useState([]);
  const [duplicateMessage, setDuplicateMessage] = useState();
  const [editingOptionIndex, setEditingOptionIndex] = useState(null);
  const [editedOptionValue, setEditedOptionValue] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);

  const openFilter = Boolean(anchorEl);
  const id = openFilter ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [filterFormValue, setFilterFormValue] = useState({
    isActive: "",
  });

  const intialState = {
    questionValue: "",
    subCategoriesValue: "",
  };

  const [formValue, setFormValue] = useState(intialState);
  const [isQuestionName, setIsQuestionName] = useState(false);
  const [subCategoriesName, setSubCategoriesName] = useState(false);
  const [isBulkUpload, setIsBulkUpload] = useState(false);
  const [subCatId, setSubCatId] = useState();
  const [errorsMsgShowActive, seterrorsMsgShowActive] = useState(false);
  const [errorsMsgShow, setErrorsMsgShow] = useState();
  const [optionFormValue, setOptionFormValue] = useState("");
  const [responsetypeSelectError, setResponsetypeSelectError] = useState(false);
  const [responsetypeError, setResponsetypeError] = useState(false);

  const handleChange = (questionItem) => {
    const newQuestionList = [...getQuestionsLists];
    newQuestionList.forEach((lists) => {
      if (lists.key == questionItem.key) {
        lists.isActive = !lists.isActive;
        lists.checked = !lists.checked;
        lists.questionId = questionItem.key;
        lists.createdBy = parseInt(USER_ID());
      }
    });
    setGetQuestionsLists(newQuestionList);
  };

  function mappedData() {
    let errors = false;
    if (formValue?.questionValue.trim() === "") {
      setFormValue({
        ...formValue,
        questionValue: "",
      });
      seterrorsMsgShowActive(false);
      setIsQuestionName(true);
      errors = true;
    }
    if (formValue?.subCategoriesValue?.length === 0) {
      seterrorsMsgShowActive(false);
      setSubCategoriesName(true);
      errors = true;
    }
    if (
      responsetype === QUESTION_RESPONSE_TYPE.SINGLE_SELECT ||
      responsetype === QUESTION_RESPONSE_TYPE.MULTI_SELECT
    ) {
      if (optionList?.length === 0) {
        setResponsetypeSelectError(true);
        errors = true;
      }
    }
    if (
      responsetype ===
      QUESTION_RESPONSE_TYPE.ACCEPTABLE_NOTACCEPTABLE_NOTAPPLICABLE
    ) {
      if (involvedPartType === null) {
        setResponsetypeError(true);
        errors = true;
      }
    }
    if (errors) {
      return false;
    }
    const index = getQuestionsLists?.findIndex(
      (object) =>
        object.value.toUpperCase() === formValue?.questionValue.toUpperCase()
    );
    if (index === -1) {
      seterrorsMsgShowActive(false);
      const questions = [
        {
          questionId: 0,
          subcategoryId: formValue?.subCategoriesValue,
          question: formValue?.questionValue,
          isActive: true,
          createdBy: parseInt(USER_ID()),
          responsetype: responsetype,
          involvedParty:
            involvedPartType === 1
              ? true
              : involvedPartType === 2
                ? false
                : null,
          optionList: optionList,
        },
      ];
      return { questions, organisationId: parseInt(ORG_ID()) };
    } else {
      seterrorsMsgShowActive(true);
      setErrorsMsgShow("Question already added");
    }
  }

  const submitData = () => {

    if (InactiveClient()) return;
    const data = mappedData();
    if (!data) {
    } else {
      setIsSubmit(true);
      onSubmit(data);
    }
  };

  const submitUpdateData = () => {
    if (InactiveClient()) return;
    const questions = [];
    getQuestionsLists.forEach((data) => {
      if (data.checked) {
        questions.push(data);
      }
    });
    setFilterFormValue({
      assetType: "",
      isActive: "",
    });
    onSubmit({ questions, organisationId: parseInt(ORG_ID()) });
  };

  const handleFileChanges = (data) => {
    const subIdsFilter = data?.map((x) => parseInt(x.subcategoryId));
    setSubCatId([...new Set(subIdsFilter)]);

    const questions = data.map((x) => {
      const optionsArray = x.optionList?.split("|");
      x.questionId = 0;
      x.subcategoryId = parseInt(x.subcategoryId);
      x.isActive = true;
      x.createdBy = parseInt(USER_ID());
      x.responsetype = parseInt(x.responsetype);
      x.involvedParty =
        parseInt(x.involvedParty) === 1
          ? true
          : parseInt(x.involvedParty) === 2
            ? false
            : null;
      x.optionList = optionsArray?.map((option) => ({
        key: 0,
        value: option,
      }));
      delete x.id;
      return x;
    });
    onSubmit({ questions, organisationId: parseInt(ORG_ID()) });
  };

  const handleSubCategoriesChange = (e) => {
    setFormValue({
      ...formValue,
      subCategoriesValue: e.target.value,
    });
    setEditingOptionIndex(null);
  };

  useEffect(() => {
    if (formValue?.subCategoriesValue) {
      const subIds = formValue?.subCategoriesValue;
      setSubCatId(subIds);
      const data = {
        subCategories: [subIds],
        searchText: "",
        organisationId: parseInt(ORG_ID()),
      };
      getQuestionsListData(data);
    }
  }, [formValue?.subCategoriesValue]);

  const dataBack = {
    title: "Questions Configuration",
    route: INSPECTION_ADMIN,
  };

  useEffect(() => {
    setGetQuestionsLists(getQuestionsList);
  }, [getQuestionsList]);

  useEffect(() => {
    if (
      createUpdateQuestions?.status === "SUCCESS" &&
      createUpdateQuestions?.isLoading === false
    ) {
      setOptionList([]);
      setResponsetype(1);
      setInvolvedPartType(null);
      setIsSubmit(false);
      setIsQuestionName(false);
      setFormValue({
        ...formValue,
        questionValue: "",
      });
      const data = {
        subCategories: subCatId ? [subCatId] : [0],
        searchText: "",
        organisationId: parseInt(ORG_ID()),
      };
      getQuestionsListData(data);
      setIsBulkUpload(false);
    }
  }, [createUpdateQuestions]);

  const handleResChange = (value) => {
    setResponsetype(value);
    if (
      value === QUESTION_RESPONSE_TYPE.ACCEPTABLE_NOTACCEPTABLE_NOTAPPLICABLE ||
      QUESTION_RESPONSE_TYPE.TEXTBOX ||
      QUESTION_RESPONSE_TYPE.SINGLE_SELECT ||
      QUESTION_RESPONSE_TYPE.MULTI_SELECT
    ) {
      setOptionList([]);
      setEditingOptionIndex(null);
    }

    if (
      value === QUESTION_RESPONSE_TYPE.SINGLE_SELECT ||
      QUESTION_RESPONSE_TYPE.MULTI_SELECT ||
      QUESTION_RESPONSE_TYPE.TEXTBOX
    ) {
      setInvolvedPartType();
    }
  };

  const handleInvChange = (value) => {
    setInvolvedPartType(value);
  };

  const isOptionDuplicate = (optionValue) => {
    return optionList?.some(
      (option) => option?.value?.toLowerCase() === optionValue?.toLowerCase()
    );
  };

  const submitOptionData = () => {
    if (optionFormValue.trim()) {
      if (isOptionDuplicate(optionFormValue)) {
        setDuplicateMessage(true);
      } else {
        setDuplicateMessage(false);
        setOptionList([...optionList, { value: optionFormValue, key: 0 }]);
        setOptionFormValue("");
      }
    }
  };

  const deleteOnOption = (index) => {
    const newList = optionList?.filter((_, i) => i !== index);
    setOptionList(newList);
  };

  const mapBulkError = (data) => {
    let usersWithError = [];
    data.forEach((x) => {
      if (
        !x.subcategoryId ||
        !x.subcategoryId.trim() ||
        !x.responsetype ||
        !x.responsetype.trim() ||
        !x.question ||
        !x.question.trim() ||
        (parseInt(x.responsetype) ===
          QUESTION_RESPONSE_TYPE.ACCEPTABLE_NOTACCEPTABLE_NOTAPPLICABLE &&
          (!x.involvedParty || !x.involvedParty.trim())) ||
        ((parseInt(x.responsetype) === QUESTION_RESPONSE_TYPE.SINGLE_SELECT ||
          parseInt(x.responsetype) === QUESTION_RESPONSE_TYPE.MULTI_SELECT) &&
          (!x.optionList || !x.optionList.trim()))
      ) {
        usersWithError.push(x);
      }
    });
    return usersWithError;
  };

  useEffect(() => {
    setGetQuestionsLists([]);
    setFormValue({
      ...formValue,
      questionValue: "",
      subCategoriesValue: "",
    });
  }, []);
  const handeleBulkUpload = () => {
    if (InactiveClient()) return;
    setIsBulkUpload(!isBulkUpload);
    setGetQuestionsLists([]);
    setEditingOptionIndex(null);
    setFormValue({
      ...formValue,
      questionValue: "",
      subCategoriesValue: "",
    });
  };
  const handleEditClick = (index, value) => {
    if (InactiveClient()) return;
    setEditingOptionIndex(index);
    setEditedOptionValue(value);
  };

  const handleSaveEdit = () => {
    if (editedOptionValue.trim()) {
      const updatedOptions = optionList.map((option, index) =>
        index === editingOptionIndex
          ? { ...option, value: editedOptionValue }
          : option
      );
      setOptionList(updatedOptions);
      setEditingOptionIndex(null);
      setEditedOptionValue("");
    }
  };

  return (
    <>
      <Grid className="inspectionOverRide">
        <BackBtn dataBack={dataBack} />

        <Grid container item md={12} xs={12} className="addShadowBox">
          <Grid container mt={1}>
            <Grid container item md={12} xs={12}>
              <Grid container item md={12} mb={3}>
                <Grid item lg={6} xs={12} mb={3}>
                  <FormControl fullWidth>
                    <FormLabels label="Select Sub Category" isRequired={true} />
                    <SelectMenu
                      placeholder="Please Select"
                      listData={getSubCategoriesList ?? []}
                      value={formValue.subCategoriesValue}
                      onchangehandler={(e) => handleSubCategoriesChange(e)}
                      isError={subCategoriesName}
                      errorMsg={
                        subCategoriesName ? "Sub Category is Required" : ""
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  lg={6}
                  xs={12}
                  sm={12}
                  md={4}
                  mt={3}
                  textAlign="right"
                >
                  {checkPrivileges([12, 120, 4]) && (
                    <Buttons
                      aria-describedby="dd"
                      variant="contained"
                      type="button"
                      name="btn"
                      label={
                        isBulkUpload ? "Single Question" : "Multiple Question"
                      }
                      varientContained={true}
                      onClick={() => handeleBulkUpload()}
                    ></Buttons>
                  )}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={12}>
                  {isBulkUpload ? (
                    <>
                      <BulkUpload
                        handleFileChanges={handleFileChanges}
                        paraText1={"Drop the files here ..."}
                        paraText2={"Drop the files here, "}
                        paraText3={"or Browse"}
                        templateUrl={QUESTION_TEMPLATE_URL}
                        templateText="Download Template"
                        gridColoumns={QUESTION_GRID_COLOUMNS}
                        isCustomColoumns={true}
                        mapBulkError={mapBulkError}
                      />
                    </>
                  ) : (
                    <>
                      {checkPrivileges([12, 120, 121, 4]) && (
                        <>
                          <Grid container className="addQuestionBox">
                            <Grid
                              item
                              className="addQuestionInput"
                              lg={12}
                              md={12}
                              xs={12}
                            >
                              <InputField
                                type="text"
                                isRequired={true}
                                value={formValue?.questionValue}
                                label={false}
                                placeholder="Add Question Text Here..."
                                onChange={(e) =>
                                  setFormValue({
                                    ...formValue,
                                    questionValue: e.target.value,
                                  })
                                }
                              />
                            </Grid>
                            <Grid>
                              {isQuestionName && !formValue?.questionValue && (
                                <p className="error">Question is Required</p>
                              )}
                            </Grid>
                            <Grid
                              item
                              md={12}
                              sm={12}
                              className="QuestionBoxRes"
                            >
                              <div className="responseTypeBoxRes">
                                <div className="responseType">
                                  Response Type
                                </div>
                                <div className="responseTypeRight">
                                  <div
                                    onClick={() =>
                                      handleResChange(
                                        QUESTION_RESPONSE_TYPE.ACCEPTABLE_NOTACCEPTABLE_NOTAPPLICABLE
                                      )
                                    }
                                    className={
                                      responsetype ===
                                        QUESTION_RESPONSE_TYPE.ACCEPTABLE_NOTACCEPTABLE_NOTAPPLICABLE
                                        ? "responseTypeBtn active"
                                        : "responseTypeBtn"
                                    }
                                  >
                                    Acceptable/Not Acceptable/NA
                                  </div>
                                  <div
                                    className={
                                      responsetype ===
                                        QUESTION_RESPONSE_TYPE.TEXTBOX
                                        ? "responseTypeBtn active"
                                        : "responseTypeBtn"
                                    }
                                    onClick={() =>
                                      handleResChange(
                                        QUESTION_RESPONSE_TYPE.TEXTBOX
                                      )
                                    }
                                  >
                                    Text Box
                                  </div>
                                  <div
                                    className={
                                      responsetype ===
                                        QUESTION_RESPONSE_TYPE.SINGLE_SELECT
                                        ? "responseTypeBtn active"
                                        : "responseTypeBtn"
                                    }
                                    onClick={() =>
                                      handleResChange(
                                        QUESTION_RESPONSE_TYPE.SINGLE_SELECT
                                      )
                                    }
                                  >
                                    Single Select Dropdown
                                  </div>
                                  <div
                                    className={
                                      responsetype ===
                                        QUESTION_RESPONSE_TYPE.MULTI_SELECT
                                        ? "responseTypeBtn active"
                                        : "responseTypeBtn"
                                    }
                                    onClick={() =>
                                      handleResChange(
                                        QUESTION_RESPONSE_TYPE.MULTI_SELECT
                                      )
                                    }
                                  >
                                    Multiple Select Dropdown
                                  </div>
                                </div>
                              </div>
                            </Grid>

                            {responsetype ===
                              QUESTION_RESPONSE_TYPE.ACCEPTABLE_NOTACCEPTABLE_NOTAPPLICABLE && (
                                <Grid
                                  container
                                  mt={2}
                                  item
                                  md={12}
                                  xs={12}
                                  className="addQuestionBox"
                                >
                                  <Grid item md={12} xs={12} pl={3}>
                                    <FormControl fullWidth>
                                      <FormLabels
                                        label={"Involved Party Type"}
                                        isRequired={true}
                                      />
                                      <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        className="radioGroup"
                                      >
                                        <RadioButton
                                          checked={involvedPartType === 1}
                                          value="1"
                                          label="Yes"
                                          onChange={() => handleInvChange(1)}
                                        ></RadioButton>

                                        <RadioButton
                                          checked={involvedPartType === 2}
                                          value="2"
                                          label="No"
                                          onChange={() => handleInvChange(2)}
                                        ></RadioButton>
                                      </RadioGroup>
                                    </FormControl>
                                  </Grid>
                                  <Grid>
                                    {responsetypeError &&
                                      involvedPartType === null && (
                                        <p className="error">
                                          Please select involved Party Type
                                        </p>
                                      )}
                                  </Grid>
                                </Grid>
                              )}
                            {(responsetype ===
                              QUESTION_RESPONSE_TYPE.SINGLE_SELECT ||
                              responsetype ===
                              QUESTION_RESPONSE_TYPE.MULTI_SELECT) && (
                                <Grid
                                  container
                                  mt={2}
                                  item
                                  md={12}
                                  xs={12}
                                  className="addQuestionBox"
                                >
                                  <Grid
                                    item
                                    md={7}
                                    xs={12}
                                    className="addQuestionInput"
                                  >
                                    <InputField
                                      type="text"
                                      isRequired={true}
                                      value={optionFormValue}
                                      label={false}
                                      placeholder="Add Option Text Here..."
                                      onChange={(e) =>
                                        setOptionFormValue(e.target.value)
                                      }
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    pl={2}
                                    md={5}
                                    xs={12}
                                    className="meduimAddBtn"
                                  >
                                    <Buttons
                                      aria-describedby="dd"
                                      variant="contained"
                                      type="button"
                                      name="btn"
                                      label={"Add Option"}
                                      id="btnAddEquipment"
                                      varientAddIconBlue={true}
                                      onClick={submitOptionData}
                                    ></Buttons>
                                  </Grid>
                                  <Grid md={12} sm={12} xs={12} item>
                                    {responsetypeSelectError &&
                                      optionList?.length === 0 && (
                                        <p className="error">Please add Option</p>
                                      )}
                                  </Grid>
                                  <Grid md={12} sm={12} xs={12} item>
                                    {duplicateMessage && (
                                      <p className="error">
                                        Please add different option
                                      </p>
                                    )}
                                  </Grid>
                                  <Grid
                                    item
                                    md={12}
                                    className="CategoriesBox w-100"
                                    mt={3}
                                  >
                                    <Grid className="title">Option List</Grid>
                                    <Grid
                                      className="questionBox mainQuestion questConf"
                                      mt={3}
                                    >
                                      {optionList?.map((option, optionIndex) => (
                                        <Grid
                                          className="catListBox small"
                                          key={optionIndex}
                                        >
                                          <Grid className="catListItem">
                                            {editingOptionIndex ===
                                              optionIndex ? (
                                              <InputField
                                                type="text"
                                                value={editedOptionValue}
                                                onChange={(e) =>
                                                  setEditedOptionValue(
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            ) : (
                                              option?.value
                                            )}
                                          </Grid>
                                          {editingOptionIndex === optionIndex && (
                                            <Buttons
                                              varientContained={true}
                                              label={"Update"}
                                              onClick={handleSaveEdit}
                                            />
                                          )}
                                          {editingOptionIndex !== optionIndex && (
                                            <>
                                              <Grid className="editIcon">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="16"
                                                  height="16"
                                                  viewBox="0 0 16 16"
                                                  fill="none"
                                                  onClick={() =>
                                                    handleEditClick(
                                                      optionIndex,
                                                      option?.value
                                                    )
                                                  }
                                                >
                                                  <path
                                                    d="M14.2892 5.54197L10.5359 1.81197L11.5632 0.791966C11.9127 0.444632 12.3287 0.270966 12.811 0.270966C13.2933 0.270966 13.7089 0.444632 14.0577 0.791966L15.3156 2.04197C15.651 2.3753 15.8187 2.7883 15.8187 3.28097C15.8187 3.7743 15.651 4.18763 15.3156 4.52097L14.2892 5.54197ZM13.0515 6.77097L4.26793 15.5H0.515625V11.771L9.29918 3.04197L13.0515 6.77097Z"
                                                    fill="#1B436E"
                                                  />
                                                </svg>
                                              </Grid>

                                              <Grid className="closeIcon">
                                                <svg
                                                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root deleteIcon"
                                                  focusable="false"
                                                  aria-hidden="true"
                                                  viewBox="0 0 24 24"
                                                  data-testid="DeleteIcon"
                                                  onClick={() =>
                                                    deleteOnOption(optionIndex)
                                                  }
                                                >
                                                  <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6zM19 4h-3.5l-1-1h-5l-1 1H5v2h14z"></path>
                                                </svg>
                                              </Grid>
                                            </>
                                          )}
                                        </Grid>
                                      ))}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )}
                            <Grid mt={2} item lg={2.8} md={2.8} xs={12}>
                              <Buttons
                                aria-describedby="dd"
                                variant="contained"
                                type="button"
                                name="btn"
                                label={"Add Question"}
                                id="btnAddEquipment"
                                varientAddIconBlue={true}
                                disabled={isSubmit ? true : false}
                                onClick={submitData}
                              ></Buttons>
                            </Grid>
                          </Grid>
                          <Grid md={12} item>
                            {errorsMsgShowActive && (
                              <p className="errorMsg">{errorsMsgShow}</p>
                            )}
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid item md={9} xs={8} mt={2}>
                <div className="filter_of_caselist">
                  <div className="filter_btn">
                    <button
                      aria-describedby={id}
                      variant="contained"
                      type="button"
                      name="btn"
                      onClick={(e) => handleClick(e)}
                    >
                      <FilterListIcon id="icn" /> Filters
                    </button>
                  </div>
                </div>

                <ServiceeFilterList
                  id={id}
                  open={openFilter}
                  anchorEl={anchorEl}
                  handleClose={() => {
                    setAnchorEl(null);
                    window.close();
                  }}
                  handleApply={() => {
                    const data = {
                      subCategories: subCatId ? [subCatId] : [0],
                      searchText: "",
                      organisationId: parseInt(ORG_ID()),
                      isActive:
                        filterFormValue.isActive === 1
                          ? true
                          : filterFormValue.isActive === 2
                            ? false
                            : "",
                    };
                    getQuestionsListData(data);
                    setAnchorEl(null);
                    window.close();
                  }}
                  secondLabel="Status"
                  secondLebelFilter={CLIENT_SATATUS_FILTER}
                  secondLebelValue={filterFormValue.isActive}
                  firstLebelOnChanges={(e) =>
                    setFilterFormValue({
                      ...filterFormValue,
                    })
                  }
                  secondLebelOnChanges={(e) =>
                    setFilterFormValue({
                      ...filterFormValue,
                      isActive: e.target.value,
                    })
                  }
                  handleClear={() => {
                    setFilterFormValue({
                      assetType: "",
                      isActive: "",
                    });
                    const data = {
                      subCategories: subCatId ? [subCatId] : [0],
                      searchText: "",
                      organisationId: parseInt(ORG_ID()),
                      // isActive: "",
                    };
                    getQuestionsListData(data);
                    setAnchorEl(null);
                    window.close();
                  }}
                />
              </Grid>
              <Grid item md={12} className="CategoriesBox" mt={3}>
                <Grid className="title columnBox">
                  <div className="column question">Questions</div>
                  <div className="column responsetype">Response Type</div>
                  {checkPrivileges([12, 4, 120]) && (
                    <div className="column status">Status</div>
                  )}
                </Grid>
                <Grid className="questionBox mainQuestion" mt={3}>
                  {getQuestionsLists &&
                    getQuestionsLists.map((questionItem) => {
                      return (
                        <Grid className="catListBox" key={questionItem?.key}>
                          <Grid className="catListItem question">
                            {questionItem?.value}
                          </Grid>
                          <Grid className="catListItem responsetype">
                            {getResponseTypeValue(questionItem.responsetype)}
                          </Grid>
                          {checkPrivileges([12, 4, 120]) && (
                            <Grid className="closeIcon status">
                              <FormControl
                                component="fieldset"
                                variant="standard"
                              >
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <ISwitch
                                        checked={
                                          questionItem.isActive
                                            ? questionItem.isActive
                                            : false
                                        }
                                        onChange={() =>
                                          handleChange(questionItem)
                                        }
                                        name="status"
                                      />
                                    }
                                  />
                                </FormGroup>
                              </FormControl>
                            </Grid>
                          )}
                        </Grid>
                      );
                    })}
                </Grid>
                {getQuestionsLists && getQuestionsLists.length === 0 && (
                  <NORow />
                )}
              </Grid>
            </Grid>
          </Grid>
          {getQuestionsLists && getQuestionsLists.length !== 0 && (
            <Grid container mt={10}>
              <Grid item lg={2} xs={12} sm={3} md={3}>
                <Buttons
                  varientText={true}
                  label="Cancel"
                  onClick={() => navigate(-1)}
                />
              </Grid>
              <Grid item lg={2} xs={12} sm={3} md={3}>
                {checkPrivileges([12, 120, 4]) && (
                  <Buttons
                    varientContained={true}
                    label={"Save"}
                    onClick={submitUpdateData}
                  />
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default AddQuestion;
