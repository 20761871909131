import React from "react";
import { Grid } from "@mui/material";
import ViewImages from "../../../incidents/view-incident-report/common/viewImages/viewImages";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import "./viewAttendee.scss";
import { getLabelByAssetType } from "../../../../utils/helper";

const ViewAttendes = ({
  getScheduleAttendeesDetailsData,
  fromMyTraining,
  completedBy,
}) => {
  const userInfo1 = JSON.parse(localStorage.getItem("userInfo"));
  const isSignatureProfile = userInfo1?.signatureUrl;

  const completedbysignature = getScheduleAttendeesDetailsData?.attendeesDetails.length > 0 && getScheduleAttendeesDetailsData
    ?.attendeesDetails[0]?.signatureUrl
    ? getScheduleAttendeesDetailsData?.attendeesDetails[0]?.signatureUrl
    : isSignatureProfile;

  const imageSize = {
    size: "small",
  };
  const dataBack = {
    title: "Back to Safety Meetings",
    route: -1,
  };
  return (
    <div className="oberrideViewAttendee">
      <section className="grid_main_sec">
        <Grid container mt={2}>
          {" "}
          <Grid>
            <div class="inline-row">
              <div class="grid-item">
                Asset Type:
                <span>
                  {" "}
                  {getScheduleAttendeesDetailsData
                    ?.assetType
                    ? getLabelByAssetType(
                      getScheduleAttendeesDetailsData
                        ?.assetType
                    )
                    : "N/A"}
                </span>
              </div>
              <div class="grid-item">
                Project Name:
                <span>
                  {" "}
                  {getScheduleAttendeesDetailsData
                    ?.assetName
                    ? getScheduleAttendeesDetailsData
                      ?.assetName
                    : "N/A"}
                </span>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container mt={2} className="comp_signatSection">
          {" "}
          <Grid>
            <div class="inline-row">
              <div class="grid-item">
                Completed By:
                <span> {completedBy}</span>
              </div>
            </div>
          </Grid>
          <Grid mt={2} mb={2}>
            {completedbysignature ? (<><img
              width={"200px"}
              src={completedbysignature}
              alt="inspector signature"
            /></>) : (<>N/A</>)}

          </Grid>
        </Grid>

        <div className="grid_main_header two_grid">
          <div className="grid_heading_style">View Attendees</div>
        </div>

        <Grid container mt={2} className="widthpdf-100">
          <Grid item lg={12} md={12} sm={12} sx={12}>
            <div className="attendeesListTable">
              <div className="container">
                <div className="heading">
                  <div className="col">Attendees</div>
                  <div className="col">Signature</div>
                </div>

                {getScheduleAttendeesDetailsData?.attendeesDetails?.map(
                  (user, index) => {
                    return (
                      <div className="table-row" key={index}>
                        <div className="col attendee">{user?.userName}</div>
                        <div className="col proof">
                          {user?.file?.filePath ? (
                            <img
                              className="attendeeProofImage"
                              src={user?.file?.filePath}
                              alt=""
                            />
                          ) : (
                            <div className="na"> N/A</div>
                          )}
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid mt={4}>
          <div className="grid_main_header two_grid">
            <div className="grid_heading_style">Photo Verification</div>
          </div>

          <ViewImages
            image={
              getScheduleAttendeesDetailsData?.files?.length
                ? getScheduleAttendeesDetailsData?.files?.map(
                  (item) => item.filePath
                )
                : []
            }
            imageSize={imageSize}
            showName={false}
          />
        </Grid>

        {!fromMyTraining ? (
          <Grid item lg={4} textAlign="center" className="addcourseButton">
            <BackBtn dataBack={dataBack} />
          </Grid>
        ) : (
          ""
        )}
      </section>
    </div>
  );
};

export default ViewAttendes;
