import React from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import parse from "html-react-parser";
import { findValueByKeyWeather } from "../../../../utils/incpectionHelperNew";

const AccidentDetails = ({ accidentDetails, weather }) => {
  return (
    <div className="commonOveride">
      <Box className="commoninfoWrap">
        <Box className="commoninfoBox">
          <Box className="commoninfoleft">
            <Typography className="title">Accident Details:</Typography>
            <Typography className="listView">
              Where did the Accident Occur:
              <span className="font-w400">
                {accidentDetails?.accident_location}
              </span>
            </Typography>
            <Box className="columns">
              <Typography className="listView">
                Posted Speed Limit:
                <span className="font-w400">
                  {accidentDetails?.speed_limit}
                </span>
              </Typography>
              <Typography className="listView">
                Weather at the Time of Accident:
                <span className="font-w400">
                  {findValueByKeyWeather(
                    accidentDetails?.weather[0]?.value,
                    weather
                  )}
                </span>
              </Typography>
            </Box>
            <Box className="columns">
              <Typography className="listView">
                Traffic at Time of Incident:
                {accidentDetails?.traffic && (
                  <span className="font-w400">{accidentDetails?.traffic}</span>
                )}
              </Typography>
              <Typography className="listView">
                Contributing Factor(s):
                <span className="font-w400">
                  {accidentDetails?.contributing_factor}
                </span>
              </Typography>
            </Box>
            <Typography className="listView desNone">
              Description of Incident:
              <span className="blockView">
                {parse(unescape(accidentDetails?.description))}
              </span>{" "}
            </Typography>
          </Box>
          <Box className="commoninfoRight"></Box>
        </Box>
      </Box>
    </div>
  );
};

export default AccidentDetails;
