import React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import FromToDatePicker from "../../../library/custom/datePicker/DatePicker";
import {
  Card,
  CardContent,
  Box,
  CardActions,
  FormControl,
} from "@mui/material";
import Buttons from "../../../library/custom/button/Button";
import {
  ITEM_TYPE,
  OBSERVATION_STATUS,
  OPEN_CASE_LIST_FILTER,
} from "../../../constant/constant";
import SelectMenu from "../../../library/custom/selectMenu/SelectMenu";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
const FilterList = ({
  id,
  open,
  anchorEl,
  handleClose,
  dateOfCaseFrom,
  dateOfCaseHandlerFrom,
  dateOfCaseTo,
  dateOfCaseHandlerTO,
  handleSearch,
  resetFilter,
  onChangeSelectUserType,
  caseStatus,
  observationStatus = false,
  status,
  onStatusChange,
  itemTypes = false,
  itemTypesId,
  typeOnItemTypeChanges,
  dateFilter = true,
  listTypeValues,
  caseListStatus,
  caseLabel = "Case Status",
}) => {
  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Card className="filterlist_of_cases">
          <CardContent>
            <Box className="" sx={{ pb: 2 }}>
              {/* <Typography gutterBottom variant="h5">
                Time Period
              </Typography> */}
              {dateFilter && (
                <>
                  <FormLabels label="Time Period" isRequired={false} />

                  <Box className="datePicker_row" sx={{ pb: 0.5, pt: 0.5 }}>
                    <div>
                      <Typography variant="subtitle1">From</Typography>
                      <FromToDatePicker
                        value={dateOfCaseFrom}
                        onChangeHandler={dateOfCaseHandlerFrom}
                      />
                    </div>
                    <div>
                      <Typography variant="subtitle1">To</Typography>
                      <FromToDatePicker
                        value={dateOfCaseTo}
                        onChangeHandler={dateOfCaseHandlerTO}
                      />
                    </div>
                  </Box>
                </>
              )}

              <Box>
                {itemTypes && (
                  <>
                    <Box className="" sx={{ pb: 0.5, pt: 0.5 }}>
                      <Typography
                        className="filterLabel"
                        gutterBottom
                        variant="h5"
                      >
                        Item Types
                      </Typography>
                    </Box>
                    <Box className="" sx={{ pb: 2 }}>
                      <SelectMenu
                        name={"itemTypes"}
                        listData={listTypeValues ? listTypeValues : ITEM_TYPE}
                        onchangehandler={(e) => typeOnItemTypeChanges(e)}
                        value={itemTypesId}
                        placeholder="Please select"
                      />
                    </Box>
                  </>
                )}

                {!observationStatus && (
                  <div>
                    <FormControl fullWidth>
                      <FormLabels label={caseLabel} isRequired={false} />
                      <SelectMenu
                        placeholder="Please Select"
                        listData={
                          caseListStatus
                            ? caseListStatus
                            : OPEN_CASE_LIST_FILTER
                        }
                        value={caseStatus}
                        onchangehandler={(e) => onChangeSelectUserType(e)}
                      />
                    </FormControl>
                  </div>
                )}

                {observationStatus && (
                  <div>
                    <FormControl fullWidth>
                      <FormLabels label="Status" isRequired={false} />
                      <SelectMenu
                        placeholder="Please Select"
                        listData={OBSERVATION_STATUS}
                        value={status}
                        onchangehandler={(e) => onStatusChange(e)}
                      />
                    </FormControl>
                  </div>
                )}
              </Box>
            </Box>
          </CardContent>
          <CardActions>
            <Buttons
              primary={true}
              label={"Reset"}
              onClick={(e) => resetFilter(e)}
            />
            <Buttons
              primary={true}
              label={"Apply"}
              onClick={(e) => handleSearch(e)}
            />
          </CardActions>
        </Card>
      </Popover>
    </>
  );
};

export default FilterList;
