import React, { useState } from "react";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import NORow from "../../../../library/custom/noRow/noRow";
import { TableLoader } from "../../../../library/common/Loader";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import ReportRowManipulte from "../../shared/ReportRowManipulate";
import { useNavigate } from "react-router-dom";
import { PAGE_SIZE } from "../../../../constant/constant";
import { CONDUCT_JHA_REPORT } from "../../../../constant/routeContant";
import downloadIcon from "../../../../assets/downloadIcon.svg";

const JhaList = ({
  jhaConductList,
  getSaftyLogJHAConductList,
  setjhaData,
  jhaData,
  usersId,
  isSafetyLog = false,
  showDownloadButton = false,
}) => {
  const navigate = useNavigate();
  const handleOnViewclick = (rows) => {
    navigate(CONDUCT_JHA_REPORT, {
      state: { id: rows.row.id, editMode: true },
    });
  };

  const onRowClick = (rows) => {
    if (rows.value) {
      navigate(CONDUCT_JHA_REPORT, {
        state: { id: rows.row.id, editMode: true },
      });
    }
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 1 },
    {
      field: "templateName",
      headerName: "Hazard Analysis Template Name",
      flex: 3,
    },
    { field: "dateOfEntry", headerName: "Date of Entry", flex: 2 },
    { field: "assetName", headerName: "Asset Name", flex: 2 },
    {
      flex: 2,
      field: "assetType",
      headerName: "Asset Type",
    },
    { field: "conductedBy", headerName: "Conducted By", flex: 2 },
    {
      flex: 1.5,
      field: "",
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <ReportRowManipulte
            rows={rows}
            viewOnClick={() => handleOnViewclick(rows)}
            view={true}
            viewLabel="View Hazard Analysis Report"
          />
        );
      },
    },
  ];
  const [page, setPage] = useState(1);
  const handlePagination = (newPage) => {
    setPage(newPage + 1);
    getSaftyLogJHAConductList({
      page: newPage + 1,
      pageSize: PAGE_SIZE,
      userId: isSafetyLog ? usersId : jhaData.userId,
      isSendMail: false,
    });
  };

  const handlesendEmail = () => {
    getSaftyLogJHAConductList({
      page: page,
      pageSize: PAGE_SIZE,
      userId: isSafetyLog ? usersId : jhaData.userId,
      isSendMail: true,
    });
  };
  return (
    <>
      {showDownloadButton ? (
        <div className="div-safety-log-filter-container">
          <div className="div-download-button-container">
            <div className="div-download-email-right" onClick={handlesendEmail}>
              <span>
                <img
                  src={downloadIcon}
                  className="DownLoadLogo"
                  alt="DownLoad Logo"
                />
              </span>
              Download Data
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="grid_main_body">
        {/* {
    jhaConductList?.isLoading ? <Loader/> :  */}
        <div className="desc_box row_uniq">
          <div className="table_style_ui">
            <GridTable
              getRowId={(r) => r?.id}
              rows={jhaConductList?.data?.result?.itemList ?? []}
              rowCount={jhaConductList?.data?.result?.itemsCount ?? 0}
              columns={columns}
              onClick={(rows) => onRowClick(rows)}
              loading={jhaConductList?.isLoading}
              page={page}
              // pagination
              pageSize={PAGE_SIZE}
              rowsPerPageOptions={[PAGE_SIZE]}
              onPageChange={handlePagination}
              backIconButtonProps={{ disabled: false }}
              disableSelectionOnClick
              components={{
                LoadingOverlay: TableLoader,
                NoRowsOverlay: NORow,
              }}
            />
          </div>
        </div>
        {/* } */}
      </div>
    </>
  );
};

export default JhaList;
