import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as userAction from "../../../redux/actions/userAction";
import UserList from "../component/users/userList";
import { useNavigate } from "react-router-dom";
import * as adminAction from "../../../redux/actions/adminCommonAction";

import {
  ACCESS_DENIED,
  ADD_ADMIN_GROUP,
  ADD_ADMIN_USER,
} from "../../../constant/routeContant";
import { API_STATUS, ORG_ID, PAGE_SIZE } from "../../../constant/constant";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { PRODUCT_ID } from "../../../constant/storm-water/constant";
import { toast, ToastContainer } from "react-toastify";

const UsersListContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userListData = useSelector((state) => state.userlistData);
  const [sendEmail, setSendEmail] = useState(false);

  const NavigateSingleUserMethode = () => {
    navigate(ADD_ADMIN_USER);
  };
  const NavigateUserGroupMethode = () => {
    navigate(ADD_ADMIN_GROUP);
  };

  const getuserLists = (data) => {
    if (data?.isSendMail) {
      setSendEmail(true);
    } else {
      setSendEmail(false);
    }
    dispatch(userAction.getUserList(data));
  };

  const companies = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.companies
  );

  const jobTitles = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.jobTitles
  );

  const division = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.divisons
  );

  const deleteUserListData = useSelector((state) => state.deleteUserListData);

  const deleteLists = (userId) => {
    dispatch(userAction.deleteList(userId));
  };

  const getDropdownDataKeyValueData = {
    orgId: ORG_ID(),
    flags: "1,6,12",
    productId: PRODUCT_ID(),
  };
  useEffect(() => {
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
  }, []);

  useEffect(() => {
    const data = {
      page: 1,
      pageSize: PAGE_SIZE,
      search: "",
      orgId: ORG_ID(),
      IsProcoreUsers: false,
      divisionId: 0,
      designation: "",
      isSendMail: false,
    };
    getuserLists(data);
  }, []);

  useEffect(() => {
    if (deleteUserListData.status === "SUCCESS") {
      const data = {
        page: 1,
        pageSize: PAGE_SIZE,
        search: "",
        isSendMail: false,
      };
      getuserLists(data);
    }
  }, [deleteUserListData]);

  useEffect(() => {
    if (userListData?.status === API_STATUS.SUCCESS && sendEmail) {
      toast("Users has been sent to registered email ID");
    }
  }, [userListData]);

  return (
    <>
      {checkPrivileges([12, 64, 65, 66]) ? (
        <>
          <ToastContainer />
          <UserList
            loading={userListData.isLoading}
            userListData={userListData?.data}
            NavigateSingleUser={NavigateSingleUserMethode}
            NavigateUserGroup={NavigateUserGroupMethode}
            getuserLists={getuserLists}
            deleteList={deleteLists}
            companies={companies}
            jobTitles={jobTitles}
            division={division}
            isShowDowdownloadButton={true}
          />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
};

export default UsersListContainer;
