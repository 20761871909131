import React, { useState } from "react";
import "./profile.scss";
import {
  PROFILETABS,
  STORMWATERPROFILETABS,
} from "../../../../constant/constant";
import TabPanel from "../../../lms/component/tabPanel/tabPanel";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Grid } from "@mui/material";
import AllAssets from "../../shared/allAssets";
import CredentialsList from "../../../credential/component/credentialsList";
import ResourceList from "../resources/resourceList";
import ProfileForm from "./profileForm";
import ViewProfile from "./viewProfile";
import Etraining from "./etraining";
import IndividualUserGroupList from "../groups/individualUserGroupList";
import { useNavigate } from "react-router-dom";
import { MY_USER_ADD_CREDENTIAL } from "../../../../constant/routeContant";
import { ToastContainer } from "react-toastify";
import { showRighSideMenu } from "../../../../utils/rolesHelper";
import ReportListContainer from "../../../storm-water/inspection/containers/reportListContainer";
import { InactiveClient } from "../../../../utils/helper";

const Profile = ({
  tabProfileClick,
  isLoadingResources,
  resources,
  getResourceListData,
  isloadinguserGroup,
  getUserGroupListsData,
  individualUserGroupData,
  leaveGroup,
  credentialListData,
  getCredentials,
  isLoadingCredential,
  tabtraingClick,
  coursesUserListData,
  getCoursesUserListData,
  isCoursesLoading,
  lessionUserListData,
  getLessionUserListData,
  isLessonLoading,
  projectsListData,
  getProjectListData,
  isProjectLoading,
  equipmentList,
  getEquipmentList,
  isEquipmentLoading,
  vehicles,
  getVehicleList,
  isVehicleLoading,
  assetList,
  isAssetLoading,
  getAssetListData,
  tabClick,
  divisions,
  jobTitles,
  roles,
  getUserListDetsilsData,
  uploadProfileFile,
  uploadSignatureFile,
  fileUploadData,
  isProfileSuccess,
  adduserLists,
  updateUserListData,
  isProfileSignature,
  isProfileSignatureLoading,
  resourceCategory,
  resourceType,
  getClientDetsilsData,
  viewContractors,
  updateClient,
  updateContractors,
  uploadClientProfileFile,
  uploadClientSignatureFile,
  isClinetProfileSignature,
  isClinetProfileSignatureLoading,
  isClientProfileSuccess,
  updateClientData,
  uploadContractorProfileFile,
  uploadContractorSignatureFile,
  isContractorProfileSuccess,
  isContractorProfileSignatureLoading,
  isContractorProfileSignature,
  updateContractorData,
  isClient,
  divisionDropdown,
  categoryListData,
  getToolBoxTalkListResult,
  getToolBoxTalkListData,
  fileUploadedData,
  fileErrorMessage,
  signatureImageError,
  profileImageError,
  clientProfileError,
  contractorProfileError,
  contractorSignatureError,
  clientSignatureError,
}) => {
  const showMenu = showRighSideMenu();

  function a11yProps(index, id) {
    return {
      id: `${id}`,
      "aria-controls": `${index}`,
    };
  }
  const [value, setValue] = useState(0);
  const [profile, setProfile] = useState(1);
  const naviage = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
    tabProfileClick(event?.target?.id);
  };

  const editProfile = (id) => {
    if (InactiveClient()) return;
    setProfile(id);
  };

  const NavigateAddCredential = () => {
    if (InactiveClient()) return;
    naviage(MY_USER_ADD_CREDENTIAL);
  };

  return (
    <>
      <Grid container>
        {showMenu?.isSW && (
          <Grid item md={12}>
            {isClient ? (
              <Tabs value={value} onChange={handleChange}>
                <Tab
                  className="lessonList"
                  label={STORMWATERPROFILETABS[0]?.name}
                  key={STORMWATERPROFILETABS[0]?.id}
                  {...a11yProps(
                    STORMWATERPROFILETABS[0]?.id,
                    STORMWATERPROFILETABS[0]?.id
                  )}
                />
              </Tabs>
            ) : (
              <Tabs value={value} onChange={handleChange}>
                {STORMWATERPROFILETABS?.map((tabItem, i) => {
                  return (
                    <Tab
                      className="lessonList"
                      label={tabItem?.name}
                      key={i}
                      {...a11yProps(i, tabItem?.id)}
                    />
                  );
                })}
              </Tabs>
            )}
          </Grid>
        )}

        {showMenu?.isSafety && (
          <Grid item md={12}>
            {isClient ? (
              <Tabs value={value} onChange={handleChange}>
                <Tab
                  className="lessonList"
                  label={PROFILETABS[0]?.name}
                  key={PROFILETABS[0]?.id}
                  {...a11yProps(PROFILETABS[0]?.id, PROFILETABS[0]?.id)}
                />
              </Tabs>
            ) : (
              <Tabs value={value} onChange={handleChange}>
                {PROFILETABS?.map((tabItem, i) => {
                  return (
                    <Tab
                      className="lessonList"
                      label={tabItem?.name}
                      key={i}
                      {...a11yProps(i, tabItem?.id)}
                    />
                  );
                })}
              </Tabs>
            )}
          </Grid>
        )}

        <Grid item md={12} mt={3} px={2}>
          <TabPanel value={value} index={0}>
            <ToastContainer />
            {profile === 2 && (
              <ProfileForm
                divisions={divisions}
                roles={roles}
                jobTitles={jobTitles}
                getUserListDetsilsData={getUserListDetsilsData}
                uploadProfileFile={uploadProfileFile}
                fileUploadData={fileUploadData}
                uploadSignatureFile={uploadSignatureFile}
                isProfileSuccess={isProfileSuccess}
                adduserLists={adduserLists}
                updateUserListData={updateUserListData}
                isProfileSignature={isProfileSignature}
                isProfileSignatureLoading={isProfileSignatureLoading}
                editProfile={editProfile}
                getClientDetsilsData={getClientDetsilsData}
                viewContractors={viewContractors}
                updateClient={updateClient}
                updateContractors={updateContractors}
                uploadClientProfileFile={uploadClientProfileFile}
                uploadClientSignatureFile={uploadClientSignatureFile}
                isClinetProfileSignature={isClinetProfileSignature}
                isClientProfileSuccess={isClientProfileSuccess}
                isClinetProfileSignatureLoading={
                  isClinetProfileSignatureLoading
                }
                updateClientData={updateClientData}
                uploadContractorProfileFile={uploadContractorProfileFile}
                uploadContractorSignatureFile={uploadContractorSignatureFile}
                isContractorProfileSuccess={isContractorProfileSuccess}
                isContractorProfileSignatureLoading={
                  isContractorProfileSignatureLoading
                }
                isContractorProfileSignature={isContractorProfileSignature}
                updateContractorData={updateContractorData}
                fileUploadedData={fileUploadedData}
                fileErrorMessage={fileErrorMessage}
                signatureImageError={signatureImageError}
                profileImageError={profileImageError}
                clientProfileError={clientProfileError}
                contractorProfileError={contractorProfileError}
                contractorSignatureError={contractorSignatureError}
                clientSignatureError={clientSignatureError}
              />
            )}
            {profile === 1 && (
              <ViewProfile
                editProfile={editProfile}
                getUserListDetsilsData={getUserListDetsilsData}
                getClientDetsilsData={getClientDetsilsData}
                viewContractors={viewContractors}
              />
            )}
          </TabPanel>
          <TabPanel value={value} index={1} className="innerTabAssets">
            <>
              {showMenu?.isSafety && (
                <Grid container item md={12}>
                  <AllAssets
                    projectsListData={isProjectLoading ? [] : projectsListData}
                    getProjectListData={getProjectListData}
                    isLoading={isProjectLoading}
                    equipmentList={equipmentList}
                    getEquipmentList={getEquipmentList}
                    isEquipmentLoading={isEquipmentLoading}
                    vehicles={vehicles}
                    getVehicleList={getVehicleList}
                    isVehicleLoading={isVehicleLoading}
                    assetList={assetList}
                    isAssetLoading={isAssetLoading}
                    getAssetListData={getAssetListData}
                    tabClick={tabClick}
                    isProfile={true}
                    divisionDropdown={divisionDropdown}
                  />
                </Grid>
              )}
              {showMenu?.isSW && (
                <ReportListContainer isShow={false} isprofile={true} />
              )}
            </>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <CredentialsList
              isShow={false}
              credentialListData={credentialListData}
              getUserCredentials={getCredentials}
              isLoading={isLoadingCredential}
              isProfile={true}
              NavigateAddCredential={NavigateAddCredential}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <ResourceList
              isLoading={isLoadingResources}
              resources={resources?.result}
              getResourceListData={getResourceListData}
              isShow={false}
              filterShow={false}
              isProfile={true}
              resourceCategory={resourceCategory}
              resourceType={resourceType}
              isShowDowdownloadButton={false}
            />
          </TabPanel>
          <TabPanel value={value} index={4} className="etraingOver">
            <Etraining
              tabtraingClick={tabtraingClick}
              //  isSafetyLog={false}
              coursesUserListData={coursesUserListData}
              getCoursesUserListData={getCoursesUserListData}
              isLoading={isCoursesLoading}
              lessionUserListData={lessionUserListData}
              getLessionUserListData={getLessionUserListData}
              isLessonLoading={isLessonLoading}
              isProfile={true}
              categoryListData={categoryListData}
              getToolBoxTalkListResult={getToolBoxTalkListResult}
              getToolBoxTalkListData={getToolBoxTalkListData}
              showDownloadButton={false}
            />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <IndividualUserGroupList
              loading={isloadinguserGroup}
              getUserGroupListsData={getUserGroupListsData}
              individualUserGroupData={
                isloadinguserGroup
                  ? []
                  : individualUserGroupData?.data?.userInroup
              }
              itemsCount={individualUserGroupData?.data?.itemsCount}
              isShow={false}
              leaveGroup={leaveGroup}
              isProfile={true}
            />
          </TabPanel>
        </Grid>
      </Grid>
    </>
  );
};

export default Profile;
