import React, { useState, useEffect } from "react";
import "./dynamicTable.scss";
import { getDayByKey, getValueByKey } from "../../../utils/swInspectionHelper";
import { dynamicTable } from "../../../assets";
import { formatRainfall } from "../../../utils/helper";
import parse from "html-react-parser";

const DynamicTable = ({ title, columns, data, checkbox, initialName = "" }) => {
  const isRowEmpty = (row) => {
    return columns.every((column) => {
      const value = row[column.key];
      return value === "" || value === 0 || value === null;
    });
  };

  const visibleColumns = columns?.filter((column) => !column?.hide);

  return (
    <div className="dynamic-table">
      <h3>{title}</h3>
      <table>
        <thead>
          <tr>
            {visibleColumns?.map((column, index) => (
              <th key={index} className={column.className || ""}>
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data
            ?.filter((row) => !isRowEmpty(row))
            .map((row, rowIndex) => (
              <tr key={rowIndex}>
                {visibleColumns.map((column, colIndex) => (
                  <td key={colIndex}>
                    {checkbox && typeof row[column.key] === "boolean" ? (
                      <span className="checkboxShow">
                        {row[column.key] ? (
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.40625 15.5L16.8438 8.09375L15.25 6.5L9.40625 12.3125L6.75 9.6875L5.15625 11.2812L9.40625 15.5ZM2.75 21.5C2.13125 21.5 1.60156 21.2797 1.16094 20.8391C0.720312 20.3984 0.5 19.8687 0.5 19.25V2.75C0.5 2.13125 0.720312 1.60156 1.16094 1.16094C1.60156 0.720313 2.13125 0.5 2.75 0.5H19.25C19.8687 0.5 20.3984 0.720313 20.8391 1.16094C21.2797 1.60156 21.5 2.13125 21.5 2.75V19.25C21.5 19.8687 21.2797 20.3984 20.8391 20.8391C20.3984 21.2797 19.8687 21.5 19.25 21.5H2.75Z"
                              fill="#1B436E"
                            />
                          </svg>
                        ) : (
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="#1B436E"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="1"
                              y="1"
                              width="18"
                              height="18"
                              rx="1"
                              stroke="#1B436E"
                              strokeWidth="2"
                            />
                          </svg>
                        )}
                      </span>
                    ) : column.key === "rainfallAmt" ? (
                      formatRainfall(row[column.key])
                    ) : column.key === "initials" ? (
                      initialName
                    ) : column.key === "timeLimit" ? (
                      getDayByKey(row[column.key])
                    ) : column.key === "injuryDescription" ? (
                       parse(unescape(row[column.key]))
                    ) : typeof row[column.key] === "number" ? (
                      getValueByKey(row[column.key])
                    ) : (
                      row[column.key] || "N/A"
                    )}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default DynamicTable;