import React, { useEffect, useState } from "react";
import ViewAttendes from "../component/toolBoxAssignUserStatus/viewAttendes";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as toolboxAction from "../../../redux/actions/toolBoxAction";
import { useSelector } from "react-redux";
import Loader from "../../../library/common/Loader";
import { USER_INFO } from "../../../constant/constant";

const ViewAttendesContainer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const scheduleId = location.state.scheduleId;
  const instructorUserId = location.state.instructorUserId;
  const fromMyTraining = location.state.fromMyTraining;
  const getScheduleAttendeesDetailsData = useSelector(
    (state) => state.getScheduleAttendeesDetails
  );
  const userInfo = USER_INFO();
  const [completedBy, setcompletedBy] = useState(
    location?.state?.userName ??
    `${JSON.parse(userInfo).firstName ?? ""} ${JSON.parse(userInfo)?.lastName ?? ""
    }`
  );

  function getScheduleAttendeesDetails(data) {
    dispatch(toolboxAction.getScheduleAttendeesDetails(data));
  }
  useEffect(() => {
    getScheduleAttendeesDetails({
      instructorUserId: instructorUserId,
      scheduleId: scheduleId,
    });
  }, []);

  return (
    <div>
      {getScheduleAttendeesDetailsData?.isLoading && <Loader />}
      <ViewAttendes
        getScheduleAttendeesDetailsData={getScheduleAttendeesDetailsData.data}
        fromMyTraining={fromMyTraining}
        completedBy={completedBy}
      />
    </div>
  );
};

export default ViewAttendesContainer;
