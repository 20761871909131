import React, { useState } from "react";
import { Grid } from "@mui/material";
import TabPanel from "../../lms/component/tabPanel/tabPanel";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { DIVISIONSTABS, SWDIVISIONSTABS } from "../../../constant/constant";
import EquipmentTable from "../component/equipments/equipmentTable";
import ProjectTable from "../component/project/projectTable";
import VehicleTable from "../component/vehicles/vehicleTable";
import AssetTable from "../component/assets/assetTable";
import UserList from "../component/users/userList";
import UserGroupList from "../component/groups/userGroupList";
import ContractorTable from "../component/contractor/contractorTable";
import { showRighSideMenu } from "../../../utils/rolesHelper";
import SwProjectListContainer from "../../storm-water/admin/contaniners/swProjectListContainer";

const AllAssets = ({
  projectsListData,
  getProjectListData,
  isLoading,
  equipmentList,
  getEquipmentList,
  isEquipmentLoading,
  vehicles,
  getVehicleList,
  isVehicleLoading,
  assetList,
  isAssetLoading,
  getAssetListData,
  tabClick,
  isProfile,
  divisionId,
  loading,
  userListData,
  getuserLists,
  GroupListData,
  noOfRecords,
  getuserGroupLists,
  loadingGroup,
  divisionDropdown,
  isDivisionDropdown,
  loadingContractor,
  ContractorListData,
  getContractorListData,
}) => {
  function a11yProps(index, id) {
    return {
      id: `${id}`,
      "aria-controls": `${index}`,
    };
  }
  const showMenu = showRighSideMenu();

  console.log(ContractorListData, "ContractorListData");
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    tabClick(event?.target?.id);
  };
  return (
    <Grid container>
      {showMenu?.isSW && (
        <Grid item md={12}>
          <Tabs value={value} onChange={handleChange}>
            {SWDIVISIONSTABS?.map((tabItem, i) => {
              return (
                <Tab
                  className="lessonList"
                  label={tabItem?.name}
                  key={i}
                  {...a11yProps(i, tabItem?.id)}
                />
              );
            })}
          </Tabs>
        </Grid>
      )}
      {showMenu?.isSafety && (
        <Grid item md={12}>
          <Tabs value={value} onChange={handleChange}>
            {DIVISIONSTABS?.map((tabItem, i) => {
              return (
                <Tab
                  className="lessonList"
                  label={tabItem?.name}
                  key={i}
                  {...a11yProps(i, tabItem?.id)}
                />
              );
            })}
          </Tabs>
        </Grid>
      )}
      {showMenu?.isSafety && (
        <Grid item md={12} mt={3} px={2}>
          <TabPanel value={value} index={0}>
            <ProjectTable
              projectsListData={isLoading ? [] : projectsListData}
              getProjectListData={getProjectListData}
              isProjectsLoading={isLoading}
              filterShow={false}
              isProfile={isProfile}
              divisionId={divisionId}
              divisionDropdown={divisionDropdown}
              isDivisionDropdown={isDivisionDropdown}
              isShowDowdownloadButton={false}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <EquipmentTable
              equipmentList={equipmentList}
              getEquipmentList={getEquipmentList}
              isLoading={isEquipmentLoading}
              filterShow={false}
              isProfile={isProfile}
              divisionId={divisionId}
              divisionDropdown={divisionDropdown}
              isDivisionDropdown={isDivisionDropdown}
              isShowDowdownloadButton={false}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <VehicleTable
              vehicles={vehicles}
              getVehicleList={getVehicleList}
              isLoading={isVehicleLoading}
              filterShow={false}
              isProfile={isProfile}
              divisionId={divisionId}
              divisionDropdown={divisionDropdown}
              isDivisionDropdown={isDivisionDropdown}
              isShowDowdownloadButton={false}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <AssetTable
              assetList={assetList}
              isAssetLoading={isAssetLoading}
              getAssetListData={getAssetListData}
              filterShow={false}
              isProfile={isProfile}
              divisionId={divisionId}
              divisionDropdown={divisionDropdown}
              isDivisionDropdown={isDivisionDropdown}
              isShowDowdownloadButton={false}
            />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <UserList
              loading={loading}
              userListData={userListData}
              isDivision={false}
              getuserLists={getuserLists}
              divisionId={divisionId ?? 0}
              isShowDowdownloadButton={false}
            />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <UserGroupList
              getuserLists={getuserLists}
              GroupListData={GroupListData}
              noOfRecords={noOfRecords}
              getuserGroupLists={getuserGroupLists}
              loading={loadingGroup}
              isDivision={false}
              divisionId={divisionId ?? 0}
              isShowDowdownloadButton={false}
            />
          </TabPanel>
          <TabPanel value={value} index={6}>
            <ContractorTable
              contractorListData={ContractorListData}
              loadingContractor={loadingContractor}
              divisionId={divisionId ?? 0}
              getContractorListData={getContractorListData}
              filterShow={false}
              isShowDowdownloadButton={false}
            />
          </TabPanel>
        </Grid>
      )}
      {showMenu?.isSW && (
        <Grid item md={12} mt={3} px={2}>
          <TabPanel value={value} index={0}>
            <SwProjectListContainer isShow={false} divisionId={divisionId} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <UserList
              loading={loading}
              userListData={userListData}
              isDivision={false}
              getuserLists={getuserLists}
              divisionId={divisionId ?? 0}
              isShowDowdownloadButton={false}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <UserGroupList
              getuserLists={getuserLists}
              GroupListData={GroupListData}
              noOfRecords={noOfRecords}
              getuserGroupLists={getuserGroupLists}
              loading={loadingGroup}
              isDivision={false}
              divisionId={divisionId ?? 0}
              isShowDowdownloadButton={false}
            />
          </TabPanel>
        </Grid>
      )}
    </Grid>
  );
};

export default AllAssets;
