import React from "react";
import Buttons from "../../../../library/custom/button/Button";
import AddIcon from "@mui/icons-material/Add";
import "./lmsHeader.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { InactiveClient } from "../../../../utils/helper";

const LmsHeader = (dataHeader) => {
  const navigate = useNavigate();
  let url = useLocation();

  return (
    <>
      <div className="headerWrapper">
        <div className="headerTitle">{dataHeader.dataHeader.title}</div>
        <div className="headerBox">
          <div className="headerLeft">
            <ul>
              {dataHeader?.dataHeader?.menu.map((list, i) => (
                <li
                  className={url.pathname === list.route ? "active" : ""}
                  onClick={() => navigate(list.route)}
                  key={i}
                >
                  {list.text}
                </li>
              ))}
            </ul>
          </div>
          <div className="headerRight">

            {dataHeader?.dataHeader?.btnText && (
              <Buttons
                //primary={true}
                varientAddIconBlue
                label={dataHeader.dataHeader.btnText}
                startIcon={<AddIcon />}
                onClick={() => { if (InactiveClient()) return; navigate(dataHeader.dataHeader.btnLink) }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LmsHeader;
