import React, { useState } from "react";
import Buttons from "../../../../library/custom/button/Button";
import { FormControl, FormControlLabel, FormGroup, Grid } from "@mui/material";
import { ISwitch, PAGE_SIZE, USER_ID } from "../../../../constant/constant";
import { TableLoader } from "../../../../library/common/Loader";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import NORow from "../../../../library/custom/noRow/noRow";
import SearchIcon from "@mui/icons-material/Search";
import * as jhaAction from "../../../../redux/actions/jhaAction";
import { useDispatch } from "react-redux";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import ReportRowManipulte from "../../shared/ReportRowManipulate";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import { InactiveClient } from "../../../../utils/helper";

const JhaListTemplate = ({
  addTemplate,
  isLoading,
  templateList,
  itemsCount,
  getTemplateListData,
  handleStatusChange,
  handleEditOnClick,
}) => {
  const dataBack = {
    title: "Back to Hazard Analysis Configuration",
    route: -1,
  };

  const dispatch = useDispatch();

  const handleChange = (templateSetItem) => {
    if (InactiveClient()) return;
    const newCatList = [...templateList];
    newCatList.forEach((lists) => {
      if (lists.id === templateSetItem.id) {
        lists.isGlobal = false;
        lists.hazardsId = lists.id;
        lists.isActive = !lists.isActive;
        lists.listsmodifiedBy = parseInt(USER_ID());
      }
    });
    handleStatusChange(templateSetItem);
  };

  const columns = [
    {
      field: "templateName",
      headerName: "Hazard Analysis Templates",
      flex: 1.9,
    },
    { field: "dateOfEntry", headerName: "Date of Entry", flex: 1 },
    { field: "assetType", headerName: "Asset Type", flex: 1 },
    { field: "createdBy", headerName: "Created by", flex: 1 },
    {
      flex: 1,
      field: "isActive",
      headerName: "Status",
      renderCell: (rows) => (
        <Grid className="catListBox">
          <Grid className="closeIcon">
            <FormControl component="fieldset" variant="standard">
              <FormGroup className="isActiveBox">
                {checkPrivileges([58, 12]) && (
                  <FormControlLabel
                    control={
                      <ISwitch
                        checked={
                          rows?.row.isActive ? rows?.row.isActive : false
                        }
                        onChange={() => handleChange(rows?.row)}
                        name="status"
                      />
                    }
                  />
                )}
                <div>{rows?.row?.isActive ? "Active" : "Inactive"}</div>
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      ),
    },
    {
      flex: 0.6,
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={0}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={[]}
          fileNameXL={"Categories List"}
        />
      ),
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <ReportRowManipulte
            rows={rows}
            editOnClick={() => {
              handleEditOnClick(rows.row);
            }}
            edit={checkPrivileges([58, 59, 12])}
            editLabel="Edit Template"
          />
        );
      },
    },
  ];

  const [search, setsearch] = useState("");
  const [page, setpage] = useState(1);
  function handleautoSearch(params) {
    setsearch(params);
    getTemplateListData({
      page: page,
      pageSize: PAGE_SIZE,
      search: params,
    });
  }

  // pagination

  const handlePagignation = (newPage) => {
    setpage(newPage + 1);
    getTemplateListData({
      page: newPage + 1,
      pageSize: PAGE_SIZE,
      search: search,
    });
  };

  return (
    <section className="grid_main_sec">
      <div className="grid_main_header two_grid">
        <div className="grid_heading_style">Hazard Analysis Templates</div>
        <div>
          {checkPrivileges([58, 59, 12]) && (
            <Buttons
              id="btnAddContact"
              label={"Create Template"}
              varientAddIconBlue={true}
              onClick={() => addTemplate()}
            />
          )}
        </div>
      </div>

      <Grid item container md={12} className="CategoriesBox" mt={3}>
        <Grid item md={5} xs={8} mb={3}>
          <div className="searchcaseid">
            <label htmlFor="search">
              <span>
                <SearchIcon />
              </span>
              <div>
                <input
                  id="search"
                  placeholder="Search Hazard Analysis Templates"
                  type="text"
                  value={search}
                  className=""
                  onChange={(e) => handleautoSearch(e.target.value)}
                  autoComplete="off"
                />
              </div>
            </label>
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid container item md={12} xs={12}>
          <Grid item md={12} className="" mt={3}>
            <div className="desc_box row_uniq">
              <div className="table_style_ui">
                <GridTable
                  getRowId={(r) => r.id}
                  rows={templateList ?? []}
                  rowCount={itemsCount ?? 0}
                  columns={columns}
                  loading={isLoading}
                  page={1}
                  pagination
                  pageSize={PAGE_SIZE}
                  rowsPerPageOptions={[PAGE_SIZE]}
                  onPageChange={handlePagignation}
                  backIconButtonProps={{ disabled: false }}
                  disableSelectionOnClick
                  components={{
                    LoadingOverlay: TableLoader,
                    NoRowsOverlay: NORow,
                  }}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <div onClick={() => dispatch(jhaAction.clearJha())}>
        <BackBtn dataBack={dataBack} />
      </div>
    </section>
  );
};

export default JhaListTemplate;
