import React, { useEffect, useState } from "react";
import "../../../../src/feature/incidents/cases/addCaseList.scss";
import { Grid } from "@mui/material";
import ViewcCasetable from "../../../../src/library/custom/gridTable/GridTable";
import SettingTable from "../../../../src/feature/incidents/incidentList/SettingTable";
import { useNavigate } from "react-router-dom";
import { TableLoader } from "../../../../src/library/common/Loader";
import NORow from "../../../../src/library/custom/noRow/noRow";
import { ORG_ID } from "../../../constant/constant";
import { REPORTS_CREDENTIAL_VIEW } from "../../../constant/routeContant";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import Cards from "../component/Cards";
import DatePicker from "../../../library/custom/datePicker/DatePicker";
import {
  getCurrentDate,
  getDateWithDay,
  getFormatedDate,
  downloadExcel,
  downloadPDF,
} from "../../../utils/helper";
import { Popover } from "@mui/material";
import commonImages from "../../../assets";
import "./reportCrential.scss";
export default function ReportCredentialList({
  dataBack,
  getLookupCredentialReportData,
  getLookupCredentialReports,
  isLoading,
  credentialTabId,
  groupId,
  PAGE_SIZES,
  namess,
  location,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [anchorElAdd, setAnchorElAdd] = useState(null);
  const [page, setPage] = useState(1);
  const [days, setDays] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    console.log(location);
    if (location?.state?.timePeriod == 5)
      setDays({
        key: 12,
        value: "Custom",
      });
  }, [location]);

  const cardOnClick = (value) => {
    if (days.key === value.key) {
      setDays("");
      const defaultData = {
        page: 1,
        pageSize: PAGE_SIZES,
        groupId: groupId ? groupId : 0,
        orgId: ORG_ID(),
        tabId: credentialTabId,
        startDate: "",
        endDate: "",
      };
      getLookupCredentialReports(groupId, credentialTabId, "", "");
    } else {
      if (value.key !== 12) {
        let dateObj = getDateWithDay(value.key);
        const defaultData = {
          page: 1,
          pageSize: PAGE_SIZES,
          groupId: groupId ? groupId : 0,
          orgId: ORG_ID(),
          tabId: credentialTabId,
          startDate: dateObj.getCurrentDate,
          endDate: dateObj.futureData,
        };
        getLookupCredentialReports(
          groupId,
          credentialTabId,
          dateObj.getCurrentDate,
          dateObj.futureData
        );
      }
      setDays(value);
    }
  };

  const [startDate, setStartDate] = useState(
    location?.state?.startDate
      ? getFormatedDate(location?.state?.startDate)
      : getCurrentDate()
  );
  const [endDate, setEndDate] = useState(
    location?.state?.endDate
      ? getFormatedDate(location?.state?.endDate)
      : getCurrentDate()
  );

  const onchangeEnddatePicker = (e) => {
    const defaultData = {
      page: 1,
      pageSize: PAGE_SIZES,
      groupId: groupId,
      orgId: ORG_ID(),
      tabId: credentialTabId,
      startDate: getFormatedDate(startDate),
      endDate: getFormatedDate(e),
    };
    setEndDate(e);
    getLookupCredentialReports(
      groupId,
      credentialTabId,
      getFormatedDate(startDate),
      getFormatedDate(e)
    );
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const openAdd = Boolean(anchorElAdd);
  const idAdd = openAdd ? "simple-popover" : undefined;

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    const data = {
      page: newPage + 1,
      pageSize: PAGE_SIZES,
      groupId: groupId ? groupId : 0,
      orgId: ORG_ID(),
      tabId: credentialTabId ? credentialTabId : 0,
      startDate: "",
      endDate: "",
    };
    getLookupCredentialReports(groupId, credentialTabId);
  };
  const handleViewOnclick = (rows) => {
    navigate(REPORTS_CREDENTIAL_VIEW, {
      state: { id: rows.row.id, editMode: true, tabId: credentialTabId },
    });
  };

  const rowsPdf =
    getLookupCredentialReportData?.data?.result?.credentialData?.map(
      (asset) => [
        asset?.userName,
        asset?.division,
        asset?.credentialName,
        asset?.credentialNameId,
        asset?.passingDate,
        asset?.expirationDate,
        asset?.issuingBody,
      ]
    );
  const columnsDownload = [
    {
      id: "userName",
      label: `Name`,
      sortable: false,
      isSorted: false,
      // sortDirection,
    },
    {
      id: "division",
      label: `Division`,
      sortable: false,
      isSorted: false,
      // sortDirection,
    },
    {
      id: "credentialName",
      label: `Credential Name`,
      sortable: false,
      isSorted: false,
      // sortDirection,
    },
    {
      id: "credentialNameId",
      label: `Credential Number.`,
      sortable: false,
      isSorted: false,
      // sortDirection,
    },
    {
      id: "passingDate",
      label: `Passing Date`,
      sortable: false,
      isSorted: false,
      // sortDirection,
    },
    {
      id: "expirationDate",
      label: `Expiration Date`,
      sortable: false,
      isSorted: false,
      // sortDirection,
    },
    {
      id: "issuingBody",
      label: `Issuing Body`,
      sortable: false,
      isSorted: false,
      // sortDirection,
    },
  ];
  const columns = [
    { field: "userName", headerName: "Name", flex: 3 },
    {
      field: "division",
      headerName: "Division",
      flex: 3,
    },
    { field: "credentialName", headerName: "Credential Name", flex: 3 },
    { field: "credentialNameId", headerName: "Credential Number.", flex: 1.5 },
    { field: "passingDate", headerName: "Passing Date", flex: 3 },
    { field: "expirationDate", headerName: "Expiration Date", flex: 3 },
    { field: "issuingBody", headerName: "Issuing Body", flex: 2.8 },
    {
      field: "",
      flex: 2,
      sortable: false,
      disableClickEventBubbling: true,
    },
  ];

  const filterDays = [
    {
      key: 0,
      value: "Today",
    },
    {
      key: 7,
      value: "7 days",
    },
    {
      key: 14,
      value: "14 days",
    },
    {
      key: 30,
      value: "30 days",
    },
    {
      key: 12,
      value: "Custom",
    },
  ];
  return (
    <>
      <section className="grid_main_sec">
        <div className="grid_main_header two_grid">
          <div className="grid_heading_style">Credential Reports</div>
        </div>
        <Grid item lg={8} xs={12} sm={12} md={8}>
          <FormLabels label={"Select Time Period"} />
          <Cards
            staticCardData={true}
            cardData={filterDays}
            selectedVehicleAccident={days}
            cardClickHandler={cardOnClick}
          />
        </Grid>
        {days?.value === "Custom" ? (
          <>
            <Grid item container mt={1} spacing={1}>
              <Grid item lg={4} xs={12} sm={12}>
                <FormLabels label={"Custom Time Period"} isRequired={false} />
              </Grid>
            </Grid>

            <Grid item container spacing={2} mb={2}>
              <Grid item lg={3} xs={12} sm={12} md={4}>
                <FormLabels label={"From"} isRequired={false} />
                <DatePicker
                  maxDateEnable={true}
                  value={startDate}
                  disabled={false}
                  onChangeHandler={(e) => setStartDate(e)}
                />
              </Grid>
              <Grid item lg={3} xs={12} sm={12} md={4}>
                <FormLabels label={"To"} isRequired={false} />

                <DatePicker
                  maxDateEnable={true}
                  minDate={startDate}
                  value={endDate}
                  disabled={false}
                  onChangeHandler={(e) => onchangeEnddatePicker(e)}
                />
              </Grid>
            </Grid>
          </>
        ) : null}

        <div className="credntialReporttopHeaderDrillRight">
          <div
            className="downloadBox"
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
          >
            <div className="downloadIcon">
              <img src={commonImages?.downloadWhite} alt="Download" />
            </div>
            <div className="downloadText">Download Data</div>
          </div>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div className="downloadListBox">
              <div className="downloadList">
                <div className="downloads">
                  <div
                    className="downloadListIcon"
                    onClick={() =>
                      downloadPDF(
                        rowsPdf,
                        columnsDownload.map((col) => col.label),
                        "Credentials.pdf"
                      )
                    }
                  >
                    <img src={commonImages.pdfFill} alt="" />
                    <span>Download PDF</span>
                  </div>
                </div>
              </div>
              <div className="downloadList">
                <div className="downloads">
                  <div
                    className="downloadListIcon"
                    onClick={() =>
                      downloadExcel(
                        getLookupCredentialReportData?.data?.result
                          ?.credentialData ?? [],
                        "Credential.xlsx"
                      )
                    }
                  >
                    <img src={commonImages.excelDownload} alt="" />
                    <span>Download .xlsx</span>
                  </div>
                </div>
              </div>
            </div>
          </Popover>
        </div>
        <Grid mt={3}>
          <div className="grid_main_header two_grid">
            <div className="grid_heading_style">{namess}</div>
          </div>
          <div className="grid_main_body">
            <div className="desc_box row_uniq">
              <div className="table_style_ui">
                <ViewcCasetable
                  getRowId={(r) => r.id}
                  rows={
                    getLookupCredentialReportData?.data?.result
                      ?.credentialData ?? []
                  }
                  rowCount={
                    getLookupCredentialReportData?.data?.result?.noOfRecords ??
                    0
                  }
                  columns={columns}
                  loading={isLoading}
                  components={{
                    LoadingOverlay: TableLoader,
                    NoRowsOverlay: NORow,
                  }}
                  checkboxSelection={false}
                  page={page}
                  pagination
                  pageSize={PAGE_SIZES}
                  rowsPerPageOptions={[PAGE_SIZES]}
                  onPageChange={handlePagignation}
                  backIconButtonProps={{ disabled: false }}
                  disableSelectionOnClick
                />
              </div>
            </div>
          </div>
        </Grid>
      </section>
    </>
  );
}
