import React, { useState, useEffect } from "react";
import Buttons from "../../../../library/custom/button/Button";
import AddIcon from "@mui/icons-material/Add";
import ReportFilter from "./reportFilter";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import {
  COLUMNS_REPRIMINDS_VIEW,
  STATUS_OF_LIST_REPRIMAND,
} from "../../../../constant/inspectionConstant";
import { useDispatch, useSelector } from "react-redux";
import SettingTable from "../../../../library/custom/gridTable/SettingTable";
import TableRowAction from "../../../../library/custom/gridTable/TableRowAction";
import DeleteAndDownloadAction from "../../../../library/custom/gridTable/deleteAndDownloadAction";
import { TableLoader } from "../../../../library/common/Loader";
import NORow from "../../../../library/custom/noRow/noRow";
import { useNavigate } from "react-router-dom";
import downloadIcon from "../../../../assets/downloadIcon.svg";
import { toast, ToastContainer } from "react-toastify";

import {
  REPRIMAND,
  VIEW_REPRIMAND_CONTAINER,
} from "../../../../constant/routeContant";
import { getReprimandList } from "../../../../redux/actions/reprimandAction";
import { InvolvedPartyOptionData } from "../../../../constant/inspectionConstant";
import { getFormatedDate, InactiveClient } from "../../../../utils/helper";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import { PAGE_SIZE } from "../../../../constant/constant";

const ReprimandList = ({ filterShow }) => {
  const dispatch = useDispatch();
  const { reprimandList, isLoading, isEmailSuccess } = useSelector(
    (state) => state.reprimandView
  );
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [anchorEl, setAnchorEl] = useState(null);
  const [reportIdOfChekbox, setReportIdOfChekbox] = useState([]);
  const [filter, setFilter] = useState({});
  const [searchText, setSearchText] = React.useState("");
  const [isSendEmail, setIsSendEmail] = useState(false);
  const navigate = useNavigate();
  const [isTableLoading, setisTableLoading] = useState(false);
  const deleteItem = React.useCallback((item, action) => {
    if (action === "single") {
    } else {
    }
  }, []);
  function handleViewReprimand(row) {
    navigate(VIEW_REPRIMAND_CONTAINER, {
      state: {
        id: row?.reprimandDtlId,
        editMode: true,
        involvedPartyUserId: row?.involvedPartyUserId,
        status: row?.status,
      },
    });
  }

  const columns = React.useMemo(() => {
    const headers = [...COLUMNS_REPRIMINDS_VIEW];
    headers.push({
      field: "",
      flex: 1,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return filterShow ? (
          <TableRowAction
            rows={rows}
            usingComp={"Reprimand"}
            view={checkPrivileges([12, 81, 82, 83])}
            viewLabel={"View Reprimand"}
            deleteLabel={"Delete Reprimand"}
            viewOnClick={(row) => handleViewReprimand(row)}
          />
        ) : (
          ""
        );
      },
    });
    return headers;
  }, [reprimandList, pageSize, navigate]);

  const handleClose = (event) => {
    event?.currentTarget.type === "button"
      ? setAnchorEl(event.currentTarget)
      : setAnchorEl(null);
  };

  useEffect(() => {
    const { statusType, searchType, startDate, endDate } = filter;
    setIsSendEmail(false);
    dispatch(
      getReprimandList({
        searchText: searchText,
        status: (statusType && statusType[0]) ?? "",
        empTypes: (searchType && searchType[0]?.key + 1) ?? "",
        page: page ?? "",
        fromdate: (startDate && getFormatedDate(startDate)) ?? "",
        todate: (endDate && getFormatedDate(endDate)) ?? "",
        pageSize: pageSize ?? "",
        isSendMail: false,
      })
    );
  }, [dispatch, searchText, filter, page, pageSize]);

  const handleSearchFilter = (event, state) => {
    setPage(1);
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    setFilter(state);
    handleClose();
  };
  const handleClear = (event, state) => {
    setPage(1);
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    setSearchText("");
    setFilter([]);
    handleClose();
  };

  const onRowClick = (rows) => {
    if (rows.value) {
      const { row } = rows;
      handleViewReprimand(row);
    }
  };
  const searhHandler = (e) => {
    setPage(1);
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    setSearchText(e.target.value);
  };

  const sendEmail = () => {
    const { statusType, searchType, startDate, endDate } = filter;
    setIsSendEmail(true);
    dispatch(
      getReprimandList({
        searchText: searchText,
        status: (statusType && statusType[0]) ?? "",
        empTypes: (searchType && searchType[0]?.key + 1) ?? "",
        page: page ?? "",
        fromdate: (startDate && getFormatedDate(startDate)) ?? "",
        todate: (endDate && getFormatedDate(endDate)) ?? "",
        pageSize: pageSize ?? "",
        isSendMail: true,
      })
    );
  };

  useEffect(() => {
    if (isEmailSuccess && isSendEmail) {
      toast("Reprimand has been sent to registered email ID");
    }
  }, [isEmailSuccess]);

  return (
    <>
      <section className="grid_main_sec">
        <ToastContainer />
        <div className="grid_main_header two_grid">
          <div className="grid_heading_style">View Reprimands</div>
          {checkPrivileges([12, 81, 82]) && (
            <div>
              {filterShow ? (
                <Buttons
                  primary={true}
                  label={"Add Reprimand"}
                  onClick={() => {
                    if (InactiveClient()) return;
                    navigate(REPRIMAND);
                  }}
                  startIcon={<AddIcon />}
                />
              ) : (
                ""
              )}
            </div>
          )}
        </div>
        <div className="grid_main_body">
          <div className="div-filter-container">
            <>
              {filterShow ? (
                <ReportFilter
                  filterType={[]}
                  handleSearchFilter={handleSearchFilter}
                  handleClose={handleClose}
                  anchorEl={anchorEl}
                  handleInputSearchByText={(e) => searhHandler(e)}
                  handleInputSearchByTextState={searchText}
                  searchByText={true}
                  searchByTextName={"Search Reprimands"}
                  typeSearchLabel={"Employment type"}
                  isTypeSearchLabel={false}
                  isStatus={true}
                  statusLabel={"Status"}
                  sattusListData={InvolvedPartyOptionData}
                  statusOfList={STATUS_OF_LIST_REPRIMAND}
                  handleClear={handleClear}
                  singleSelect={true}
                  singleCheckbox={false}
                />
              ) : (
                ""
              )}
            </>
            <div className="div-download-email-right" onClick={sendEmail}>
              <span>
                <img
                  src={downloadIcon}
                  className="DownLoadLogo"
                  alt="DownLoad Logo"
                />
              </span>
              Download Data
            </div>
          </div>
        </div>

        <div className="desc_box row_uniq">
          <div className="table_style_ui">
            {isTableLoading ? (
              ""
            ) : (
              <GridTable
                getRowId={(r) => r.reprimandDtlId}
                rows={reprimandList?.insReprimandDetails ?? []}
                columns={columns ?? []}
                rowCount={reprimandList?.recordsCount ?? 0}
                onClick={(rows) => onRowClick(rows)}
                loading={isLoading}
                checkboxSelection={false}
                page={page}
                pagination
                pageSize={pageSize}
                rowsPerPageOptions={[50]}
                onPageChange={(newPage) => setPage(newPage + 1)}
                backIconButtonProps={{ disabled: false }}
                onSelectionModelChange={(reportId) =>
                  setReportIdOfChekbox(reportId)
                }
                components={{
                  LoadingOverlay: TableLoader,
                  NoRowsOverlay: NORow,
                }}
                disableSelectionOnClick
              />
            )}
          </div>
        </div>
      </section>
      {reportIdOfChekbox.length > 0 && (
        <DeleteAndDownloadAction
          reportIdOfChekbox={reportIdOfChekbox}
          incidentLists={reprimandList?.insReprimandDetails ?? []}
          filename={"Reprimand-Reports"}
          deleteOnClick={() => deleteItem(null, "multiple")}
        />
      )}
    </>
  );
};

export default ReprimandList;
