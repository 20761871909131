import { Grid } from "@mui/material";
import React from "react";
import { getSignificantDeviation, getValueByKey } from "../../../../../utils/swInspectionHelper";

const ViewThreeQuestion = (props) => {
  return (
    <Grid container mt={3} className="notWithThreeQuestion">
      <Grid md={6} xs={12} item>
        <Grid className="notWithThreeAnswer">
          <strong>Actual Dimension(ft):</strong>
          {props?.inQuestion?.actualDimension}
        </Grid>
      </Grid>
      <Grid md={6} xs={12} item>
        <Grid className="notWithThreeAnswer">
          <strong>Proposed Dimension(ft):</strong>
          {props?.inQuestion?.proposedDimension}
        </Grid>
      </Grid>
      <Grid md={6} xs={12} item>
        <Grid className="notWithThreeAnswer">
          <strong>Significant Deviation from plan:</strong>
          {getSignificantDeviation(props?.inQuestion?.significantDeviation)}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ViewThreeQuestion;
