import React from "react";
import "../../../incidents/cases/addCaseList.scss";
import Buttons from "../../../../library/custom/button/Button";
import ContractorTable from "./contractorTable";
import { InactiveClient } from "../../../../utils/helper";

export default function ContractorList({
  NavigateSingleUser,
  contractorListData,
  getContractorListData,
  loading,
  deleteList,
  contractorGrades,
  contractorTypes,
  isShowDowdownloadButton,
}) {
  const handleClickAdd = (event) => {
    if (InactiveClient()) return;
    NavigateSingleUser();
  };
  return (
    <>
      <section className="grid_main_sec">
        <div className="grid_main_header two_grid">
          <div className="grid_heading_style">Sub-Contractor List</div>

          <div>
            <Buttons
              id="btnAddContractor"
              varientAddIconBlue={true}
              label={"Add Sub-Contractor"}
              onClick={(e) => handleClickAdd(e)}
            />
          </div>
        </div>
        <ContractorTable
          contractorListData={contractorListData}
          getContractorListData={getContractorListData}
          loading={loading}
          deleteList={deleteList}
          contractorGrades={contractorGrades}
          filterShow={true}
          contractorTypes={contractorTypes}
          isShowDowdownloadButton={isShowDowdownloadButton}
        />
      </section>
    </>
  );
}
