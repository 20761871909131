import React, { useEffect, useState } from "react";
import ReportList from "../components/reportList";
import { useNavigate } from "react-router-dom";
import {
  ACCESS_DENIED,
  SW_INSPECTION_ADD,
  SW_INSPECTION_PROOF_VIEW,
  SW_INSPECTION_VIEW,
} from "../../../../constant/routeContant";
import * as swInspectionAction from "../../../../redux/storm-water/actions/swInspectionAction";
import * as swCommonAction from "../../../../redux/storm-water/actions/swCommonAction";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  API_STATUS,
  HBNEXT_ORG_ID,
  LOGIN_ORG_ID,
  ORG_ID,
  PAGE_SIZE,
  USER_ID,
} from "../../../../constant/constant";
import * as clientAction from "../../../../redux/actions/clientAction";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import { useLocation } from "react-router-dom";
import { getFormatedDate } from "../../../../utils/helper";
import Loader from "../../../../library/common/Loader";

const ReportListContainer = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const stateData = location?.state;
  const [stodayDate, setStodayDate] = useState(null);
  const [scalculatedDate, setSCalculatedDate] = useState(null);
  const [projects, setProjects] = useState();
  const [clients, setClients] = useState();
  const [selectedReports, setSelectedReports] = useState([]);
  const isProfile = props?.isprofile ?? false;
  const { state } = useLocation();
  const isFromDashboard = state?.from === "dashboard";
  const handleUserId = () => {
    if (
      isFromDashboard &&
      checkPrivileges([17]) &&
      checkPrivileges([138]) &&
      !checkPrivileges([136]) &&
      !checkPrivileges([137])
    ) {
      return true;
    }
    return false;
  };
  const isUseridPass = handleUserId();
  const HBView =
    parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID) && checkPrivileges([12, 17]);
  const adminView = checkPrivileges([139]);
  const clientView = LOGIN_ORG_ID() !== ORG_ID();
  const [frequencyId, setFrequencyId] = useState("");
  const selectedTab = localStorage.getItem("swReportListTab")
    ? parseInt(localStorage.getItem("swReportListTab"))
    : 5;
  const [statusId, setStatusId] = useState(isProfile ? 5 : selectedTab ?? 5);
  const [checktab, setchecktab] = useState(isProfile ? 5 : selectedTab ?? 5);
  useEffect(() => {
    if (isFromDashboard) {
      localStorage.setItem("swReportListTab", `9`);
      setchecktab(9);
      setStatusId(9);
      setProjects("");
      setClients("");
      setFrequencyId("");
    }
  }, [isFromDashboard]);
  const statusHander = (status) => {
    setStatusId(status);
    localStorage.setItem("swReportListTab", `${status}`);
    setProjects();
    setClients();
    setFrequencyId("");
  };
  const refData = {
    flags: [3, 4],
  };
  const getSwReference = useSelector((state) => state.getSwReference);
  const getSwReferenceData = (data) => {
    dispatch(swCommonAction.getSwReference(data));
  };
  useEffect(() => {
    if (statusId === 9) {
      const days = parseInt(30);
      const today = new Date();
      const calculated = new Date(today);
      calculated.setDate(today.getDate() + (days - 1));
      setSCalculatedDate(calculated);
      setStodayDate(today);
      setchecktab(9);
    } else if (statusId === 5 || statusId === 8 || statusId === 10) {
      setSCalculatedDate(null);
      setStodayDate(null);
      setchecktab(statusId);
    }
  }, [statusId]);

  const defaultData = {
    search: "",
    inspectionTypeId: 0,
    projectId: 0,
    customerId: parseInt(ORG_ID()),
    statusId: statusId,
    startDate: statusId === 9 && stodayDate ? getFormatedDate(stodayDate) : "",
    endDate:
      statusId === 9 && scalculatedDate ? getFormatedDate(scalculatedDate) : "",
    page: 1,
    pageSize: PAGE_SIZE,
    userId: isProfile || isUseridPass ? parseInt(USER_ID()) : "",
  };
  const getSwInspectionList = useSelector((state) => state.getSwInspectionList);
  const getSwInspectionListData = (data) => {
    dispatch(swInspectionAction.getSwInspectionList(data));
  };
  const getBulkInspectionDetails = useSelector(
    (state) => state.getBulkInspectionDetails
  );
  const getBulkInspectionDetailsData = (data, client) => {
    dispatch(
      swInspectionAction.getBulkInspectionDetails({
        orgId: clients?.key ? clients?.key : ORG_ID(),
        userId: USER_ID(),
        inspectionIds: data,
      })
    );
  };
  const getBulkInspectionFiles = useSelector(
    (state) => state.getBulkInspectionFiles
  );
  const getBulkInspectionFilesData = (data) => {
    dispatch(swInspectionAction.getBulkInspectionFiles(data));
  };
  const swOpenItemDetail = useSelector((state) => state.swOpenItemDetail);
  const getOpenItemDetailData = (data) => {
    dispatch(swInspectionAction.createSwOpenItemDetail(data));
  };

  useEffect(() => {
    if (
      !getBulkInspectionDetails?.isLoading &&
      getBulkInspectionDetails?.status === "SUCCESS" &&
      selectedReports?.length > 0
    ) {
      statusHander(8);
      localStorage.setItem("swReportListTab", `8`);
      setSelectedReports([]);
    }
  }, [getBulkInspectionDetails]);

  const deleteReport = useSelector((state) => state.deleteSwInspection);

  const allClients = useSelector((state) => state.searchAllClientsReducerData);
  const isSearchClientLoading = allClients.isLoading;
  const searchClient = (event) => {
    dispatch(clientAction.searchAllClients(event.target.value));
  };

  const getProjectContactsEmailDetails = useSelector(
    (state) => state.getProjectContactsEmailDetails
  );

  const isLoading = useSelector(
    (state) =>
      state.getSwInspectionList?.isLoading ||
      state.deleteSwInspection?.isLoading ||
      state.getProjectContactsEmailDetails.isLoading
  );

  useEffect(() => {
    getSwReferenceData(refData);
  }, []);

  const handleEditOnClick = (row) => {
    navigate(SW_INSPECTION_ADD, {
      state: {
        row: row,
        editMode: true,
      },
    });
  };

  const viewOnClick = (row) => {
    if (row?.isExternal) {
      navigate(SW_INSPECTION_PROOF_VIEW, {
        state: {
          inspectionDetails: row,
        },
      });
    } else {
      navigate(SW_INSPECTION_VIEW, {
        state: {
          inspectionId: row?.reportId,
          customerId: row?.customerId,
        },
      });
    }
  };

  const handleAddOnClick = (row) => {
    navigate(SW_INSPECTION_ADD, {
      state: {
        row: row,
        addMode: true,
        addDirectInspection: false,
      },
    });
  };

  const onRowClick = (row) => {
    if (row?.value) {
      navigate(SW_INSPECTION_VIEW, {
        state: {
          inspectionId: row?.id,
          customerId: row?.customerId,
        },
      });
    }
  };

  const handleDeleteOnClick = (data) => {
    dispatch(swInspectionAction.deleteSwInspection(data));
  };

  const getProjectContactsEmailDetailsData = (data) => {
    dispatch(swInspectionAction.getProjectContactsEmailDetails(data));
  };

  useEffect(() => {
    if (deleteReport?.status === API_STATUS.SUCCESS) {
      getSwInspectionListData(defaultData);
    }
  }, [deleteReport]);

  useEffect(() => {
    if (
      !stateData &&
      checktab !== 8 &&
      !isFromDashboard &&
      checktab &&
      statusId
    ) {
      if (!isFromDashboard && stodayDate && scalculatedDate) {
        getSwInspectionListData(defaultData);
      } else if (checktab !== 9) {
        getSwInspectionListData({ ...defaultData, startDate: "", endDate: "" });
      }
    } else if (checktab === 8) {
      getBulkInspectionFilesData({
        page: 1,
        pageSize: 10,
        userId: parseInt(USER_ID()),
      });
    } else if (isFromDashboard) {
      getSwInspectionListData({
        ...defaultData,
        userId: isUseridPass ? parseInt(USER_ID()) : "",
      });
    }
  }, [checktab, stateData, stodayDate, scalculatedDate, isFromDashboard]);

  useEffect(() => {
    if (clients) {
      const defaultClientData = {
        search: "",
        inspectionTypeId: 0,
        projectId: projects?.key ? projects?.key : 0,
        customerId: clients?.key ? clients?.key : parseInt(ORG_ID()),
        statusId: statusId,
        startDate:
          statusId === 9 && stodayDate ? getFormatedDate(stodayDate) : "",
        endDate:
          statusId === 9 && scalculatedDate
            ? getFormatedDate(scalculatedDate)
            : "",
        page: 1,
        pageSize: PAGE_SIZE,
        userId: isProfile || isUseridPass ? parseInt(USER_ID()) : "",
      };
      getSwInspectionListData(defaultClientData);
    }
  }, [clients]);

  useEffect(() => {
    if (
      getProjectContactsEmailDetails?.status === "SUCCESS" &&
      getProjectContactsEmailDetails?.isLoading === false
    ) {
      toast("Mail Send successfully");
    }
  }, [getProjectContactsEmailDetails]);

  return (
    <>
      {checkPrivileges([12, 102, 103, 104]) ? (
        <>
          {getBulkInspectionDetails.isLoading && <Loader />}
          <ReportList
            isLoading={isLoading}
            swInspectionList={
              isLoading ? [] : getSwInspectionList?.data?.inspectionReports
            }
            count={getSwInspectionList?.data?.recordsCount}
            getSwInspectionListData={getSwInspectionListData}
            handleEditOnClick={handleEditOnClick}
            viewOnClick={viewOnClick}
            inspectionTypeValue={getSwReference?.data}
            allClients={allClients?.data}
            isSearchClientLoading={isSearchClientLoading}
            searchClient={searchClient}
            handleDeleteOnClick={handleDeleteOnClick}
            onRowClick={onRowClick}
            statusHander={statusHander}
            statusId={statusId}
            stateData={stateData}
            scalculatedDate={scalculatedDate}
            stodayDate={stodayDate}
            setProjects={setProjects}
            projects={projects}
            setClients={setClients}
            clients={clients}
            selectedReports={selectedReports}
            setSelectedReports={setSelectedReports}
            getBulkInspectionDetailsData={getBulkInspectionDetailsData}
            getBulkInspectionFiles={getBulkInspectionFiles}
            getBulkInspectionFilesData={getBulkInspectionFilesData}
            getProjectContactsEmailDetailsData={
              getProjectContactsEmailDetailsData
            }
            handleAddOnClick={handleAddOnClick}
            frequencyId={frequencyId}
            setFrequencyId={setFrequencyId}
            isProfile={isProfile}
            getOpenItemDetailData={getOpenItemDetailData}
            swOpenItemDetail={swOpenItemDetail}
            isFromDashboard={isFromDashboard}
            isUseridPass={isUseridPass}
          />
          <ToastContainer />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}{" "}
    </>
  );
};

export default ReportListContainer;
