import React, { useEffect, useState } from "react";
import AssignRoleForm from "../component/assignrole/assignRoleForm";
import {
  ACCESS_DENIED,
  ADMIN_ADD_NEW_ROLE,
  DASHBOARD,
} from "../../../constant/routeContant";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as assignAction from "../../../redux/actions/assignAction ";
import * as userAction from "../../../redux/actions/userAction";
import * as roleAction from "../../../redux/actions/roleAction ";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../library/common/Loader";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { InactiveClient } from "../../../utils/helper";

export default function AssignRoleFormContainer() {
  const [isSuccess, setIsSuccess] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const creatAssignRoleData = useSelector((state) => state.creatAssignRoleData);
  const allUsers = useSelector((state) => state.allUsers);
  const autoSearchDataLoading = allUsers.isLoading;
  const isLoading = creatAssignRoleData.isLoading;
  const isAllowed = checkPrivileges([12, 14, 76, 77]);

  const searchUsers = (searchText) => {
    dispatch(userAction.searchAllUsers(searchText, 0));
  };

  const getUserInRoleListDatas = useSelector(
    (state) => state.getUserInRoleListData
  );

  const getUserInRoleLists = (userId) => {
    dispatch(roleAction.getUserInRoleList(userId));
  };

  const searchOption = useSelector((state) => state.users.data);

  const addRole = () => {
    if (InactiveClient()) return;
    navigate(ADMIN_ADD_NEW_ROLE);
  };

  const deleteRoleReducerData = useSelector(
    (state) => state.deleteRoleReducerData
  );

  useEffect(() => {
    setIsSuccess(false);
  }, []);

  const deleteRoles = (roleId) => {
    if (InactiveClient()) return;
    dispatch(roleAction.deleteRole(roleId));
  };

  const assignListData = useSelector((state) => state.getAssignListData);

  const getAssignLists = (data) => {
    dispatch(assignAction.getAssignList(data));
  };

  useEffect(() => {
    if (deleteRoleReducerData?.status == "SUCCESS") {
      getAssignLists();
    }
  }, [deleteRoleReducerData]);

  const defaultData = {
    orgId: "",
    search: "",
  };

  useEffect(() => {
    if (!isAllowed) {
      navigate(DASHBOARD);
    }
    getAssignLists(defaultData);
  }, []);

  //Save

  const assignRoles = (data) => {
    if (InactiveClient()) return;
    dispatch(roleAction.createAssignRole(data));
  };
  useEffect(() => {
    if (
      creatAssignRoleData?.status === "SUCCESS" &&
      creatAssignRoleData?.isLoading === false
    ) {
      toast("Role Assigned Successfully");
      setTimeout(() => {
        window.location.reload();
      }, 3800);
    }
  }, [creatAssignRoleData]);

  return (
    <>
      {checkPrivileges([12, 77, 76, 14]) ? (
        <>
          {isLoading && <Loader />}
          {creatAssignRoleData?.status === "SUCCESS" &&
            creatAssignRoleData?.data &&
            isSuccess ? (
            toast("Role Assigned Successfully")
          ) : (
            <AssignRoleForm
              onSearch={searchUsers}
              allUsers={allUsers?.data?.users}
              searchOption={searchOption?.users || []}
              isLoading={autoSearchDataLoading}
              addRole={addRole}
              loading={assignListData.isLoading}
              assignListData={assignListData?.data}
              assignRoles={assignRoles}
              deleteRole={deleteRoles}
              getUserInRoleListss={getUserInRoleLists}
              getUserInRoleListDatas={getUserInRoleListDatas}
            />
          )}
          <ToastContainer />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
}
