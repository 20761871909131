import PersonalinjuryImg from "./personalinjury.svg";
import UtilitycaseImg from "./utilitydamage.svg";
import VehicleImg from "./vehicleaccident.svg";
import StolenImg from "./stolenproperty.svg";
import nearMisses from "./nearmiss.svg";
import basicInspectionImg from "./inspection/basicInspection.svg";
import comprehensiveInspectionImg from "./inspection/comprehensiveInspection.svg";
import generalInspection from "./inspection/generalInspection.svg";
import mockOshaInspection from "./inspection/mockOshaInspection.svg";
import acUnit from "./accident/acunit.svg";
import air from "./accident/air.svg";
import bounding from "./accident/boundingBox.svg";
import lensBlur from "./accident/lensblur.svg";
import lightMode from "./accident/lightmode.svg";
import chair from "./accident/chair.svg";
import humidityHigh from "./accident/humidityHigh.svg";
import rainy from "./accident/rainy.svg";
import logo from "./logo.svg";
import logoWhite from "./logo-white.svg";
import carouselCheck from "./sliderCheck.svg";
import successCheck from "./check_circle.svg";
import visibility from "./visibility.svg";
import visibilityoff from "./visibility_off.svg";
import arrowbackleft from "./arrow_back_left.svg";
import arrowbackright from "./arrow_back_right.svg";
import modalclose from "./modal_close.svg";
import cardimg from "./card-img.svg";
import locallibrary from "./card/local_library.svg";
import category from "./card/category.svg";
import alarm from "./card/alarm.svg";
import checkcircle from "./card/check_circle.svg";
import commonCover from "./commonCover.svg";
import attachment from "./attachment.svg";
import backBtn from "./backBtn.svg";
import backBtnDark from "./backBtnDark.svg";
import noimage from "./no-image.jpg";
import passed from "./passed.svg";
import failed from "./failed.svg";
import homeIcon from "./sidenav/home.svg";
import personnelcontractor from "./sidenav/personnelcontractor.svg";
import contractormanagement from "./sidenav/contractor-management.svg";
import resourcelibrary from "./sidenav/resource-library.svg";
import adminIcon from "./sidenav/adminIcon.svg";
import inspections from "./sidenav/inspections.svg";
import incidentmanagement from "./sidenav/incidentmanagement.svg";
import learningmanagement from "./sidenav/learningmanagement.svg";
import observations from "./sidenav/observations.svg";
import supportresources from "./sidenav/supportresources.svg";
import analytics from "./sidenav/analytics.svg";
import assignmentturnedin from "./assignment_turned_in.svg";
import group from "./group.svg";
import editicon from "./edit-icon.svg";
import editiconDark from "./editIconDark.svg";
import hormorevert from "./hor-more_vert.svg";
import assignment from "./assignment.svg";
import NoRow from "./noRow.svg";
import profile from "./profile.svg";
import edit from "./edit.svg";
import whiteEditIcon from "./white-edit-icon.svg";
import subcategories from "./subcategories.svg";
import inspectionconfigation from "./inspection-configation.svg";
import questions from "./questions.svg";
import categories from "./categories.svg";
import close from "./close.svg";
import close_dark from "./close_dark.svg";
import custominspection from "./custom-inspection.svg";
import download from "./download.svg";
import wet from "./wet.svg";
import project from "./admin/assetManagement/project.svg";
import vehicles from "./admin/assetManagement/vehicles.svg";
import equipments from "./admin/assetManagement/equipments.svg";
import customAssets from "./admin/assetManagement/custom-assets.svg";
import contacts from "./admin/companyManagement/contacts.svg";
import divisions from "./admin/companyManagement/divisions.svg";
import resources from "./admin/companyManagement/resources.svg";
import assigncredential from "./admin/credentialManagement/assign-credential.svg";
import credentiallookup from "./admin/credentialManagement/credential-lookup.svg";
import mycredentials from "./admin/credentialManagement/my-credentials.svg";
import setupcredentials from "./admin/credentialManagement/setup-credentials.svg";
import assignroles from "./admin/userManagement/assign-roles.svg";
import groups from "./admin/userManagement/groups.svg";
import users from "./admin/userManagement/users.svg";
import projectsIcon from "./admin/setupSiteProjectsManagement/projectsIcon.svg";
import siteIcon from "./admin/setupSiteProjectsManagement/siteIcon.svg";
import usersconfiguration from "./admin/userManagement/user-configuration.svg";
import usersBouncedEmail from "./admin/userManagement/user-bounced-email.svg";
import arrowdropdowncircle from "./arrow_drop_down_circle.svg";
import incidentcat from "./utility/incidentcat.svg";
import lmscategory from "./utility/lms.svg";
import admincategory from "./utility/admin.svg";
import assetscategory from "./utility/assets.svg";
import credentialscategory from "./utility/credentials.svg";
import deleteIcon from "./delete.svg";
import check from "./check.svg";
import noCheck from "./noCheck.svg";
import deleteblueIcon from "./deleteblue.svg";
import deleteblueIconDark from "./deleteblue_dark.svg";
import downloadcategoryIcon from "./file_download.svg";
import publishIcon from "./publish.svg";
import unpublishIcon from "./unpublish.svg";
import noimagePublish from "./noPublishImg.svg";
import editBlue from "./editblue.svg";
import multipleMedia from "./multiple_media.svg";
import multipleMediaDark from "./multiple_media_dark.svg";

import VectorDropdown from "./vectordropdown.svg";
import credentials from "./reports/credentials.svg";
import reportsdata from "./reports/reportsdata.svg";
import safetydata from "./reports/safetydata.svg";
import mapview from "./reports/mapview.svg";
import utilities from "./reports/utilities.svg";
import trainingsessions from "./reports/trainingsessions.svg";
import reportSummary from "./reports/reportSummary.svg";
import supportcategory from "./utility/rafiki.svg";
import image from "./utility/image.svg";
import contentcopy from "./content_copy.svg";
import check_circleAttendee from "./check_circleAttendee.svg";
import ContractorConfiguration from "./contractor/ContractorConfiguration.svg";
import ContractorList from "./contractor/ContractorList.svg";
import ActionCount from "./actionCount.svg";
import KudosCount from "./kudosCount.svg";
import ViolationCount from "./violationCount.svg";
import ReprimandCount from "./reprimandCount.svg";
import AttendanceVerifiedBtn from "./attendanceVerifiedBtn.svg";
import closeIncident from "./closeIncident.svg";
import assignAteendee from "./assigne.svg";
import selfassign from "./selfassgin.svg";
import schedulemeeting from "./event_available.svg";
import DefaultProfilePic from "./DefaultProfilePic.svg";
import activitiesJha from "./admin/jha/activitiesJha.svg";
import categoryJha from "./admin/jha/categoryJha.svg";
import hazardJha from "./admin/jha/hazardJha.svg";
import comment from "./comment.svg";
import checkcircleclosed from "./check_circle_closed.svg";
import locationImg from "./locationImg.svg";
import ManageIcon from "./ManageIcon.svg";
import deleteIconRed from "./deleteIconRed.svg";
import checksampleResport from "./checksample.svg";
import templateJha from "./admin/jha/templateJha.svg";
import setControlIcon from "./setControlIcon.svg";
import setControlIconDark from "./setControlIconDark.svg";
import openError from "./openError.svg";
import safetylandingpage from "./safetyDash.svg";
import stormlandingpage from "./stormDash.svg";
import cancelIcon from "./cancelIcon.svg";
import docIcon from "./doc-icon.svg";
import pdfIcon from "./pdf-icon.svg";
import arrowuptriggle from "./arrow_up_triggle.svg";
import arrowdowntriggle from "./arrow_down_triggle.svg";
import Celebration from "./celebration.svg";
import RedInfo from "./red-info.svg";
import norainfallimg from "./rafiki.svg";

import inspectionacknowledgment from "./inspectionacknowledgment.svg";
import conductExternal from "./inspection/conductExternal.svg";
import addProof from "./inspection/addProof.svg";
import hbNextLogo from "./hbnextlogo.svg";
import warning from "./accident/warning.svg";
import missedIcon from "./missed_icon.svg";
import deferIcon from "./defer_icon.svg";
import userSearch from "./admin/userManagement/userSearch.svg";
import userSearchByRole from "./admin/userManagement/userSearchByRole.svg";
import lmsmappingcategory from "./utility/lmsmapping.svg";
import InputImg from "./formbuilder/draw.svg";
import TextAreaImg from "./formbuilder/chair.svg";
import SingleSelectImg from "./formbuilder/arrow_selector_tool.svg";
import MuiltiSelectImg from "./formbuilder/checklist.svg";
import RadioButtonImg from "./formbuilder/radio_button_checked.svg";
import CheckboxImg from "./formbuilder/check_box.svg";
import CloseSection from "./formbuilder/close.svg";
import Duplicate from "./formbuilder/duplicate.svg";
import reportsanalyticsdashboard from "./reports/reportsanalyticsdashboard.svg";
import downloadWhite from "./downloadIcon.svg";
import excelDownload from "./excel-download.svg";
import pdfFill from "./pdf-fill.svg";
import rainfall from "./rainfall.svg";
import supportConnectIcon from "./call_quality_light.svg";
import supportConnectIconDark from "./call_quality_dark.svg";
import supportFaqIcon from "./quiz_light.svg";
import supportFaqIconDark from "./quiz_dark.svg";
import supportTicketIcon from "./find_in_page_light.svg";
import supportTicketIconDark from "./find_in_page_dark.svg";
import supportConnectCallIcon from "./call_light.svg";
import supportConnectCallIconDark from "./call_dark.svg";
import supportConnectAddressIcon from "./home_work_light.svg";
import supportConnectAddressIconDark from "./home_work_dark.svg";
import supportConnectEmailIcon from "./mail_light.svg";
import supportConnectEmailIconDark from "./mail_dark.svg";
import oshaPdfIcon from "./oshaPdfIcon.svg";
import help from "./help.svg";
import qaCoderafiki from "./qaCoderafiki.svg";
import qaCode_rafiki2 from "./qacode-amico2.svg";
import qrcode_rafiki3 from "./qrcode_rafiki3.svg";
import qrcode_rafiki4 from "./qrcode_rafiki4.svg";
import SequenceLogo from "./SequenceLogo.svg";
import unchecbox from "./check_box.svg";
import checbox from "./uncheck_box.svg";
import damp from "./accident/damp.svg";
import cloud from "./weather/cloud.svg";
import partlycloud from "./weather/partly-cloud.svg";
import acunit from "./weather/ac_unit.svg";
import lightmode from "./weather/light_mode.svg";
import rainyy from "./weather/rainy.svg";

export const dynamicTable = {
  unchecbox,
  checbox,
};

export const swInspection = {
  comment,
  checkcircleclosed,
  locationImg,
};
// import templateJha from "./admin/jha/templateJha.svg";
// import setControlIcon from "./setControlIcon.svg";

export const FormBuilderImg = {
  InputImg,
  TextAreaImg,
  SingleSelectImg,
  MuiltiSelectImg,
  RadioButtonImg,
  CheckboxImg,
  CloseSection,
  Duplicate,
};

const commonImages = {
  ActionCount,
  KudosCount,
  ViolationCount,
  ReprimandCount,
  contentcopy,
  deleteIcon,
  PersonalinjuryImg,
  UtilitycaseImg,
  VehicleImg,
  StolenImg,
  nearMisses,
  basicInspectionImg,
  comprehensiveInspectionImg,
  generalInspection,
  mockOshaInspection,
  carouselCheck,
  successCheck,
  visibility,
  visibilityoff,
  cardimg,
  locallibrary,
  category,
  alarm,
  checkcircle,
  commonCover,
  attachment,
  backBtn,
  backBtnDark,
  noimage,
  assignmentturnedin,
  group,
  editicon,
  editiconDark,
  hormorevert,
  assignment,
  NoRow,
  profile,
  edit,
  whiteEditIcon,
  download,
  arrowdropdowncircle,
  check,
  noCheck,
  deleteblueIcon,
  deleteblueIconDark,
  downloadcategoryIcon,
  publishIcon,
  unpublishIcon,
  noimagePublish,
  editBlue,
  multipleMedia,
  multipleMediaDark,
  VectorDropdown,
  AttendanceVerifiedBtn,
  check_circleAttendee,
  closeIncident,
  assignAteendee,
  selfassign,
  schedulemeeting,
  DefaultProfilePic,
  ManageIcon,
  deleteIconRed,
  checksampleResport,
  setControlIcon,
  setControlIconDark,
  openError,
  safetylandingpage,
  stormlandingpage,
  // setControlIcon,
  cancelIcon,
  pdfIcon,
  docIcon,
  arrowuptriggle,
  arrowdowntriggle,
  Celebration,
  RedInfo,
  norainfallimg,
  hbNextLogo,
  missedIcon,
  deferIcon,
  downloadWhite,
  excelDownload,
  pdfFill,
  supportConnectIcon,
  supportConnectIconDark,
  supportFaqIcon,
  supportFaqIconDark,
  supportTicketIcon,
  supportTicketIconDark,
  supportConnectCallIcon,
  supportConnectCallIconDark,
  supportConnectAddressIcon,
  supportConnectAddressIconDark,
  supportConnectEmailIcon,
  supportConnectEmailIconDark,
  oshaPdfIcon,
  help,
  qaCoderafiki,
  qaCode_rafiki2,
  qrcode_rafiki3,
  qrcode_rafiki4,
};

export const modalImg = {
  arrowbackleft,
  arrowbackright,
  modalclose,
};

//accident vehicle image
export const accident = {
  acUnit,
  air,
  bounding,
  lensBlur,
  lightMode,
  wet,
  warning,
  damp,
};
export const weather = {
  cloud,
  partlycloud,
  acunit,
  lightmode,
  rainyy,
};
// inspection card images
export const inspection = {
  acUnit,
  air,
  bounding,
  lensBlur,
  lightMode,
  chair,
  humidityHigh,
  rainy,
  custominspection,
  addProof,
  conductExternal,
};

export const quizImg = {
  passed,
  failed,
};
export const sideNavImg = {
  homeIcon,
  adminIcon,
  inspections,
  incidentmanagement,
  learningmanagement,
  observations,
  supportresources,
  analytics,
  contractormanagement,
  resourcelibrary,
  personnelcontractor,
};
export const logos = {
  logo,
  logoWhite,
};
export const SequenceLogos = {
  SequenceLogo,
};

export const InspectionCardImage = {
  subcategories,
  categories,
  questions,
  inspectionconfigation,
  close,
  close_dark,
  inspectionacknowledgment,
};

export const AssetManagementImage = {
  project,
  vehicles,
  equipments,
  customAssets,
};

export const CompanyManagementImage = {
  contacts,
  divisions,
  resources,
};

export const CredentialManagementImage = {
  assigncredential,
  credentiallookup,
  mycredentials,
  setupcredentials,
};
export const UserManagementImage = {
  assignroles,
  groups,
  users,
  usersconfiguration,
  userSearch,
  usersBouncedEmail,
};
export const UserSearchManagementImage = {
  users,
  userSearchByRole,
};

export const SetupSiteProjectsManagement = {
  projectsIcon,
  siteIcon,
};

export const UtilityManagement = {
  incidentcat,
  lmscategory,
  admincategory,
  assetscategory,
  credentialscategory,
  supportcategory,
  image,
  lmsmappingcategory,
};

export const ReportsManagementImage = {
  credentials,
  reportsdata,
  safetydata,
  utilities,
  trainingsessions,
  reportSummary,
  mapview,
  reportsanalyticsdashboard,
  rainfall,
};

export const ContractorManagementImage = {
  ContractorConfiguration,
  ContractorList,
};

export const JhaConfigurationImage = {
  activitiesJha,
  hazardJha,
  categoryJha,
  templateJha,
};

export default commonImages;
