import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import SearchAutoComplete from "../../../../library/custom/searchAutoComplete/SearchAutoComplete";
import Buttons from "../../../../library/custom/button/Button";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import ReportRowManipulte from "../../../incidents/incidentList/ReportRowManipulte";
import { useNavigate } from "react-router-dom";
import { ADMIN_EDIT_NEW_ROLE } from "../../../../constant/routeContant";
import DialogBox from "../../../../library/common/DialogBox";
import NormalGrid from "../../../../library/custom/gridTable/NormalGrid";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import { PRODUCT_ID } from "../../../../constant/storm-water/constant";
import { TableLoader } from "../../../../library/common/Loader";
import NORow from "../../../../library/custom/noRow/noRow";
import { LOGIN_ORG_ID, ORG_ID } from "../../../../constant/constant";
import { InactiveClient } from "../../../../utils/helper";
export default function AssignRoleForm({
  addRole,
  assignListData,
  allUsers,
  loading,
  assignRoles,
  deleteRole,
  isLoading,
  onSearch,
  getUserInRoleListss,
  getUserInRoleListDatas,
}) {
  const [users, setUsers] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedrow, setselectedRow] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const autoCompGroup = useRef(null);
  useEffect(() => {
    const roleIds = getUserInRoleListDatas?.data?.userInRoles?.map(
      (roleId) => roleId.roleId
    );
    setSelectionModel(roleIds);
  }, [getUserInRoleListDatas]);

  const deletehandle = () => {
    setOpenModal(false);
    deleteRole(selectedrow.roleId);
  };

  const deleteRoles = (rows) => {
    if (InactiveClient()) return;
    setOpenModal(true);
    setselectedRow(rows);
  };

  const navigate = useNavigate();

  const handleOnclick = (rows) => {
    if (InactiveClient()) return;
    navigate(ADMIN_EDIT_NEW_ROLE, {
      state: { roleId: rows.row.roleId, editMode: true },
    });
  };

  const onResetData = () => {
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const onSubmitData = () => {
    const sendData = {
      userId: parseInt(users),
      modifiedBy: 0,
      modifiedByApp: 0,
      roleIds: selectionModel,
      serviceType: parseInt(PRODUCT_ID()),
    };
    assignRoles(sendData);
  };

  const credentialsColumns = [
    { field: "roleId", headerName: "Id", flex: 1 },
    { field: "name", headerName: "Role Name", flex: 3 },
    { field: "title", headerName: "Title", flex: 2.4 },
    { field: "description", headerName: "Description", flex: 2.5 },
    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={assignListData?.itemsCount ?? 0}
          numberOfItemsPerPage={5}
          incidentLists={assignListData?.roleData ?? []}
          fileNameXL={"List-of-Roles"}
        />
      ),
      flex: 0.8,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          checkPrivileges([12, 14, 76]) && (
            <ReportRowManipulte
              rows={rows}
              editOnClick={() => handleOnclick(rows)}
              deleteOnClick={() => deleteRoles(rows?.row)}
              edit={checkPrivileges([12, 14, 76])}
              editLabel="Edit Role"
              download={false}
              view={false}
              deleted={false}
              deleteLabel="Delete Role"
            />
          )
        );
      },
    },
  ];
  const [userss, setUserss] = useState();

  const onChangeUsers = (value) => {
    setUsers(value?.key || 0);
    setUserss({ key: value?.key || 0, value: value?.value || "" });
    getUserInRoleListss(value?.key);
  };

  const typeOnchange = (event) => {
    onSearch(event.target.value);
  };
  return (
    <div className="overidePersonal">
      <Grid item lg={6} xs={12} sm={12} md={4} textAlign="left">
        <div className="heading_style">Assign Role</div>
        {openModal && (
          <DialogBox
            open={openModal}
            rows={[]}
            header={"Confirm Deletion"}
            deletehandle={deletehandle}
            handleCloseModal={() => setOpenModal(false)}
            content={"Are you sure you want to delete this Assign Role?"}
            view
          />
        )}
      </Grid>

      <Grid container item md={12} xs={12} className="addShadowBox">
        <Grid item container spacing={2}>
          <Grid item container spacing={2}>
            <Grid item lg={5} xs={12} sm={12} md={6}>
              <FormControl fullWidth>
                <FormLabels label={"Select User"} isRequired={false} />
                <SearchAutoComplete
                  name="selectUser"
                  placeholderText="Enter User Name"
                  optionsData={isLoading ? [] : allUsers ?? []}
                  typeOnchange={(event, value) => typeOnchange(event)}
                  isKeyValuePair={true}
                  value={userss}
                  onChange={(event, value) => onChangeUsers(value)}
                  isLoading={isLoading}
                  autoCompClear={autoCompGroup}
                />
              </FormControl>
            </Grid>
            {checkPrivileges([12]) && (
              <Grid item lg={3} xs={12} sm={12} md={4} mt={3.6}>
                <Buttons
                  id="btnAddRole"
                  label={"Add New Role"}
                  varientAddIconBlue={true}
                  onClick={() => addRole()}
                />
              </Grid>
            )}
          </Grid>
          <Grid item container mt={5} spacing={2} mr={1} ml={1}>
            <FormControl fullWidth>
              <FormLabels label={"Select Role"} isRequired={false} />
              <Grid lg={12}>
                <div className="desc_box row_uniq">
                  <div className="table_style_ui">
                    <NormalGrid
                      loading={loading}
                      components={{
                        LoadingOverlay: TableLoader,
                        NoRowsOverlay: NORow,
                      }}
                      getRowId={(r) => r.roleId}
                      rowss={
                        assignListData?.roleData
                          ? assignListData.roleData.filter((x, index) => {
                            if (
                              x.isGlobal === true ||
                              (checkPrivileges([12]) &&
                                LOGIN_ORG_ID() === ORG_ID())
                            ) {
                              return x;
                            }
                          })
                          : []
                      }
                      columnss={credentialsColumns}
                      rowCount={assignListData?.itemsCount}
                      selectionModel={selectionModel}
                      onSelectionModelChange={(e) => {
                        setSelectionModel(e);
                        const selectedIDs = new Set(e);
                        const selectedRows = assignListData?.roleData.filter(
                          (r) => selectedIDs.has(r.id)
                        );
                        setSelectedRows(selectedRows);
                      }}
                    ></NormalGrid>
                  </div>
                </div>
              </Grid>
            </FormControl>
          </Grid>
          <Grid container mb={4} mt={3} spacing={3}>
            <Grid item lg={12} xs={12} sm={12} md={4}>
              <div className="save_btn">
                <div className="cancle_heading_style"></div>
                <Buttons
                  aria-describedby="dd"
                  variant="contained"
                  type="button"
                  name="btn"
                  label={"Reset"}
                  onClick={() => onResetData()}
                  varientContained={true}
                ></Buttons>
                <Grid item lg={0.5}></Grid>
                <Buttons
                  aria-describedby="dd"
                  variant="contained"
                  type="button"
                  name="btn"
                  label={"Assign"}
                  onClick={() => onSubmitData()}
                  varientContained={true}
                ></Buttons>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
