import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import { useState } from "react";
import SearchAutoComplete from "../../../../library/custom/searchAutoComplete/SearchAutoComplete";
import {
  PAGE_SIZE,
  USER_TYPE,
  USER_ID,
  API_STATUS,
  ORG_ID,
  HBNEXT_ORG_ID,
} from "../../../../constant/constant";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import { TableLoader } from "../../../../library/common/Loader";
import NORow from "../../../../library/custom/noRow/noRow";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import "./primaryInspectorList.scss";
import Buttons from "../../../../library/custom/button/Button";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import DatePickers from "../../../../library/custom/datePicker/DatePicker";
import "./primaryInspectorList.scss";
import UserFilterList from "../../../admin/shared/UserFilterList ";
import { WEEKDAYS } from "../../../../constant/storm-water/constant";
import { useRef } from "react";
import { dateValiadtor, getFormatedDate } from "../../../../utils/helper";
import commonImages from "../../../../../src/assets";
import DialogBox from "../../../../library/common/DialogBox";
import { useNavigate } from "react-router-dom";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import { checkPrivileges } from "../../../../utils/rolesHelper";

function SecondryInspectorList({
  allUsers,
  onSearch,
  dataBack,
  SwInspectorList,
  InspectorListData,
  isLoading,
  getSwReference,
  assignSwInspectorDatas,
  SwSecondaryInspectorList,
  getSwSecondaryInspectorListData,
  deleteSecondaryInspectorList,
  setSecondaryInspector,
  setupData,
  assignsSwInspectorData,
  assignSwInspectorDataStatus,
  deleteSwSecondaryInspectionDataStatus,
  deleteSwSecondaryInspectionDatas,
  setIsSuccess,
}) {
  const history = useNavigate();
  const [users, setUsers] = useState("");
  const [primaryInspector, setPrimaryInspector] = useState("");
  const [primaryInspectorError, setPrimaryInspectorError] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [assignBox, setAssignBox] = useState(false);
  const [manageSecondryBox, setManageSecondryBox] = useState(false);
  const [inspectionDay, setInspectionDay] = useState("");
  const [frequency, setFrequency] = useState("");
  const [formDate, setFormDate] = useState(null);
  const [toDate, setToDate] = useState(formDate);
  const [setupIds, setSetupIds] = useState("");
  const [ids, setIds] = useState("");
  const [formDateError, setformDateError] = useState(false);
  const [toDateError, setToDateError] = useState(false);
  const [dateErrMsg, setDateErrMsg] = useState("");
  const [inspector, setInspector] = useState("");
  const [errorMsgPrimaryInspectors, setErrorMsgPrimaryInspectors] =
    useState(false);
  const [errorMsgPrimaryInspectorsMSG, setErrorMsgPrimaryInspectorsMSG] =
    useState();
  const [primaryInspectorValue, setPrimaryInspectorValue] = useState("");
  const onChangePrimaryInspector = (value) => {
    setPrimaryInspector(value?.key);
    setPrimaryInspectorValue({
      key: value?.key ?? "",
      value: value?.value ?? "",
    });
    setErrorMsgPrimaryInspectors(false);
  };

  useEffect(() => {
    if (
      assignSwInspectorDataStatus === API_STATUS.SUCCESS &&
      assignsSwInspectorData?.isLoading === false &&
      assignsSwInspectorData?.data?.result === ""
    ) {
      setIsSuccess(false);
      setPrimaryInspectorValue("");
      setFormDate(null);
      setToDate(null);
    }
  }, [assignsSwInspectorData]);

  useEffect(() => {
    if (
      deleteSwSecondaryInspectionDataStatus === API_STATUS.SUCCESS &&
      deleteSwSecondaryInspectionDatas?.isLoading === false
    ) {
      setIsSuccess(false);
      setIds("");
      const data = {
        setupId: setupIds,
      };
      SwSecondaryInspectorList(data);
    }
  }, [deleteSwSecondaryInspectionDatas]);

  const inspectionDayOnChanges = (e) => {
    setInspectionDay(e.target.value);
  };

  const frequencyTypeOnchange = (e) => {
    setFrequency(e.target.value);
  };

  const onchangeFormdatePicker = (e) => {
    setFormDate(e);
    // setDateErrMsg(false);
  };
  const onchangeTodatePicker = (e) => {
    setToDate(e);
    // setDateErrMsg(false);
  };

  const addAssignBox = () => {
    setAssignBox(!assignBox);
    setManageSecondryBox(false);
  };

  const AddManageSecondryBox = () => {
    setManageSecondryBox(!manageSecondryBox);
    setAssignBox(false);
    const data = {
      setupId: setupIds,
    };

    SwSecondaryInspectorList(data);
  };
  const autoCompSupervisor = useRef(null);
  const [usersValue, setUsersValue] = useState({ key: "", value: "" });

  const onChangeUsers = (value) => {
    setUsers(value?.key || "");
    setUsersValue({ key: value?.key ?? "", value: value?.value ?? "" });

    const data = {
      page: 1,
      pageSize: PAGE_SIZE,
      search: "",
      UserId: value?.key || "",
      InspectionDayId: "",
      FrequencyId: "",
    };
    SwInspectorList(data);
  };

  const credcloumns = [
    { field: "projectNo", headerName: "Project Number", flex: 2.5 },
    { field: "siteName", headerName: "Site Name", flex: 2.5 },
    { field: "projectName", headerName: "Project ", flex: 2 },
    {
      field: "orgName",
      headerName: "Client ",
      flex: 2,
      hide: parseInt(ORG_ID()) !== HBNEXT_ORG_ID,
    },
    {
      field: "inspectionDay",
      headerName: "Inspection Day",
      flex: 3,
      renderCell: (rows) => {
        return rows?.row?.inspectionDay === 0
          ? "Sunday"
          : rows?.row?.inspectionDay === 1
          ? "Monday"
          : rows?.row?.inspectionDay === 2
          ? "Tuesday"
          : rows?.row?.inspectionDay === 3
          ? "Wednesday"
          : rows?.row?.inspectionDay === 4
          ? "Thursday"
          : rows?.row?.inspectionDay === 5
          ? "Friday"
          : rows?.row?.inspectionDay === 6
          ? "Saturday"
          : "";
      },
    },
    { field: "frequency", headerName: "Frequency", flex: 2.5 },
    {
      field: "primaryInspectorName",
      headerName: "Primary Inspector",
      flex: 3.5,
    },
    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={InspectorListData?.data?.count ?? 0}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={InspectorListData?.data?.inspectionSetupList ?? []}
          fileNameXL={"InspectionAuthorityList"}
        />
      ),
      flex: 2,
      sortable: false,
      disableClickEventBubbling: true,
    },
  ];

  const inspectorcloumns = [
    { field: "inspectorName", headerName: "Inspector Name", flex: 4 },
    { field: "startDate", headerName: "Start Date", flex: 3.5 },
    { field: "endDate", headerName: "End Date", flex: 3.5 },
  ];

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    const data = {
      page: newPage + 1,
      pageSize: PAGE_SIZE,
      search: "",
      UserId: users ? users : "",
      InspectionDayId:
        inspectionDay === 7 ? 0 : inspectionDay === "" ? "" : inspectionDay,
      FrequencyId: frequency ? frequency : "",
    };

    SwInspectorList(data);
  };

  const handleautoSearch = (event) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      search: searchText ? searchText : "",
      page: page,
      pageSize: PAGE_SIZE,
      UserId: users ? users : "",
      InspectionDayId:
        inspectionDay === 7 ? 0 : inspectionDay === "" ? "" : inspectionDay,
      FrequencyId: frequency ? frequency : "",
    };

    SwInspectorList(data);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    window.close();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleApply = (state) => {
    setAnchorEl(null);
    const data = {
      page: 1,
      pageSize: PAGE_SIZE,
      search: "",
      UserId: users ? users : "",
      InspectionDayId:
        inspectionDay === 7 ? 0 : inspectionDay === "" ? "" : inspectionDay,
      FrequencyId: frequency ? frequency : "",
    };
    SwInspectorList(data);
  };

  const handleSearch = (data) => {
    setAnchorEl(null);
    SwInspectorList(data);
  };

  const handleClear = () => {
    setSearch("");
    setInspectionDay("");
    setFrequency("");
    const data = {
      page: 1,
      pageSize: PAGE_SIZE,
      search: "",
      UserId: "",
      InspectionDayId: "",
      FrequencyId: "",
    };
    handleSearch(data);
  };

  const [accessUserId, setAccessUserId] = useState([]);
  const [UserId, setUserId] = useState([]);
  const [results, setResults] = useState([]);

  const assignInspector = () => {
    let isFieldEmpty = false;
    if (primaryInspector == results) {
      setErrorMsgPrimaryInspectors(true);
      setErrorMsgPrimaryInspectorsMSG(
        "Primary inspector cannot be secondary inspector"
      );
      isFieldEmpty = true;
    } else {
      setErrorMsgPrimaryInspectors(false);
      setErrorMsgPrimaryInspectorsMSG(false);
    }
    if (!primaryInspector) {
      setPrimaryInspectorError(true);
      isFieldEmpty = true;
    }
    if (!formDate) {
      setformDateError(true);
      isFieldEmpty = true;
    }
    if (formDate) {
      const validDate = dateValiadtor(getFormatedDate(formDate));
      if (validDate === false) {
        setDateErrMsg(true);
        isFieldEmpty = true;
      } else if (validDate === "Valid date") {
        setDateErrMsg(false);
      }
    }
    if (!toDate) {
      setToDateError(true);
      isFieldEmpty = true;
    }

    if (toDate) {
      const validDate = dateValiadtor(getFormatedDate(toDate));
      if (validDate === false) {
        setDateErrMsg(true);
        isFieldEmpty = true;
      } else if (validDate === "Valid date") {
        setDateErrMsg(false);
      }
    }

    if (!isFieldEmpty) {
      const assignData = {
        setupId: parseInt(accessUserId.toString()),
        isPrimary: false,
        fromDate: getFormatedDate(formDate),
        toDate: getFormatedDate(toDate),
        inspectorId: parseInt(primaryInspector),
        userId: parseInt(USER_ID()),
      };
      assignSwInspectorDatas(assignData);
    }
  };

  const onRowClicks = (rows) => {
    setUserId([rows.id]);
    setIds(rows.row.id);
  };

  const onRowClick = (rows) => {
    setResults(rows?.row?.primaryInspectorId);
    setAccessUserId([rows.id]);
    setSecondaryInspector(rows?.row?.secondaryInspector);
    setInspector(rows?.row?.secondaryInspector);
    setSetupIds(rows?.row?.setupId);
    const data = {
      setupId: rows?.row?.setupId,
    };

    SwSecondaryInspectorList(data);
  };

  const [openModal, setOpenModal] = useState(false);

  const deletehandle = () => {
    setOpenModal(false);
    const payload = {
      id: ids,
      userId: parseInt(USER_ID()),
    };
    deleteSecondaryInspectorList(payload);
  };

  const deleteSecondaryInspector = (rows) => {
    setOpenModal(true);
  };

  useEffect(() => {
    if (isLoading === false && setupData) {
      setAccessUserId([setupData?.mapId]);
      setSetupIds(setupData?.mapId);
      setInspector(setupData?.secondaryInspectors);
      setSecondaryInspector(setupData?.secondaryInspectors);
    }
  }, [setupData, isLoading]);
  return (
    <div>
      <Grid item container mt={1} spacing={2}>
        {openModal && (
          <DialogBox
            open={openModal}
            rows={[]}
            header={"Confirm Deletion"}
            deletehandle={deletehandle}
            handleCloseModal={() => setOpenModal(false)}
            content={
              "Are you sure you want to delete this Secondary Inspector?"
            }
            view
          />
        )}
        <Grid item lg={4} xs={12} sm={12} md={4}>
          <FormControl fullWidth>
            <FormLabels label={"Primary Inspector Name"} isRequired={false} />
            <SearchAutoComplete
              name="selectInspector"
              optionsData={allUsers ?? []}
              typeOnchange={(event, value) =>
                onSearch(event.target.value, USER_TYPE.EMPLOYEE)
              }
              isKeyValuePair={true}
              value={usersValue ? usersValue : " "}
              onChange={(event, value) => onChangeUsers(value)}
              isLoading={isLoading}
              noOptionsTextMsg={"No User"}
              autoCompClear={autoCompSupervisor}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid item container mt={1} spacing={2}>
        <Grid item lg={12} xs={12} sm={12} md={4}>
          <section className="grid_main_sec">
            <div className="grid_main_body">
              <div className="filter_of_caselist">
                <div className="searchcaseid">
                  <label htmlFor="search">
                    <span>
                      <SearchIcon />
                    </span>
                    <div>
                      <input
                        id="search"
                        placeholder="Search"
                        type="text"
                        value={search}
                        className=""
                        onChange={handleautoSearch}
                        autoComplete="off"
                      />
                    </div>
                  </label>
                </div>

                <div className="filter_btn">
                  <button
                    aria-describedby={id}
                    variant="contained"
                    type="button"
                    name="btn"
                    onClick={(e) => handleClick(e)}
                  >
                    <FilterListIcon id="icn" /> Filters
                  </button>
                  <UserFilterList
                    firstLabel="Inspection Day"
                    secondLabel="Frequency"
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    handleApply={handleApply}
                    handleClear={handleClear}
                    firstLebelFilter={WEEKDAYS}
                    secondLebelFilter={getSwReference?.insfrequencyList ?? []}
                    firstLebelOnChanges={inspectionDayOnChanges}
                    secondLebelOnChanges={frequencyTypeOnchange}
                    firstLebelValue={inspectionDay}
                    secondLebelValue={frequency}
                    resetBtn={true}
                    isShow={parseInt(frequency) === 2}
                  />
                </div>
              </div>

              <div className="desc_box row_uniq">
                <div className="table_style_ui">
                  <GridTable
                    getRowId={(r) => r.setupId}
                    rows={InspectorListData?.data?.inspectionSetupList ?? []}
                    rowCount={InspectorListData?.data?.count ?? 0}
                    columns={credcloumns}
                    loading={isLoading}
                    checkboxSelection={true}
                    page={page}
                    pagination
                    pageSize={PAGE_SIZE}
                    rowsPerPageOptions={[PAGE_SIZE]}
                    onPageChange={handlePagignation}
                    onClick={(rows) => onRowClick(rows)}
                    backIconButtonProps={{ disabled: false }}
                    selectionModel={accessUserId}
                    hideFooterSelectedRowCount
                    onSelectionModelChange={(selection) => {
                      if (selection.length > 1) {
                        const selectionSet = new Set(accessUserId);
                        const result = selection.filter(
                          (s) => !selectionSet.has(s)
                        );
                        setAccessUserId(result);
                      } else {
                        setAccessUserId(selection);
                      }
                    }}
                    multipleSelection={false}
                    disableSelectionOnClick
                    components={{
                      LoadingOverlay: TableLoader,
                      NoRowsOverlay: NORow,
                    }}
                  />
                </div>
              </div>
            </div>
          </section>
        </Grid>
      </Grid>

      {inspector && checkPrivileges([12, 96, 97]) && (
        <Grid container lg={9} className="manageInspector">
          <Grid item lg={3} md={12} xs={12} mt={4}>
            <div
              className="secodndryAssignInspector"
              onClick={() => addAssignBox()}
            >
              {assignBox ? (
                <>
                  <RemoveIcon className="addIconBtns" /> Assign Inspector
                </>
              ) : (
                <>
                  <AddIcon className="addIconBtns" /> Assign Inspector
                </>
              )}
            </div>
          </Grid>
          <Grid item lg={4} md={12} xs={12} mt={4}>
            <div
              className="secodndryInspector"
              onClick={() => AddManageSecondryBox()}
            >
              {manageSecondryBox ? (
                <>
                  <RemoveIcon className="addIconBtns" /> Manage Secondary
                  Inspectors
                </>
              ) : (
                <>
                  <AddIcon className="addIconBtns" /> Manage Secondary
                  Inspectors
                </>
              )}
            </div>
          </Grid>
        </Grid>
      )}
      {assignBox && (
        <>
          <Grid item container mt={3} spacing={2}>
            <Grid item lg={4} xs={12} sm={12} md={4}>
              <FormLabels label="Add Secondary Inspectors" isRequired={true} />
              <SearchAutoComplete
                name="selectUser"
                optionsData={allUsers ?? []}
                typeOnchange={(event, value) =>
                  onSearch(event.target.value, USER_TYPE.EMPLOYEE)
                }
                isKeyValuePair={true}
                value={primaryInspectorValue}
                onChange={(event, value) => onChangePrimaryInspector(value)}
                isLoading={isLoading}
              />
              {primaryInspectorError && !primaryInspector && (
                <div className="errorMsg">Please enter this required field</div>
              )}
              {errorMsgPrimaryInspectors && (
                <div className="error">{errorMsgPrimaryInspectorsMSG}</div>
              )}
            </Grid>
          </Grid>
          <Grid item container lg={10} mt={1} spacing={2}>
            <Grid item lg={5} xs={12} sm={12} md={6}>
              <FormControl fullWidth>
                <FormLabels label={"From"} isRequired={true} />
                <DatePickers
                  maxDateEnable={true}
                  value={formDate}
                  disabled={false}
                  onChangeHandler={(e) => onchangeFormdatePicker(e)}
                />
              </FormControl>
              {formDateError && !formDate && (
                <div className="errorMsg">From Date is required</div>
              )}
              {dateErrMsg && formDate != null && (
                <div className="errorMsg">Invalid Date</div>
              )}
            </Grid>

            <Grid item lg={5} xs={12} sm={12} md={6}>
              <FormControl fullWidth>
                <FormLabels label={"To"} isRequired={true} />
                <DatePickers
                  maxDateEnable={true}
                  minDate={formDate}
                  value={toDate}
                  disabled={false}
                  onChangeHandler={(e) => onchangeTodatePicker(e)}
                />
              </FormControl>
              {toDateError && !toDate && (
                <div className="errorMsg">To Date is required</div>
              )}
              {dateErrMsg && toDate != null && (
                <div className="errorMsg">Invalid Date</div>
              )}
            </Grid>
          </Grid>

          <Grid container mt={3} spacing={1}>
            <Grid lg={2} xs={12} sm={4} md={4} mt={1.5}>
              <Buttons
                varientText={true}
                label="Cancel"
                onClick={() => history(-1)}
              />
            </Grid>
            <Grid lg={1.5} xs={6} sm={4} md={4} mt={1.5}>
              <Buttons
                varientContained={true}
                label={"Assign"}
                onClick={() => assignInspector()}
              />
            </Grid>
            <Grid item lg={4} xs={12} sm={12} md={4}></Grid>
          </Grid>
        </>
      )}
      {manageSecondryBox && (
        <>
          <Grid item container mt={3}>
            <Grid item lg={6} md={8} sm={12}>
              <div className="desc_box row_uniq">
                <div className="table_style_ui">
                  <GridTable
                    getRowId={(r) => r.id}
                    rows={
                      getSwSecondaryInspectorListData?.data
                        ?.secondaryInspectionList ?? []
                    }
                    columns={inspectorcloumns}
                    loading={isLoading}
                    checkboxSelection={true}
                    page={page}
                    pagination
                    pageSize={PAGE_SIZE}
                    rowsPerPageOptions={[PAGE_SIZE]}
                    backIconButtonProps={{ disabled: false }}
                    onClick={(rows) => onRowClicks(rows)}
                    selectionModel={UserId}
                    hideFooterSelectedRowCount
                    onSelectionModelChange={(selection) => {
                      if (selection.length > 1) {
                        const selectionSet = new Set(UserId);
                        const result = selection.filter(
                          (s) => !selectionSet.has(s)
                        );
                        setUserId(result);
                      } else {
                        setUserId(selection);
                      }
                    }}
                    multipleSelection={false}
                    disableSelectionOnClick
                    components={{
                      LoadingOverlay: TableLoader,
                      NoRowsOverlay: NORow,
                    }}
                  />
                </div>
              </div>
            </Grid>
          </Grid>

          {ids && (
            <>
              <Grid item container mt={3}>
                <Grid item lg={6} md={8} sm={12}>
                  <div className="deleteButtonContainer">
                    <div className="deleteImagestyle">
                      <img
                        src={commonImages.deleteIconRed}
                        className="deleteImg"
                        alt=""
                        onClick={deleteSecondaryInspector}
                      />{" "}
                    </div>
                    <div
                      className="deleteButtonText"
                      onClick={deleteSecondaryInspector}
                    >
                      Delete Inspector
                    </div>
                  </div>
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}

      <Grid container mt={3} spacing={1} alignItems={"left"}>
        <Grid lg={2} xs={12} sm={4} md={4}>
          <div className="insBackBtn">
            <BackBtn dataBack={dataBack} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default SecondryInspectorList;
