import React from "react";
import JhaListTemplate from "../component/jha/jhaListTemplate";
import { useNavigate } from "react-router-dom";
import {
  ACCESS_DENIED,
  ADMIN_JHA_ADD_TEMPLATE,
} from "../../../constant/routeContant";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as jhaAction from "../../../redux/actions/jhaAction";
import { API_STATUS, PAGE_SIZE, USER_ID } from "../../../constant/constant";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { InactiveClient } from "../../../utils/helper";

const JhaListTemplateContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // add
  function addTemplate() {
    if (InactiveClient()) return;
    navigate(ADMIN_JHA_ADD_TEMPLATE);
  }

  // get JHa Template List
  const defaultData = {
    page: 1,
    pageSize: PAGE_SIZE,
    search: "",
    assetType: 0,
  };

  const templateListData = useSelector((state) => state.getJhaTemplateList);
  const getTemplateListData = (data) => {
    dispatch(jhaAction.getJhaTemplateList(data));
  };

  useEffect(() => {
    getTemplateListData(defaultData);
  }, []);

  const isLoading = useSelector(
    (state) =>
      state.getJhaTemplateList?.isLoading ||
      state?.updateJhaTemplateStatus?.isLoading
  );

  const updateJhaTemplateStatusData = useSelector(
    (state) => state?.updateJhaTemplateStatus
  );
  function handleStatusChange(data) {
    if (InactiveClient()) return;
    let newData = {
      templateId: data.id,
      isActive: data.isActive,

      modifiedBy: parseInt(USER_ID()),
    };
    dispatch(jhaAction.updateJhaTemplateStatus(newData));
  }
  useEffect(() => {
    if (
      updateJhaTemplateStatusData.status === API_STATUS.SUCCESS &&
      updateJhaTemplateStatusData?.isLoading === false
      // clearalert
    ) {
      toast("Status Updated successfully");
      getTemplateListData(defaultData);
    }
  }, [updateJhaTemplateStatusData]);
  function handleEditOnClick(data) {
    if (InactiveClient()) return;
    navigate(ADMIN_JHA_ADD_TEMPLATE, {
      state: {
        templateId: data.id,
        editMode: true,
      },
    });
  }

  return (
    <div>
      {checkPrivileges([12, 58, 59, 60]) ? (
        <>
          <ToastContainer />
          <JhaListTemplate
            addTemplate={addTemplate}
            templateList={templateListData?.data?.result?.itemList ?? []}
            itemsCount={templateListData?.data?.result?.itemsCount ?? []}
            isLoading={isLoading}
            getTemplateListData={getTemplateListData}
            handleStatusChange={handleStatusChange}
            handleEditOnClick={handleEditOnClick}
          />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}{" "}
    </div>
  );
};

export default JhaListTemplateContainer;
