import React from "react";
import DynamicTable from "../../../../../library/custom/dynamicTable/dynamicTable";
import {
  getValueByKey,
  groundStabilizationColumns,
  lotDisturbanceColumns,
  observationColumns,
  phaseProjectColumns,
} from "../../../../../utils/swInspectionHelper";
import { Grid } from "@mui/material";

const ViewNorthCarolina = (props) => {
  return (
    <>
      <Grid container mt={3}>
        {props?.swInspectionConfQuestionAns?.map((item) => {
          return (
            <Grid
              item
              xs={12}
              sm={12}
              className="confQuestionAns"
              mb={2}
              key={item?.confQuesId}
            >
              <strong>{item?.confQues}:</strong>
              {getValueByKey(item?.confQuesId)}
            </Grid>
          );
        })}
      </Grid>

      <DynamicTable
        title="Current Phase of Project"
        columns={phaseProjectColumns}
        data={props?.swInspectionCurrentProjectPhase}
        checkbox={true}
      />

      <DynamicTable
        title="Ground Stabilization:Must be recorded after each phase of grading"
        columns={groundStabilizationColumns}
        data={props?.swInspectionGroundStabilization}
      />

      <DynamicTable
        title=" Observations Of Runnoff At Stormwater Discharge Outfalls: Details Of Recommended Corrective Actions"
        columns={observationColumns}
        data={props?.swInspectionObservation}
      />

      <DynamicTable
        title="Individual Lot Disturbance / Stabilization Data Log"
        columns={lotDisturbanceColumns}
        data={props?.swInspectionLotDisturbance}
      />
    </>
  );
};

export default ViewNorthCarolina;
