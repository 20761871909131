import { getApiUrl } from "../config";
import { ORG_ID } from "../constant/constant";
import { get, post, deletes } from "./axiosRequest";
import { PRODUCT_ID } from "../constant/storm-water/constant";

export const getResourcesList = async ({ data }) => {
  const {
    pageSize,
    page,
    search,
    resourceTypes,
    expirationType,
    catId,
    userId,
    orgId,
    assetId = 0,
    assetTypeId = 0,
    startDate,
    endDate,
    isSendMail,
  } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/Resource/GetResourcesList?organisationId=${orgId}&isSendMail=${
    isSendMail ? isSendMail : false
  }&page=${page}&pageSize=${pageSize}&search=${search}&startDate=${startDate}&endDate=${endDate}&assetId=${assetId}&assetTypeId=${assetTypeId}&expirationType=${
    expirationType ? expirationType : 0
  }&resourceTypes=${resourceTypes}&catId=${catId}${
    userId ? `&userId=${localStorage.getItem("userId")}` : ` &userId=${0}`
  }&productId=${PRODUCT_ID()}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: orgId,
      },
    });
  } catch (err) {
    return err;
  }
};

export const createResources = async ({ data }) => {
  const clientOrg = data?.client;

  const url = `${getApiUrl().adminApiUrl}/Resource/AddResource`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: clientOrg ? clientOrg : parseInt(ORG_ID()),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateResource = async ({ data }) => {
  const url = `${getApiUrl().adminApiUrl}/Resource/UpdateResource`;
  const clientOrg = data?.client; // TODO Client orgid pass update

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: clientOrg ? clientOrg : parseInt(ORG_ID()),
        //orgId: parseInt(ORG_ID()), TODO for info
      },
    });
  } catch (err) {
    return err;
  }
};

export const getResourcesDetails = async ({ data }) => {
  const { resourcesId, clientsValue } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/Resource/GetResourceDetails?resourceId=${resourcesId}&orgId=${
    clientsValue?.key ? clientsValue?.key : parseInt(ORG_ID())
  }`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: clientsValue?.key ? clientsValue?.key : parseInt(ORG_ID()),
      },
    });
  } catch (err) {
    return err;
  }
};

export const deleteResources = async (payload) => {
  const url = `${getApiUrl().adminApiUrl}/Resource/DeleteResource/${
    payload?.payload
  }`;
  try {
    return await deletes(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
