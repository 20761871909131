import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import "./lessonsList.scss";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabPanel from "../tabPanel/tabPanel";
import LmstableData from "../../../../library/custom/lmstableData/lmstableData";
import { useSelector, useDispatch } from "react-redux";
import * as lmsAction from "../../../../redux/actions/lmsAction";
import { COLUMNS_LESSON_VIEW } from "../../courseConstant";
import TableRowAction from "../../../../library/custom/gridTable/TableRowAction";
import { useNavigate } from "react-router-dom";
import { LESSONDETAIL } from "../../../../constant/routeContant";
import downloadIcon from "../../../../assets/downloadIcon.svg";
import {
  PAGE_SIZE,
  ORG_ID,
  USER_ID,
  API_STATUS,
  HBNEXT_ORG_ID,
} from "../../../../constant/constant";
import SearchIcon from "@mui/icons-material/Search";
import Popper from "@mui/material/Popper";
import LmsFilterList from "../../../../library/custom/lmsFilterList/lmsFilterList";
import FilterButton from "../../../../library/custom/filterButton/filterButton";
import DialogBox from "../../../../library/common/DialogBox";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import { InactiveClient } from "../../../../utils/helper";

const LessonList = (props) => {
  const [page, setPage] = useState(1);
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const [status, setStatus] = useState(0);
  const LessondeleteStatus = props.LessondeleteStatus;
  const publishLessonStatus = props?.publishLessonStatus;
  const getLessonListData = props?.getLessonListData;
  const [placement, setPlacement] = useState();
  const [open, setOpen] = useState(false);
  const deleteLesson = props?.deleteLesson;
  const setisdisable = props?.setisdisable;
  const isdisable = props?.isdisable;
  const [isTableLoading, setisTableLoading] = useState(false);

  const handleChange = (event, newValue) => {
    setPage(1);
    setValue(newValue);
    setStatus(newValue);
    const data = {
      pageNumber: 1,
      pageSize: PAGE_SIZE,
      search: search,
      catIds: selectedValue ? selectedValue : "",
      statusId: "",
      userId: USER_ID(),
      organisationId: ORG_ID(),
      status: newValue,
      isSendMail: false,
    };
    getLessonListData(data);
  };
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const handleClose = (event) => {
    event?.currentTarget.type === "button"
      ? setAnchorEl(event.currentTarget)
      : setAnchorEl(null);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { isLoading } = useSelector((state) => state.getLessonsList);
  const lessionListData = useSelector((state) => state.getLessonsList);
  const categoryListData = props?.categoryListData;
  const noRecourdCount = lessionListData?.data?.noOfRecords;

  const handleViewOnClick = (lessonId) => {
    navigate(LESSONDETAIL, {
      state: { lessonId: lessonId, isAdminLesson: true },
    });
  };
  const publishItem = (lessonId) => {
    const lessonData = {
      lessonId: lessonId,
      isPulish: true,
      orgId: parseInt(ORG_ID()),
    };
    props?.onSubmit(lessonData);
    setAnchorEl(null);
  };

  const unPublishItem = (lessonId) => {
    const lessonData = {
      lessonId: lessonId,
      isPulish: false,
      orgId: parseInt(ORG_ID()),
    };
    props?.onSubmit(lessonData);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (
      LessondeleteStatus === API_STATUS.SUCCESS ||
      publishLessonStatus === API_STATUS.SUCCESS
    ) {
      dispatch(
        lmsAction.getLessonsList({
          search: "",
          pageSize: PAGE_SIZE,
          pageNumber: page ?? 1,
          catIds: selectedValue ? selectedValue : "",
          status: status ? status : "",
          organisationId: ORG_ID(),
          isSendMail: false,
        })
      );
      handleClose();
    }
  }, [LessondeleteStatus, publishLessonStatus]);

  useEffect(() => {
    if (lessionListData.status === API_STATUS.SUCCESS) setisdisable(false);
  }, [lessionListData.status]);

  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");
  const deleteItem = (rows) => {
    if (InactiveClient()) return;
    setOpenModal(true);
    setSelectedRow(rows);
  };
  const deletehandle = () => {
    if (InactiveClient()) return;
    setOpenModal(false);
    const data = {
      lessonId: selectedRow?.lessonId,
    };
    deleteLesson(data);
  };
  const isShowClient =
    parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID) && checkPrivileges([12]);

  const columns = React.useMemo(() => {
    const headers = [...COLUMNS_LESSON_VIEW];
    headers.push({
      field: "",
      width: 75,
      sortable: false,
      disableClickEventBubbling: true,
      filterable: false,
      disableColumnMenu: true,

      renderCell: (rows) => {
        return (
          <TableRowAction
            rows={rows}
            usingComp={"Lesson"}
            deleted={
              (!rows.row.isPublished &&
                checkPrivileges([12, 11]) &&
                isShowClient) ||
              (!rows.row.isPublished &&
                checkPrivileges([12, 11]) &&
                rows?.row?.isGlobal === false)
                ? "deleted"
                : ""
            }
            edit={
              (!rows.row.isPublished &&
                checkPrivileges([12, 11, 10]) &&
                isShowClient) ||
              (!rows.row.isPublished &&
                checkPrivileges([12, 11, 10]) &&
                rows?.row?.isGlobal === false)
                ? "edit"
                : ""
            }
            view
            publish
            viewLabel={"View Lesson"}
            editLabel={"Edit Lesson"}
            deleteLabel={"Delete Lesson"}
            publishText={
              rows?.row?.isPublished ? (
                <span className={isdisable ? "disableclss" : ""}>
                  UnPublish Lesson{" "}
                </span>
              ) : (
                <span className={isdisable ? "disableclss" : ""}>
                  Publish Lesson
                </span>
              )
            }
            viewOnClick={(row) => handleViewOnClick(row?.lessonId)}
            editOnClick={(row) =>
              !row?.isPublished && !InactiveClient()
                ? navigate(`/lesson/edit/${row.lessonId}`)
                : undefined
            }
            deleteOnClick={() =>
              checkPrivileges([12, 11]) ? deleteItem(rows?.row) : ""
            }
            publishOnClick={(row) => {
              if (InactiveClient()) {
                return true;
              } else {
                if (!isdisable) {
                  return row?.isPublished
                    ? unPublishItem(row?.lessonId)
                    : publishItem(row?.lessonId);
                }
              }
            }}
          />
        );
      },
    });
    return headers;
  }, [lessionListData, PAGE_SIZE, navigate, isdisable]);

  const handleautoSearch = (event) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      pageNumber: 1,
      pageSize: PAGE_SIZE,
      search: searchText ? searchText : "",
      catIds: selectedValue ? selectedValue : "",
      status: value,
      userId: USER_ID(),
      organisationId: ORG_ID(),
      isSendMail: false,
    };
    getLessonListData(data);
    setPage(1);
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
  };
  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    getLessonListData({
      pageNumber: newPage + 1,
      pageSize: PAGE_SIZE,
      search: search,
      catIds: selectedValue ? selectedValue : "",
      status: value,
      userId: USER_ID(),
      organisationId: ORG_ID(),
      isSendMail: false,
    });
  };
  const handleSearch = (data) => {
    setAnchorEl(null);
    getLessonListData(data);
    setOpen(false);
  };
  const handleFilterClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };
  const id = open ? "simple-popper" : undefined;
  const [selectedValue, setSelectedValue] = useState();

  const onChangeCategoryType = (item) => {
    setSelectedValue(item);
  };

  const handleClear = (item) => {
    setSelectedValue("");
    setSearch("");

    getLessonListData({
      pageNumber: 1,
      pageSize: PAGE_SIZE,
      search: "",
      catIds: "",
      status: value,
      userId: USER_ID(),
      organisationId: ORG_ID(),
      isSendMail: false,
    });
    setAnchorEl(null);
    setOpen(false);
    setPage(1);
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const sendEmail = () => {
    getLessonListData({
      pageNumber: page,
      pageSize: PAGE_SIZE,
      search: search,
      catIds: selectedValue ? selectedValue : "",
      status: value,
      userId: USER_ID(),
      organisationId: ORG_ID(),
      isSendMail: true,
    });
  };

  return (
    <>
      <div className="lessonsList">
        <Grid container>
          <Grid lg={5} xs={12}>
            {openModal && (
              <DialogBox
                open={openModal}
                rows={[]}
                header={"Confirm Deletion"}
                deletehandle={deletehandle}
                handleCloseModal={() => setOpenModal(false)}
                content={"Are you sure you want to delete this Lesson?"}
                view
              />
            )}
            <Box>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  className="tabcustom"
                  icon={
                    value === 0 ? (
                      <div className="totalV">
                        {value === 0 ? noRecourdCount : ""}
                      </div>
                    ) : (
                      ""
                    )
                  }
                  iconPosition="end"
                  label="All Lessons"
                />
                <Tab
                  className="tabcustom"
                  icon={
                    value === 1 ? (
                      <div className="totalV">
                        {value === 1 ? noRecourdCount : ""}
                      </div>
                    ) : (
                      ""
                    )
                  }
                  label="Published"
                  iconPosition="end"
                />
                <Tab
                  className="tabcustom"
                  icon={
                    value === 2 ? (
                      <div className="totalV">
                        {value === 2 ? noRecourdCount : ""}
                      </div>
                    ) : (
                      ""
                    )
                  }
                  iconPosition="end"
                  label="Unpublished"
                />
              </Tabs>
            </Box>
          </Grid>
          <Grid lg={7} xs={12}>
            <div className="rightFilter">
              <div className="div-download-email-right" onClick={sendEmail}>
                <span>
                  <img
                    src={downloadIcon}
                    className="DownLoadLogo"
                    alt="DownLoad Logo"
                  />
                </span>
                Download Data
              </div>
              <div className="searchfilter">
                <label htmlFor="search">
                  <span>
                    <SearchIcon />
                  </span>
                  <div className="searchfilterInput">
                    <input
                      id="search"
                      placeholder="Search"
                      type="text"
                      value={search}
                      className=""
                      onChange={handleautoSearch}
                      autoComplete="off"
                    />
                  </div>
                </label>
              </div>
              <div
                className="filter_btn"
                onClick={handleFilterClick("bottom-end")}
              >
                <FilterButton label={"Filters"} handleClick={handleClick} />
              </div>
              <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement={placement}
              >
                <LmsFilterList
                  filterType={categoryListData?.categoryList ?? []}
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  handleSearch={handleSearch}
                  tabvalue={value}
                  inputsearch={search}
                  onChangeType={onChangeCategoryType}
                  handleClear={handleClear}
                  filter={{}}
                  PAGE_SIZE={PAGE_SIZE}
                  selectedValue={selectedValue}
                  isFilterTypeList={false}
                  setisTableLoading={setisTableLoading}
                />
              </Popper>
            </div>
          </Grid>
        </Grid>

        <Grid container>
          <TabPanel className="tabPanel" value={value} index={0}>
            {isTableLoading ? (
              ""
            ) : (
              <LmstableData
                lessionListDataAll={lessionListData}
                onPageChange={handlePagignation}
                page={page}
                isLoading={isLoading}
                columns={columns}
              />
            )}
          </TabPanel>
          <TabPanel className="tabPanel" value={value} index={1}>
            {isTableLoading ? (
              ""
            ) : (
              <LmstableData
                lessionListDataAll={lessionListData}
                onPageChange={handlePagignation}
                page={page}
                isLoading={isLoading}
                columns={columns}
              />
            )}
          </TabPanel>
          <TabPanel className="tabPanel" value={value} index={2}>
            {isTableLoading ? (
              ""
            ) : (
              <LmstableData
                lessionListDataAll={lessionListData}
                onPageChange={handlePagignation}
                page={page}
                isLoading={isLoading}
                columns={columns}
              />
            )}
          </TabPanel>
        </Grid>
      </div>
    </>
  );
};

export default LessonList;
