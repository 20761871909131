import commonImages, {
  AssetManagementImage,
  CompanyManagementImage,
  CredentialManagementImage,
  InspectionCardImage,
  UserManagementImage,
  UtilityManagement,
  ReportsManagementImage,
  ContractorManagementImage,
  JhaConfigurationImage,
  UserSearchManagementImage,
} from "../assets";
import { countryCode } from "../config";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

export const USERID = localStorage.getItem("userId");

export const LANGUAGES = {
  US: "en-US",
  MX: "es-MX",
};

export const API_STATUS = {
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
  PENDING: "PENDING",
};

export const PAGE_SIZE = 10;
export const PAGE_SIZE_100 = 100;
export const REPORT_PAGE_SIZE = 20;
export const ROW_PER_PAGE_SIZE = [10, 25, 50, 100];
export const DOCUMENT_UPLOAD_MAX_NUM = 50;
export const DOCUMENT_UPLOAD_MAX_NUM_ONE = 1;
//export const DOCUMENT_UPLOAD_MAX_SIZE = 10485760;
export const DOCUMENT_UPLOAD_MAX_SIZE = 52528235;

export const API_COMPANY_DATA = [
  { key: "1", value: "Abc company" },
  { key: "2", value: "Abc company2" },
];

export const INSPECTION_STATUS = {
  Draft: 1,
  Open: 2,
  Closed: 3,
  Save: 4,
  Submit: 5,
  Na: 6,
  SubmitForReview: 7,
  ActionPending: 8,
  Scheduled: 9,
  Missed: 10,
  SubmittedByOther: 11,
};

export const API_ADMIN_STATUS = [
  { key: "1", value: "Active" },
  { key: "2", value: "In Active" },
];

export const API_ADMIN_STATUS_STATUS = [
  { key: "1", value: "Active" },
  { key: "0", value: "In Active" },
];

export const INCIDENT_STATUS = {
  DRAFT: "1",
  SUBMITTED: "2",
  CLOSED: "3",
  BOTH: "",
};

export const ITEM_STATUS_VALUE = [
  { key: 1, value: "Open" },
  { key: 2, value: "Closed" },
  { key: 3, value: "All" },
];

export const INCIDENT_STATUS_Key_VALUE = [
  { key: "Draft", value: "1" },
  { key: "Open", value: "2" },
  { key: "Closed", value: "3" },
];

export const INCIDENT_STATUS_VALUE = [
  { key: 1, value: "Draft" },
  { key: 2, value: "Open" },
  { key: 3, value: "Closed" },
];

export const PERMIT_TYPE = {
  PRIMARY: "1",
  SECONDARY: "2",
  TERTIARY: "3",
};

export const TRAINING_OPTION = [
  { key: 1, value: "Lesson" },
  { key: 2, value: "Course" },
];

export const SITE_STATUS = [
  { key: "Inactive", value: "0" },
  { key: "Active", value: "1" },
];

export const ORIGIN = [
  { value: "Inspection", key: 1 },
  { value: "Incident", key: 2 },
  { value: "Observation", key: 3 },
  { value: "Reprimand", key: 4 },
];

export const ITEM_TYPE = [
  { value: "Kudos", key: 1 },
  { value: "Corrective", key: 2 },
  { value: "Reprimand", key: 3 },
  { value: "Action", key: 4 },
];
export const OBSERVATION_ITEM_TYPE = [
  { value: "Kudos", key: 1 },
  { value: "Corrective", key: 2 },
];

export const ASSET_TYPE = [
  { value: "Project", key: 1 },
  { value: "Equipment", key: 2 },
  { value: "Vehicle", key: 3 },
  { value: "Custom Asset", key: 4 },
];

export const STATUS_TYPE = [
  { value: "Open", key: 2 },
  { value: "Close", key: 3 },
];

export const OPEN_ITEM_STATUS = {
  OPEN: "OPEN",
  CLOSED: "CLOSED",
};

export const ticketPriority = [
  { key: "1", value: "High" },
  { key: "2", value: "Medium " },
  { key: "3", value: "Low" },
];

// todo
export const ticketCategory = [
  { key: "1", value: "Inspection Management" },
  { key: "2", value: "Login/SignUp" },
  { key: "3", value: "LMS" },
  { key: "4", value: "Contractor Management" },
  { key: "5", value: "Admin & DashBoard" },
  { key: "6", value: "Incident Management" },
];

export const numberOfOccupants = [
  { key: "1", value: "1" },
  { key: "2", value: "2" },
  { key: "3", value: "3" },
  { key: "4", value: "4" },
  { key: "5", value: "5" },
  { key: "6", value: "6" },
  { key: "7", value: "7" },
  { key: "8", value: "8" },
  { key: "9", value: "9" },
  { key: "10", value: "10" },
];
export const INCIDENT_LIST_COLOUMNS = [
  { field: "reportId", headerName: "Report ID", flex: 2 },
  { field: "caseId", headerName: "Case ID", flex: 1.8 },
  { field: "dateOfEntry", headerName: "Date of Entry", flex: 2 },
  { field: "incidentType", headerName: "Incident Type", flex: 2.5 },
  {
    field: "enteredBy",
    headerName: "Entered by",
    type: "text",
    flex: 2.5,
  },
  {
    field: "status",
    headerName: "Status",
    type: "text",
    flex: 1,
  },
];

export const MODULES_NAME = {
  incident: "INCIDENT",
  inspection: "INSPECTION",
  swInspection: "SWINSPECTION",
  safteyInspectionMang: "SAFTEYINSPECTIONMANG",
  swInspectionConfig: "SWINSPECTIONCONFIG",
  swInspectionSetup: "SWINSPECTIONSETUP",
  support: "SUPPORT",
  lms: "LMS",
  admin: "ADMIN",
};

export const ROLE_TYPE = {
  fieldUser: "FIELD_USER",
  pointOfContact: "POINT_OF_CONTACT",
  divisionAdmin: "DIVISION_ADMIN",
  supervisor: "SUPERVISOR",
  inspector: "INSPECTOR",
};

export const USER_TYPE = {
  EMPLOYEE: 0,
  CONTRACTOR: 1,
  ALL: 2,
  RESOLUTION: 3,
  DIVISION_EMPLOYEE: 4,
  DIVISION_CONTRACTOR: 5,
  NONPRIVILEGED: 6,
};

export const GET_USER_TYPE = {
  EMPLOYEE: 0,
  CONTRACTOR: 1,
  ALLUSERS: 2,
  PREVNONPREVUSER: 3,
};

export const COUNTRY_CODE = "+" + countryCode;
export const COUNTRY_CODE_ENCODED = "%2B" + countryCode;

export const LOGIN_ERROR_MESSAGE = "Incorrect Mobile or password.";

export const INCIDENT_REPORT_ADDED = "Incident Report Saved successfully";
export const INCIDENT_REPORT_UPDATE = "Incident Report Updated successfully";

export const SITE_TEMPLATE_URL =
  "https://safetycloudfiles-dev.s3.amazonaws.com/20240411T164323654Z388482.xlsx";
export const PROJECT_SW_TEMPLATE_URL =
  "https://safetycloudfiles-dev.s3.amazonaws.com/20240530T055605549Z167831.xlsx";

export const CREDENTIAL_TEMPLATE_URL =
  "https://safetycloudfiles-dev.s3.amazonaws.com/incident/20230502T04273120230501T09314920230501T09171920230327T090518BulkCredentialTemplate.xlsx";
export const USERS_TEMPLATE_URL =
  "https://safetycloudfiles-dev.s3.amazonaws.com/incident/20231214T17511820230925T11123720230302T11193820230227T10581020221126T145412BulkUsersTemplates.xlsx";

export const CLIENT_TEMPLATE_URL =
  "https://safetycloudfiles-dev.s3.amazonaws.com/20240828T171938826Z737450.xlsx";

export const STROM_USERS_TEMPLATE_URL =
  "https://safetycloudfiles-dev.s3.amazonaws.com/incident/20231220T15425420231220T15242520231214T17511820230925T11123720230302T11193820230227T10581020221126T145412BulkUsersTemplates2.xlsx";

export const STROM_SAFETY_USERS_TEMPLATE_URL =
  "https://safetycloudfiles-dev.s3.amazonaws.com/20240411T165554839Z159362.xlsx";

export const NON_PRIVILEGE_USERS_TEMPLATE_URL =
  "https://safetycloudfiles-dev.s3.amazonaws.com/20240411T170527199Z833930.xlsx";
export const CONTACT_TEMPLATE_URL =
  "https://safetycloudfiles-dev.s3.amazonaws.com/20240412T033714908Z212687.xlsx";
export const ADMIN_CONTACT_TEMPLATE_URL =
  "https://safetycloudfiles-dev.s3.amazonaws.com/incident/20231228T095957GlobalContactBulkUserTemplate.xlsx";
export const ASSET_TEMPLATE_URL =
  "https://safetycloudfiles-dev.s3.amazonaws.com/20240412T040233028Z222646.xlsx";
export const VEHICLE_TEMPLATE_URL =
  "https://safetycloudfiles-dev.s3.amazonaws.com/20240412T035522940Z031101.xlsx";
export const PROJECT_TEMPLATE_URL =
  "https://safetycloudfiles-dev.s3.amazonaws.com/20240412T035211809Z317202.xlsx";
export const EQUIPMENT_TEMPLATE_URL =
  "https://safetycloudfiles-dev.s3.amazonaws.com/20240412T040014793Z126688.xlsx";
export const CONTRACTOR_TEMPLATE_URL =
  "https://safetycloudfiles-dev.s3.amazonaws.com/20240411T171500420Z405666.xlsx";
export const QUESTION_TEMPLATE_URL =
  "https://safetycloudfiles-dev.s3.amazonaws.com/20240612T090005424Z852851.xlsx";
export const HBNEXT_ORG_ID = 1;
export const SAFTEY_SERVICE_TYPE = 1;
export const ORG_ID = () => localStorage.getItem("orgId");
export const ORG_NAME = () => localStorage.getItem("orgName");
export const DIVISION_ID = () =>
  localStorage.getItem("divisionId")
    ? localStorage.getItem("divisionId")
    : null;
export const USER_ID = () => localStorage.getItem("userId");
export const LOGIN_ORG_ID = () => localStorage.getItem("loginOrganizationId");

export const SERVICE_TYPE = () => localStorage.getItem("serviceType");

export const IS_CLIENT = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  return userInfo?.isClient;
};

export const USER_INFO = () => localStorage.getItem("userInfo");

export const BUSINESSTYPE_ID = 1;
export const BUSINESS_TYPE = [{ key: "1", value: "Construction" }];
export const PASSPERCENTAGETYPE = [
  {
    key: 50,
    value: "50%",
  },
  {
    key: 70,
    value: "70%",
  },
  {
    key: 80,
    value: "80%",
  },
  {
    key: 90,
    value: "90%",
  },
  {
    key: 100,
    value: "100%",
  },
];

export const USER_LIST_DESIGNATION_FILTER = [
  { key: "1", value: "Plumber1" },
  { key: "2", value: "Plumber2" },
];

export const GET_ITEM_LOG = {
  All: 0,
  Kudos: 1,
  Corrective: 2,
  Reprimand: 3,
  Action: 4,
};

export const USER_LIST_COMPANY_FILTER = [
  { key: "1", value: "Abc company" },
  { key: "2", value: "Abc company2" },
];

export const CREDENTIAL_LIST_BODY_FILTER = [{ key: "1", value: "osha" }];

export const CREDENTIAL_LIST_CRED_FILTER = [{ key: "1", value: "degree" }];

export const CREDENTIALLOOKUPTABS = [
  { id: 1, name: "Users" },
  { id: 2, name: "Credentials" },
];
export const DIVISIONSTABS = [
  { id: 1, name: "Projects" },
  { id: 2, name: "Equipments" },
  { id: 3, name: "Vehicles" },
  { id: 4, name: "Custom Asset" },
  { id: 5, name: "Users" },
  { id: 6, name: "Groups" },
  { id: 7, name: "Sub Contractors" },
];

export const SWDIVISIONSTABS = [
  { id: 1, name: "Projects" },
  { id: 5, name: "Users" },
  { id: 6, name: "Groups" },
];

export const USERSEARCHBYROLETABS = [
  { id: 1, name: "Projects" },
  { id: 2, name: "Equipments" },
  { id: 3, name: "Vehicles" },
  { id: 4, name: "Custom Asset" },
  { id: 5, name: "Groups" },
];

export const SWUSERSEARCHBYROLETABS = [
  { id: 1, name: "Inspections" },
  { id: 5, name: "Groups" },
];

export const SAFETYLOGTABS = [
  { id: 1, name: "Training" },
  { id: 2, name: "Kudos" },
  { id: 3, name: "Reprimand" },
  { id: 4, name: "Corrective" },
  { id: 5, name: "Hazard Analysis" },
];

export const OPENITEMSLISTTABS = [
  { id: 1, name: "Kudos" },
  { id: 2, name: "Corrective" },
  { id: 3, name: "Reprimand" },
  { id: 4, name: "Action" },
];

export const LANGUAGE = [
  { key: 1, value: "English" },
  { key: 2, value: "Spanish" },
];

export const PROFILETABS = [
  { id: 1, name: "My Profile" },
  { id: 2, name: "Assets" },
  { id: 3, name: "My Credentials" },
  { id: 4, name: "My Resources" },
  { id: 5, name: "My Training" },
  { id: 6, name: "My User Groups" },
];

export const STORMWATERPROFILETABS = [
  { id: 1, name: "My Profile" },
  { id: 2, name: "My Inspections" },
  { id: 3, name: "My Credentials" },
  { id: 4, name: "My Resources" },
  { id: 5, name: "My Training" },
  { id: 6, name: "My User Groups" },
];

export const ETRAININGTABS = [
  { id: 1, name: "Courses" },
  { id: 2, name: "Lessons" },
  { id: 3, name: "Safety Meeting" },
];

export const STORMWATERETRAININGTABS = [
  { id: 1, name: "Courses" },
  { id: 2, name: "Lessons" },
];

export const TEXT_FIELD_ERROR_MSG = "Please enter this required field.";
export const DROP_DOWN_FIELD_ERROR_MSG =
  "Selection is required for this field ";

export const INSPECTIONCARD = [
  {
    id: 4,
    title: "Inspection Configuration",
    image: InspectionCardImage.inspectionconfigation,
  },
  {
    id: 1,
    title: "Category Configuration",
    image: InspectionCardImage.categories,
  },
  {
    id: 2,
    title: "Sub Category Configuration",
    image: InspectionCardImage.subcategories,
  },
  {
    id: 3,
    title: "Questions Configuration",
    image: InspectionCardImage.questions,
  },
  {
    id: 6,
    title: "Header Configuration",
    image: InspectionCardImage.questions,
  },
  {
    id: 5,
    title: "Inspection Acknowledgment",
    image: InspectionCardImage.inspectionacknowledgment,
  },
];

export const CATEGORYCARD = [
  {
    id: 1,
    title: "Incident Category",
    image: UtilityManagement.incidentcat,
  },
  {
    id: 2,
    title: "Learning Category",
    image: UtilityManagement.lmscategory,
  },
  {
    id: 7,
    title: "Resources Category",
    image: InspectionCardImage.categories,
  },
  {
    id: 3,
    title: "Support Category",
    image: UtilityManagement.supportcategory,
  },

  {
    id: 4,
    title: "Admin Category",
    image: UtilityManagement.admincategory,
  },
  {
    id: 6,
    title: "Credentials Category",
    image: UtilityManagement.credentialscategory,
  },
  {
    id: 5,
    title: "Assets Category",
    image: UtilityManagement.assetscategory,
  },
  {
    id: 8,
    title: "Add Files",
    image: UtilityManagement.image,
  },
  {
    id: 9,
    title: "Configure Permittee Types",
    image: UtilityManagement.image,
  },
  {
    id: 10,
    title: "General Permit List",
    image: UtilityManagement.image,
  },
  {
    id: 11,
    title: "Lms Mapping",
    image: UtilityManagement.lmsmappingcategory,
  },
];

export const RESOURCETYPES = [
  { key: 1, value: "Document" },
  { key: 2, value: "Link" },
];
export const ASSETMANAGEMENT = [
  { id: 1, title: "Projects", image: AssetManagementImage.project },
  { id: 2, title: "Vehicles", image: AssetManagementImage.vehicles },
  { id: 3, title: "Equipment", image: AssetManagementImage.equipments },
  { id: 4, title: "Custom Assets", image: AssetManagementImage.customAssets },
  { id: 5, title: "Procore Projects", image: AssetManagementImage.project },
];

export const JHA_CONFIGURATION_MANGEMENT = [
  {
    id: 1,
    title: "Category Configuration",
    image: JhaConfigurationImage.categoryJha,
  },
  { id: 2, title: "Activities", image: JhaConfigurationImage.activitiesJha },
  {
    id: 3,
    title: "Hazards & Controls",
    image: JhaConfigurationImage.hazardJha,
  },
  {
    id: 4,
    title: "Create Hazard Analysis Template",
    image: JhaConfigurationImage.templateJha,
  },

  {
    id: 5,
    title: "Setup Hazard Analysis",
    image: InspectionCardImage.questions,
  },
];

export const COMPANYMANAGEMENT = [
  { id: 1, title: "Divisions", image: CompanyManagementImage.divisions },
  { id: 2, title: "Contacts", image: CompanyManagementImage.contacts },
  { id: 3, title: "Resources", image: CompanyManagementImage.resources },
];
export const CREDENTIALMANAGEMENT = [
  {
    id: 1,
    title: "Setup Credentials",
    image: CredentialManagementImage.setupcredentials,
  },
  {
    id: 2,
    title: "Assign Credential",
    image: CredentialManagementImage.assigncredential,
  },
  // {
  //   id: 3,
  //   title: "My Credentials",
  //   image: CredentialManagementImage.mycredentials,
  // },
  {
    id: 4,
    title: "Credential Lookup",
    image: CredentialManagementImage.credentiallookup,
  },

  {
    id: 5,
    title: "Add User Credentials",
    image: CredentialManagementImage.mycredentials,
  },
];
export const USERMANAGEMENT = [
  { id: 1, title: "Users", image: UserManagementImage.users },
  { id: 2, title: "Groups", image: UserManagementImage.groups },
  { id: 3, title: "Assign Roles", image: UserManagementImage.assignroles },
  {
    id: 4,
    title: "User Configuration ",
    image: UserManagementImage.usersconfiguration,
  },
  {
    id: 5,
    title: "Non-Privileged Users",
    image: UserManagementImage.users,
  },
  {
    id: 6,
    title: "User search",
    image: UserManagementImage.userSearch,
  },
  {
    id: 7,
    title: "Procore Users",
    image: UserManagementImage.usersconfiguration,
  },
  {
    id: 8,
    title: "Bounced Email List",
    image: UserManagementImage.usersBouncedEmail,
  },
];

export const USERSEARCHMANAGEMENT = [
  { id: 1, title: "User Info Check", image: UserSearchManagementImage.users },
  {
    id: 2,
    title: "User Search by Role",
    image: UserSearchManagementImage.userSearchByRole,
  },
];

export const USER_EMAIL_BOUNCE_TYPE = [
  { key: "Permanent", value: "Permanent" },
  { key: "Transient", value: "Transient" },
];

export const REPORTSMANAGEMENT = [
  { id: 1, title: "Credentials", image: ReportsManagementImage.credentials },
  { id: 2, title: "Item Data", image: ReportsManagementImage.safetydata },
  {
    id: 3,
    title: "Inspection Data",
    image: ReportsManagementImage.reportsdata,
  },
  {
    id: 4,
    title: "Training Data",
    image: ReportsManagementImage.trainingsessions,
  },
  {
    id: 5,
    title: "Utilities",
    image: ReportsManagementImage.utilities,
  },
  {
    id: 6,
    title: "Personal Injury",
    image: ReportsManagementImage.utilities,
  },
  {
    id: 7,
    title: "Reports & Analytics Dashboard",
    image: ReportsManagementImage.reportsanalyticsdashboard,
  },
];

export const SWREPORTSMANAGEMENT = [
  { id: 1, title: "Inspection", image: ReportsManagementImage.reportsdata },
  {
    id: 2,
    title: "Report Summary",
    image: ReportsManagementImage.reportSummary,
  },
  { id: 3, title: "Map View", image: ReportsManagementImage.mapview },
  { id: 4, title: "Rainfall Data", image: ReportsManagementImage.rainfall },
];

export const CONTRACTORMANAGEMENT = [
  {
    id: 1,
    title: "Contractor List",
    image: ContractorManagementImage.ContractorList,
  },
  {
    id: 2,
    title: "Contractor Configuration",
    image: ContractorManagementImage.ContractorConfiguration,
  },
  {
    id: 3,
    title: "Contractor Mapping",
    image: InspectionCardImage.categories,
  },
];

export const ISwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 44,
  height: 24,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 1,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#1B436E" : "#1B436E",
        opacity: 1,
        border: 0,
      },
      "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 22,
        height: 22,
        backgroundColor: "#FFFFFF",
        border: "1px solid #DDE2E5",
        margin: 0,
        marginLeft: 4,
        marginTop: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
    backgroundColor: "#DDE2E5",
    border: "1px solid #DDE2E5",
    margin: 0,
    marginLeft: 5,
    marginTop: 0,
  },
  "& .MuiSwitch-track": {
    borderRadius: 32 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#FFFFFF" : "#FFFFFF",
    border: "1px solid #DDE2E5",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    margin: 0,
    marginLeft: 4,
  },
}));
export const ASSET_GRID_COLOUMNS = [
  { field: "assetName", headerName: "Asset Name", flex: 2 },
];

export const PROJECT_GRID_COLOUMNS = [
  { field: "projectName", headerName: "Project Name", flex: 2 },
  { field: "location", headerName: "Location", flex: 2 },
];

export const CREDENTIAL_GRID_COLOUMNS = [
  { field: "credentialNameId", headerName: "Credential Name", flex: 2 },
  { field: "credentialNumber", headerName: "Credential Number", flex: 2 },
];

export const SITE_GRID_COLOUMNS = [
  { field: "siteName", headerName: "Site Name", flex: 2 },
  { field: "primaryOwner", headerName: "Primary Owner", flex: 2 },
];

export const VEHICLE_GRID_COLOUMNS = [
  { field: "makeAndModel", headerName: "Make and Model", flex: 2 },
  { field: "licensePlateNumber", headerName: "License Plate Number", flex: 2 },
];

export const EQUIPMENT_GRID_COLOUMNS = [
  { field: "makeAndModel", headerName: "Make and Model", flex: 2 },
  {
    field: "serialNumber",
    headerName: "Equipment Serial Number",
    flex: 2,
  },
];
export const QUESTION_GRID_COLOUMNS = [
  { field: "subcategoryId", headerName: "Subcategory Id", flex: 2 },
  { field: "question", headerName: "Question", flex: 2 },
  { field: "responsetype", headerName: "Response Type", flex: 1 },
  { field: "involvedParty", headerName: "Involved Party Type", flex: 1 },
  { field: "optionList", headerName: "Option List", flex: 2 },
];
export const USERS_TYPE = [
  { key: "0", value: "All" },
  { key: "1", value: "Created By Admin" },
  { key: "2", value: "Created By User" },
  { key: "3", value: "Assigned By Admin" },
];

export const SELECT_USER_TYPE = [
  { key: "1", value: "All Users" },
  { key: "2", value: "User Groups" },
];

export const SELECT_EXPIRATION_PERIOD = [
  { key: 11, value: "No Expiration Period" },
  { key: 1, value: "1" },
  { key: 2, value: "2" },
  { key: 3, value: "3" },
  { key: 4, value: "4" },
  { key: 5, value: "5" },
  { key: 6, value: "6" },
  { key: 7, value: "7" },
  { key: 8, value: "8" },
  { key: 9, value: "9" },
  { key: 10, value: "10" },
];
export const EXPIRATION_TYPE = [
  { key: "all", value: "All" }, //pass 0 in API
  { key: 1, value: "Not Expired" },
  { key: 2, value: "Expired" },
  { key: 3, value: "None" },
];

export const OPEN_ITEMS_SELECT_FILTER = [
  { key: "1", value: "Division" },
  { key: "2", value: "Project" },
];

export const OPEN_CASE_LIST_FILTER = [
  { key: 1, value: "Draft" },
  { key: 2, value: "Open" },
  { key: 3, value: "Closed" },
];

export const FILTERDAYS = [
  {
    key: 0,
    value: "Today",
  },
  {
    key: 7,
    value: "7 days",
  },
  {
    key: 14,
    value: "14 days",
  },
  {
    key: 30,
    value: "30 days",
  },
  {
    key: 90,
    value: "90 days",
  },
  {
    key: 12,
    value: "Custom",
  },

  {
    key: 13,
    value: "Reset",
  },
];

export const PERSONALINJURYFACTORS = [
  {
    key: 1,
    value: "Contributing Factors",
  },
  {
    key: 2,
    value: "Type of Injury",
  },
  {
    key: 3,
    value: "Body Part Injured",
  },
  {
    key: 4,
    value: "Treatment Type",
  },
  {
    key: 5,
    value: "Recordable (OSHA 301)",
  },
];

export const GET_COUNT_TYPE = {
  KUDOS: 1,
  VIOLATION: 2,
  REPRIMAND: 3,
  ACTION: 4,
};

export const SAMPLINGREQUIREDDATA = [
  { key: "0", value: "Yes" },
  { key: "1", value: "No" },
];
export const FORCASTDATA = [
  { key: "0", value: "True" },
  { key: "1", value: "False" },
];

export const API_SITE_NAME = [{ key: "94", value: "Gyan Vatika Site" }];

export const associatedIncidentType = [
  {
    key: 1,
    value: "Personal Injury",
  },
  {
    key: 2,
    value: "Vehicle Accident",
  },
  {
    key: 5,
    value: "Near Miss",
  },
  {
    key: 3,
    value: "Stolen Property",
  },
  {
    key: 4,
    value: "Utility Damage",
  },
];

export const ASSET_TYPE_JHA = [
  { key: 1, value: "Projects" },
  { key: 4, value: "Custom assets" },
];

export const CATEORIES_TEMPLATES = [
  {
    categoryId: 0,
    categoryName: "Cat1",
    activities: [{ key: 0, value: "Cat1Act1" }],
  },
  {
    categoryId: 1,
    categoryName: "Cat2",
    activities: [
      { key: 0, value: "Cat2Act1" },
      { key: 1, value: "Cat2Act2" },
    ],
  },
];
export const PRODUCT_LIST = [
  { key: 1, value: "Safety" },
  { key: 2, value: "Stormwater" },
];
export const LANDING_CARD = [
  {
    id: 1,
    title: "Safety",
    image: commonImages.safetylandingpage,
  },
  {
    id: 2,
    title: "Stormwater",
    image: commonImages.stormlandingpage,
  },
];
export const IMAGEEXT = {
  png: "png",
  jpg: "jpg",
  jpeg: "jpeg",
  gif: "gif",
  svg: "svg",
  pdf: "pdf",
  doc: "doc",
  docx: "docx",
  ppt: "ppt",
  pptx: "pptx",
  xls: "xls",
  xlsx: "xlsx",
  zip: "zip",
};
export const PRODUCT_TYPE_CONSTANT = {
  SAFETY: 1,
  STORMWATER: 2,
};
export const FILTER_RAINFALL_DAYS = [
  // {
  //   key: 1,
  //   value: "Today",
  // },
  {
    key: 7,
    value: "7 days",
  },
  {
    key: 14,
    value: "14 days",
  },
  {
    key: 30,
    value: "30 days",
  },

  {
    key: 12,
    value: "Custom",
  },
];
export const TRACKING_ID = "G-XNFFC5719V";

export const LIGHT = "light";
export const DARK = "dark";

export const SIGNOFF_METHOD_TYPE = {
  SIGNATURE: 1,
  CERTIFY_STATEMENT: 2,
  NA: 3,
};
export const QUESTION_RESPONSE_TYPE = {
  ACCEPTABLE_NOTACCEPTABLE_NOTAPPLICABLE: 1,
  TEXTBOX: 2,
  SINGLE_SELECT: 3,
  MULTI_SELECT: 4,
};
export const QUESTION_RESPONSE_TYPE_VALUE = [
  { key: 1, value: "Acceptable/Not Acceptable/NA" },
  { key: 2, value: "Text Box" },
  { key: 3, value: "Single Select Dropdown" },
  { key: 4, value: "Multiple Select Dropdown" },
];
export const INSPECTION_HEADER_RESPONSE_TYPE = {
  TEXTBOX: 1,
  DATE: 2,
  TIME: 3,
  SINGLE_SELECT: 4,
  MULTI_SELECT: 5,
  CHECKBOX: 6,
  RADIOBUTTON: 7,
  INPUT_TEXTBOX: 8,
  AUTOSEARCH: 9,
  PILLS_BUTTON: 10,
};
export const QUESTION_ANSWER_RESPONSE_TYPE = {
  ACCEPTABLE: 1,
  NOTACCEPTABLE: 2,
  NOTAPPLICABLE: 3,
  TEXTBOX: 4,
  SINGLE_SELECT: 5,
  MULTI_SELECT: 6,
};

export const REPORTS_ANALYTICS_DASHBOARD_LIST = [
  { id: 1, name: "Inspections" },
  { id: 2, name: "Learning" },
  { id: 3, name: "Incidents" },
];

export const TOP_FIVE_INSPECTOR = [
  { value: "Name" },
  { value: "Corrective Items" },
];
export const TOP_FIVE_SUB_CONTRACTORS = [
  { value: "Company" },
  { value: "Corrective Items" },
];
export const TOP_FIVE_SUB_CONTRACTORS_KUDOS = [
  { value: "Company" },
  { value: "Kudos" },
];
export const TOP_FIVE_USERS_KUDOS = [{ value: "Name" }, { value: "Kudos" }];
export const TOP_FIVE_USERS_CORRECTIVE = [
  { value: "Name" },
  { value: "Corrective Items" },
];
export const TOP_FIVE_EXPIRED_CREDENTIALS = [
  { value: "User" },
  { value: "Credential" },
  { value: "Exp, Date" },
];
export const TOP_FIVE_EXPIRING_CREDENTIALS_90_DAYS = [
  { value: "User" },
  { value: "Credential" },
  { value: "Qty" },
];
export const TOP_FIVE_SUB_CONTRACTORS_WITH_MOST_SAFETY_MEETINGS = [
  { value: "Company" },
  { value: "Safety Meetings" },
];
export const TOP_FIVE_HIGHEST_COMPLETED_TRAINING_USERS = [
  { value: "Name" },
  { value: "Completed Training" },
];
export const LOWEST_PARTICIPATION_TRAINING = [
  { value: "Training Name" },
  { value: "Incomplete Training" },
];
export const USERS_WITH_INCOMPLETE_TRAINING = [
  { value: "Name" },
  { value: "Incomplete Training" },
];
export const MOST_SAFETY_MEETINGS_CONDUCTED = [
  { value: "Name" },
  { value: "" },
];
export const MOST_INCOMPLETE_TRAINING_USERS = [
  { value: "Name" },
  { value: "Incomplete Training" },
];
export const MOST_COMPLETE_TRAINING_USERS = [
  { value: "Name" },
  { value: "Conducted Meetings" },
];
export const TIMEPERIOD = [
  { key: 1, value: "7 Days" },
  { key: 2, value: "30 Days" },
  { key: 3, value: "60 Days" },
  { key: 4, value: "90 Days" },
  { key: 5, value: "Custom date range" },
];

export const USER_INVOLVEDPARTY = [
  { id: 1, name: "Kudos" },
  { id: 2, name: "Corrective" },
];
export const USER_TRAININGS_TYPE = [
  { id: 1, name: "Courses" },
  { id: 2, name: "Lessons" },
  // { id: 3, name: "Safety Meetings" },
];

export const COLORS = {
  White: "#fff",
  BrightAqua: "#1DF2E5",
  MediumGreen: "#34A853",
  BlueDress: "#1D7DEA",
  NeonPurple: "#BB1DF2",
  RustOrange: "#BD5B00",
  RubyRed: "#F21D1D",
  Dune: "#333333",
  IronsideGrey: "#666666",
  VeryDardBlue: "#1C446F",
  Pink: "#F21DD0",
  LightGreen: "#A1F21D",
  blue: "#1D4CF2",
};
export const associatedIncidentType1 = [
  {
    key: -1,
    value: "All Incidents",
  },
  {
    key: 1,
    value: "Personal Injury",
  },
  {
    key: 2,
    value: "Vehicle Accident",
  },
  {
    key: 5,
    value: "Near Miss",
  },
  {
    key: 3,
    value: "Stolen Property",
  },
  {
    key: 4,
    value: "Utility Damage",
  },
];
export const TOP_FIVE_PART_INJURED = [{ value: "Category" }, { value: "Qty" }];

export const LINE_CHART = [
  {
    lessonCount: 4,
    courseCount: 3,
    safetyMeetingCount: 5,
    jhaCount: 2,
    date: "01-01-2024",
  },
  {
    lessonCount: 5,
    courseCount: 1,
    safetyMeetingCount: 1,
    jhaCount: 7,
    date: "02-05-2024",
  },
  {
    lessonCount: 7,
    courseCount: 6,
    safetyMeetingCount: 2,
    jhaCount: 3,
    date: "03-01-2024",
  },
];

export const topfiveprojects = [
  {
    assetId: 318,
    assetName: "Division test 1",
    count: 2,
  },
  {
    assetId: 231,
    assetName: "new test",
    count: 2,
  },
  {
    assetId: 469,
    assetName: "fgdgfdg",
    count: 2,
  },
  {
    assetId: 326,
    assetName: "Check Project",
    count: 1,
  },
  {
    assetId: 535,
    assetName: "Res Project T",
    count: 1,
  },
];
export const CORRENT_OPEN_CASE = [
  { value: "Case ID" },
  { value: "Date of Case" },
];
export const USER_WITH_MOST_ACTION = [
  { value: "Name" },
  { value: "Action Items" },
];
export const USER_WITH_MOST_INCIDENT = [
  { value: "Name" },
  { value: "Incidents" },
];

export const CLIENT_SATATUS_FILTER = [
  { key: 1, value: "Active" },
  { key: 2, value: "Inactive" },
];

export const CLIENT_PRODUCT_FILTER = [
  { key: 1, value: "Safety" },
  { key: 2, value: "Stromwater" },
  { key: 3, value: "All" },
];

export const INS_STATUS = [
  { key: 1, value: "Draft" },
  { key: 5, value: "Submitted" },
];

export const OBSERVATION_STATUS = [
  { key: 2, value: "Open" },
  { key: 3, value: "Closed" },
];

export const ESTABLISHMENT_TYPE = [
  { key: 1, value: "Not a Government entity" },
  { key: 2, value: "State Government entity" },
  { key: 3, value: "Local Government entity" },
];

export const TEXT_EDITOR_CHARECTER_LIMIT = 2000;

export const OSHASTATUS = [
  { key: 2, value: "In Progress" },
  { key: 3, value: "Submit" },
];

export const CASESSTATUS = [
  { key: 3, value: "Open" },
  { key: 1, value: "In Progress" },
  { key: 2, value: "Closed" },
];

export const INCIDENT_TYPE = [
  {
    key: 1,
    value: "Personal Injury",
  },
  {
    key: 2,
    value: "Vehicle Accident",
  },
  {
    key: 3,
    value: "Stolen Property",
  },
  {
    key: 4,
    value: "Utility Damage",
  },
  {
    key: 5,
    value: "Near Miss",
  },
];
export const QR_LANDING_CARD = [
  {
    id: 1,
    title: "Inspection Reports",
    image: commonImages.qaCoderafiki,
  },
  {
    id: 2,
    title: "Resources",
    image: commonImages.qaCode_rafiki2,
  },
  {
    id: 3,
    title: "Permits",
    image: commonImages.qrcode_rafiki3,
  },
  {
    id: 4,
    title: "Sampling Log",
    image: commonImages.qrcode_rafiki4,
  },
];
export const YES_NO = [
  { key: 1, value: "Yes" },
  { key: 2, value: "No" },
];
export const TIME_LIMIT_FOR_GROUND_COVER = [
  { key: 1, value: "7 Days" },
  { key: 2, value: "14 Days" },
];

export const ITEM_STATUS_NEW_VALUE = [
  { key: 2, value: "Open" },
  { key: 3, value: "Closed" },
  { key: 1, value: "All" },
];

export const ASSIGN_TOOL_BOX_TALK_DROPDOWN = [
  { key: 1, value: "Projects" },
  { key: 4, value: "Custom assets" },
  { key: "N/A", value: "N/A" },
];
export const SIGNIFICAT_DEVIATION = [
  { key: 1, value: "Yes" },
  { key: 2, value: "No" },
  { key: 3, value: "N/A" },
];

export const IS_RECORDALE = [
  { key: 1, value: "Yes" },
  { key: 2, value: "I'm Not Sure" },
  { key: 3, value: "Both" },
];
export const ORG_STATUS = () => localStorage.getItem("orgStatus");
