import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import FormControl from "@mui/material/FormControl";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import { InputField } from "../../../../library/custom/textBox/InputField";
import { NumberField } from "../../../../library/custom/numberField/numberField";
import Buttons from "../../../../library/custom/button/Button";
import TextArea from "../../../../library/custom/textArea/TextArea";

import {
  CONTACT_TEMPLATE_URL,
  COUNTRY_CODE,
  ORG_ID,
  TEXT_FIELD_ERROR_MSG,
  ISwitch,
  USER_ID,
  PRODUCT_LIST,
  ADMIN_CONTACT_TEMPLATE_URL,
  HBNEXT_ORG_ID,
} from "../../../../constant/constant";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import BulkUpload from "../../../../library/custom/bulkUpload/BulkUpload";
import { useNavigate } from "react-router-dom";
import Checkbox from "../../../../library/checkbox/checkbox";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import { PRODUCT_ID } from "../../../../constant/storm-water/constant";

export default function CreateContact({
  onSubmit,
  state,
  city,
  getCity,
  viewContactData,
  editMode,
  contactId,
  products,
  roles,
}) {
  const intialState = {
    contactId: 0,
    companyName: "",
    firstName: "",
    lastName: "",
    designation: "",
    phone: "",
    email: "",
    address1: "",
    address2: "",
    cityId: 0,
    stateId: 0,
    zipCode: "",
    officePhone: "",
    notes: "",
    createdBy: USER_ID(),
    modifiedBy: USER_ID(),
    organisationId: ORG_ID(),
    statusId: 0,
  };
  const [checked, setChecked] = useState(false);
  const [isStatusId, setIsStatusId] = useState(0);
  const navigate = useNavigate();
  const [product, setProduct] = useState([]);
  const [formValue, setFormValue] = useState(intialState);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isBulkUpload, setIsBulkUpload] = useState(false);
  const [checkBoxPrivilege, setcheckBoxPrivilege] = useState(false);
  const [isGlobal, setIsGlobal] = useState(false);
  const [selectUserRole, setSelectUserRole] = useState("");
  const [emailverify, setEmailverify] = useState(false);
  const [phoneverify, setPhoneverify] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    setIsStatusId(1);
  };

  const onChangeSelectUserRole = (e) => {
    setSelectUserRole(e.target.value);
  };

  const handleStateChange = (e) => {
    const stateId = e.target.value;
    getCity(stateId);
    setFormValue({
      ...formValue,
      stateId: e.target.value,
    });
  };

  useEffect(() => {
    if (products?.length === 1) {
      setProduct(products[0].key);
    }
  }, [products]);
  const [isCompanyName, setIsCompanyName] = useState(false);
  const [isFirstName, setIsFirstName] = useState(false);
  const [isLastName, setIsLastName] = useState(false);
  const [isDesignation, setDesignation] = useState(false);
  const [isPhone, setIsPhone] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [isZipCode, setIsZipCode] = useState(false);
  const [productError, setProductError] = useState(false);
  const [selectUserRoleError, setSelectUserRoleError] = useState(false);

  const superAdminRole = parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID);
  const selectedServiceTypeData = localStorage?.getItem("serviceType");
  const findValueMultipleSelect = (arrrayofObject, selectedArray) => {
    if (products.length > 1) {
      let valueArray = [];
      arrrayofObject.forEach((data) => {
        if (selectedArray.indexOf(data.value) >= 0) {
          valueArray.push(data.key);
        }
      });
      return valueArray;
    }
  };
  const selectedproducts = findValueMultipleSelect(products, product);

  const onChangeEmail = (e) => {
    setFormValue({ ...formValue, email: e.target.value });
    const isValidEmail = /^[\w-.+]+@([\w-]+\.)+[a-zA-Z]{2,}$/;
    if (e.target?.value && e.target.value.match(isValidEmail)) {
      setEmailverify(false);
    } else {
      setEmailverify(true);
    }
    setFormValue({ ...formValue, email: e.target.value });
    if (e.target.value == "") {
      setEmailverify(false);
    }
  };

  function mappedData() {
    let errors = false;
    if (formValue?.companyName.trim() === "") {
      setFormValue({
        ...formValue,
        companyName: "",
      });
      setIsCompanyName(true);
      errors = true;
    }
    if (editMode && checkBoxPrivilege && selectUserRole?.length === 0) {
      setSelectUserRoleError(true);
      errors = true;
    }
    if (formValue?.firstName.trim() === "") {
      setFormValue({
        ...formValue,
        firstName: "",
      });
      setIsFirstName(true);
      errors = true;
    }
    if (formValue?.lastName.trim() === "") {
      setFormValue({
        ...formValue,
        lastName: "",
      });
      setIsLastName(true);
      errors = true;
    }

    if (editMode && checkBoxPrivilege && formValue?.phone.trim() === "") {
      setFormValue({
        ...formValue,
        phone: "",
      });
      setIsPhone(true);
      errors = true;
    }
    if (formValue?.phone) {
      if (formValue?.phone?.length != 10) {
        setPhoneverify(true);
        errors = true;
      }
    }

    if (formValue?.phone?.length == 0) {
      setPhoneverify(false);
    }
    if (formValue?.email.trim() === "") {
      setFormValue({
        ...formValue,
        email: "",
      });
      setIsEmail(true);
      errors = true;
    }
    if (emailverify == true) {
      errors = true;
    }

    if (errors) {
      return false;
    }
    let findData = {};
    if (editMode) {
      findData = roles.find((role) => role.key === selectUserRole);
    }
    const data = {
      contactId: contactId ? contactId : formValue?.contactId,
      companyName: formValue?.companyName,
      firstName: formValue?.firstName,
      lastName: formValue?.lastName,
      designation: formValue?.designation,
      phone: formValue?.phone,
      email: formValue?.email,
      address1: formValue?.address1,
      address2: formValue?.address2,
      cityId: formValue?.cityId,
      stateId: formValue?.stateId,
      zipCode: formValue?.zipCode,
      officePhone: formValue?.officePhone,
      notes: formValue?.notes,
      createdBy: parseInt(USER_ID()),
      modifiedBy: parseInt(USER_ID()),
      organisationId: parseInt(ORG_ID()),
      isActive: checked,
      statusId: isStatusId,
      productIds: selectedproducts ? selectedproducts : [product],
      isGlobal: isGlobal,
    };
    const dataConvert = {
      contactId: contactId,
      email: formValue?.email,
      phoneNumber: formValue?.phone,
      isdCode: COUNTRY_CODE,
      roleId: findData?.key,
      createdBy: parseInt(USER_ID()),
    };
    if (checkBoxPrivilege) {
      return dataConvert;
    } else {
      return data;
    }
  }

  const submitData = () => {
    const data = mappedData();

    if (!data) {
    } else {
      setIsSubmit(true);
      if (checkBoxPrivilege) {
        onSubmit(data, checkBoxPrivilege);
      } else {
        onSubmit(editMode ? data : [data], checkBoxPrivilege);
      }
    }
  };

  useEffect(() => {
    if (viewContactData) {
      getCity(viewContactData?.stateId);
      if (viewContactData?.statusId === 1) {
        setChecked(true);
      } else {
        setChecked(false);
      }
      setFormValue({
        companyName: viewContactData?.companyName,
        firstName: viewContactData?.firstName,
        lastName: viewContactData?.lastName,
        designation: viewContactData?.designation,
        phone: viewContactData?.phone,
        email: viewContactData?.email,
        address1: viewContactData?.address1,
        address2: viewContactData?.address2,
        cityId: viewContactData?.cityId,
        stateId: viewContactData?.stateId,
        zipCode: viewContactData?.zipCode,
        officePhone: viewContactData?.officePhone,
        notes: viewContactData?.notes,
        statusId: viewContactData?.statusId,
      });
      {
        setProduct(viewContactData?.product?.map((item) => item.value));
        setIsGlobal(viewContactData?.isGlobal);
      }
    }
  }, [viewContactData]);

  const handleFileChanges = (data) => {
    const users = data.map((x, index) => {
      x.phonenumber = `${COUNTRY_CODE}${x.phonenumber}`;
      x.status = true;
      x.isphonenumberverified = true;
      x.isemailverified = true;
      // x.productIds = x.productIds === undefined ? [] : x.productIds.split(",");
      x.productIds = selectedproducts ? selectedproducts : [product];
      x.isGlobal = x.isGlobal === "TRUE" ? true : false;
      return x;
    });
    const usersData = {
      users,
    };
    // setMultipleUsers(data);
    onSubmit(usersData.users);
  };

  const mapBulkError = (data) => {
    let usersWithError = [];

    data.forEach((x) => {
      const isValidEmail = /^[\w-\.+]+@([\w-]+\.)+[\w-]{2,4}$/g;
      if (
        x.firstName?.trim() === undefined ||
        null ||
        "" ||
        x.lastName?.trim() === undefined ||
        null ||
        "" ||
        x.companyName?.trim() === undefined ||
        null ||
        "" ||
        x.email?.trim() === undefined ||
        null ||
        "" ||
        !x.email.match(isValidEmail)
      ) {
        usersWithError.push(x);
        return;
      }
    });
    return usersWithError;
  };

  const isShowClient =
    parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID) && checkPrivileges([12]);

  useEffect(() => {
    if (selectedServiceTypeData) {
      const newListData = products?.find(
        (x) => x.key == selectedServiceTypeData
      );

      setProduct(
        typeof newListData?.value === "string"
          ? newListData?.value.split(",")
          : newListData?.value
      );
    }
  }, [selectedServiceTypeData]);
  useEffect(() => {
    if (!formValue?.phone) {
      setPhoneverify(false);
    }
  }, [formValue?.phone]);

  return (
    <div className="overidePersonal">
      <Grid container item lg={9} xs={12} sm={12} md={9}>
        <Grid item lg={6} xs={12} sm={12} md={4} textAlign="left">
          <div className="heading_style">
            {editMode ? "Update Contact" : "Add Contact"}
          </div>
        </Grid>
        <Grid item lg={6} xs={12} sm={12} md={4} textAlign="right">
          {checkPrivileges([12, 40]) && !editMode ? (
            <Buttons
              aria-describedby="dd"
              variant="contained"
              type="button"
              name="btn"
              label={isBulkUpload ? "Single Contact" : "Multiple Contacts"}
              varientContained={true}
              onClick={() => setIsBulkUpload(!isBulkUpload)}
            ></Buttons>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
      <Grid item lg={12} xs={12} sm={12} md={4}>
        {isBulkUpload ? (
          <Grid container>
            <Grid item lg={9} xs={9} sm={9} md={9} mt={4}>
              <BulkUpload
                handleFileChanges={handleFileChanges}
                mapBulkError={mapBulkError}
                paraText1={"Drop the files here ..."}
                paraText2={"Drop the files here, "}
                paraText3={"or Browse"}
                templateUrl={
                  parseInt(PRODUCT_ID()) === 2 && isShowClient
                    ? ADMIN_CONTACT_TEMPLATE_URL
                    : CONTACT_TEMPLATE_URL
                }
                templateText="Download Template"
                pocFirstNameKey="firstName"
                pocLastNameKey="lastName"
                pocPhoneKey="phone"
                pocFirstNamelabel="FIRST NAME"
                pocLastNamelabel="LAST NAME"
                pocPhonelabel="PHONE"
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container item md={9} xs={9} className="addShadowBox">
            <Grid item container spacing={2}>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <InputField
                  disabled={checkBoxPrivilege}
                  type="text"
                  isRequired={true}
                  label="First Name"
                  value={formValue.firstName}
                  onChange={(e) =>
                    setFormValue({ ...formValue, firstName: e.target.value })
                  }
                  placeholder="Add First Name Here.."
                  isError={isFirstName}
                  errorMsg={isFirstName ? "First Name is Required" : ""}
                />
              </Grid>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <InputField
                  disabled={checkBoxPrivilege}
                  type="text"
                  isRequired={true}
                  label="Last Name"
                  value={formValue.lastName}
                  onChange={(e) =>
                    setFormValue({ ...formValue, lastName: e.target.value })
                  }
                  placeholder="Add Last Name Here.."
                  isError={isLastName}
                  errorMsg={isLastName ? "Last Name is Required" : ""}
                />
              </Grid>
            </Grid>
            <Grid item container mt={3} spacing={2}>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <InputField
                  disabled={checkBoxPrivilege}
                  type="text"
                  isRequired={true}
                  label="Company"
                  value={formValue.companyName}
                  placeholder="Add Company Name Here.."
                  onChange={(e) =>
                    setFormValue({
                      ...formValue,
                      companyName: e.target.value,
                    })
                  }
                  isError={isCompanyName}
                  errorMsg={isCompanyName ? "Company Name is Required" : ""}
                />
              </Grid>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <InputField
                  disabled={checkBoxPrivilege}
                  type="text"
                  label="Job Title"
                  value={formValue.designation}
                  onChange={(e) =>
                    setFormValue({
                      ...formValue,
                      designation: e.target.value,
                    })
                  }
                  placeholder="Add Job Title Here..."
                />
              </Grid>
            </Grid>
            <Grid item container mt={3} spacing={2}>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <FormControl fullWidth={true}>
                  <NumberField
                    type="text"
                    isRequired={checkBoxPrivilege}
                    label="Phone Number"
                    value={formValue.phone}
                    placeholder="Add Phone Number Here..."
                    maxLength="10"
                    onChange={(value) =>
                      value.length <= 10
                        ? setFormValue({
                            ...formValue,
                            phone: value,
                          })
                        : formValue.phone
                    }
                    isError={isPhone}
                    errorMsg={
                      isPhone && !formValue.phone ? TEXT_FIELD_ERROR_MSG : ""
                    }
                  />
                </FormControl>
                {phoneverify && formValue?.phone?.length != 10 && (
                  <div className="errorMsg">
                    Please enter 10 digit valid Phone Number
                  </div>
                )}
              </Grid>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <FormControl fullWidth={true}>
                  <InputField
                    type="text"
                    isRequired={true}
                    label="Email ID"
                    value={formValue.email}
                    placeholder="Add Email Here..."
                    onChange={(e) => onChangeEmail(e)}
                    isError={isEmail}
                    errorMsg={isEmail ? "Email is Required" : ""}
                  />
                </FormControl>
                {emailverify && (
                  <div className="errorMsg">
                    Please enter a valid Email address
                  </div>
                )}
              </Grid>
            </Grid>

            <Grid container mt={3}>
              <Grid item lg={12} xs={12} sm={12} md={12}>
                <FormLabels label="Address Line 1" />
                <InputField
                  disabled={checkBoxPrivilege}
                  type="text"
                  placeholder="Add Address 1 Here..."
                  value={formValue.address1}
                  onChange={(e) =>
                    setFormValue({ ...formValue, address1: e.target.value })
                  }
                />
              </Grid>
            </Grid>
            <Grid container mt={3}>
              <Grid item lg={12} xs={12} sm={12} md={12}>
                <FormLabels label="Address Line 2" />
                <InputField
                  disabled={checkBoxPrivilege}
                  type="text"
                  placeholder="Add Address 2 Here..."
                  value={formValue.address2}
                  onChange={(e) =>
                    setFormValue({ ...formValue, address2: e.target.value })
                  }
                />
              </Grid>
            </Grid>
            <Grid item container mt={3} spacing={2}>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <FormControl fullWidth>
                  <FormLabels label="State" />
                  <SelectMenu
                    placeholder="Please Select"
                    disabled={checkBoxPrivilege}
                    listData={state}
                    value={formValue.stateId}
                    onchangehandler={(e) => handleStateChange(e)}
                  />
                </FormControl>
              </Grid>

              <Grid item lg={6} xs={12} sm={12} md={6}>
                <FormControl fullWidth>
                  <FormLabels label="City" />
                  <SelectMenu
                    listData={city}
                    disabled={checkBoxPrivilege}
                    placeholder="Please Select"
                    value={formValue.cityId}
                    onchangehandler={(e) =>
                      setFormValue({
                        ...formValue,
                        cityId: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container mt={3} spacing={2}>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <NumberField
                  type="text"
                  disabled={checkBoxPrivilege}
                  isRequired={false}
                  label="Zip Code"
                  placeholder="Add Zip Code Here..."
                  maxLength="5"
                  value={formValue.zipCode}
                  onChange={(e) =>
                    e.length <= 5
                      ? setFormValue({ ...formValue, zipCode: e })
                      : formValue.zipCode
                  }
                />
              </Grid>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <NumberField
                  disabled={checkBoxPrivilege}
                  type="text"
                  label="Office Phone"
                  value={formValue.officePhone}
                  placeholder="Office Phone Number Here..."
                  maxLength="10"
                  onChange={(value) =>
                    value.length <= 10
                      ? setFormValue({
                          ...formValue,
                          officePhone: value,
                        })
                      : formValue.officePhone
                  }
                />
              </Grid>
            </Grid>

            <Grid container mt={3}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormControl fullWidth>
                  <FormLabels label="Add Notes" />
                  <TextArea
                    disabled={checkBoxPrivilege}
                    placeholder={"Add Notes Here..."}
                    value={formValue.notes}
                    onChange={(e) =>
                      setFormValue({ ...formValue, notes: e.target.value })
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid item container mt={2} spacing={3}>
              {parseInt(PRODUCT_ID()) === 2 && isShowClient ? (
                <Grid container mt={5} ml={4}>
                  <Grid lg={5} xs={12} sm={6} md={6}>
                    <div className="typeTxt">
                      <Checkbox
                        checked={isGlobal}
                        onChange={() => setIsGlobal(!isGlobal)}
                        label={"Make Contact Global"}
                      />
                    </div>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
              {editMode && checkBoxPrivilege && (
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <FormControl fullWidth>
                    <FormLabels label={"Select User Role"} isRequired={true} />
                    <SelectMenu
                      placeholder="Please Select"
                      listData={roles}
                      value={selectUserRole}
                      onchangehandler={(e) => onChangeSelectUserRole(e)}
                    />
                  </FormControl>
                  {selectUserRoleError && selectUserRole?.length === 0 && (
                    <div className="errorMsg">
                      Please enter this required field
                    </div>
                  )}
                </Grid>
              )}
            </Grid>

            <Grid container mt={1}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {checkPrivileges([12, 40]) && editMode && (
                  <FormControl
                    component="fieldset"
                    variant="standard"
                    className="contactswitch"
                  >
                    <FormLabel component="status">
                      Select Contact Status
                    </FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <ISwitch
                            checked={checked}
                            onChange={handleChange}
                            name="status"
                            disabled={checkBoxPrivilege}
                          />
                        }
                        label={checked ? "Active" : "InActive"}
                      />
                    </FormGroup>
                  </FormControl>
                )}
              </Grid>
            </Grid>

            <Grid
              container
              mt={3}
              spacing={1}
              alignItems="right"
              className="addcontactbtn"
            >
              {editMode && (
                <Grid lg={5} xs={12} sm={6} md={6}>
                  <div className="typeTxt">
                    <Checkbox
                      checked={checkBoxPrivilege}
                      onChange={() => setcheckBoxPrivilege(!checkBoxPrivilege)}
                      label={"Convert to Privileged User"}
                    />
                  </div>
                </Grid>
              )}

              {/* {!editMode && (
                <Grid
                  item
                  lg={5}
                  xs={12}
                  sm={12}
                  md={4}
                  textAlign="left"
                ></Grid>
              )} */}
              <Grid lg={3} xs={12} sm={6} md={6}>
                <Buttons
                  varientText={true}
                  label="Cancel"
                  onClick={() => navigate(-1)}
                />
              </Grid>
              <Grid lg={4} xs={12} sm={6} md={6}>
                <Buttons
                  varientContained={true}
                  label={editMode ? "Update Contact" : "Add Contact"}
                  onClick={submitData}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
