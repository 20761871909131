import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import {
  ORG_ID,
  PAGE_SIZE,
  LOGIN_ORG_ID,
  HBNEXT_ORG_ID,
} from "../../../../constant/constant";
import { TableLoader } from "../../../../library/common/Loader";
import NORow from "../../../../library/custom/noRow/noRow";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import ReportRowManipulte from "../../../incidents/incidentList/ReportRowManipulte";
import {
  ADMIN_ADD_SITE,
  ADMIN_VIEW_SITE,
} from "../../../../constant/routeContant";
import { useNavigate } from "react-router-dom";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import SearchAutoComplete from "../../../../library/custom/searchAutoComplete/SearchAutoComplete";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import SiteFilter from "./siteFilter";
import { getFormatedDate, InactiveClient } from "../../../../utils/helper";

export default function SiteList({
  siteLists,
  siteListData,
  isLoading,
  searchClient,
  autoSearchDataLoading,
  searchAllClientsReducerData,
}) {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [clients, setClients] = useState();
  const [status, setStatus] = useState();

  const isShowClient =
    parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID) && checkPrivileges([12]);

  const [siteListApiData, setSiteListApiData] = useState({
    page: 1,
    pageSize: PAGE_SIZE,
    customerId: ORG_ID(),
    startDate: "",
    endDate: "",
    search: "",
    status: status ? status : "",
  });
  const [changesearchauto, setchangesearchauto] = useState({
    key: "",
    value: "",
  });
  const onSelectSearchCustomer = (e, value, s) => {
    setchangesearchauto({ key: value?.key ?? "", value: value?.value ?? "" });
    const data = {
      page: 1,
      pageSize: PAGE_SIZE,
      customerId: value?.key ? value.key : "",
      startDate: "",
      endDate: "",
      search: "",
      status: status ? status : "",
    };
    siteLists(data);
    setClients(value?.key);
  };

  const typeOnchange = (event) => {
    if (event.target?.value?.trim()) {
      searchClient(event.target?.value?.trim());
    }
  };
  const handleSearchs = (data) => {
    setStatus(data?.status);
    setAnchorEl(null);
    let filterdata = data;
    filterdata["startDate"] = filterdata?.startDate
      ? getFormatedDate(filterdata?.startDate)
      : "";
    filterdata["endDate"] = filterdata?.endDate
      ? getFormatedDate(filterdata?.endDate)
      : "";
    siteLists(filterdata);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    window.close();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    const data = {
      ...siteListApiData,
      page: newPage + 1,
      status: status ? status : "",
    };
    setSiteListApiData({
      ...siteListApiData,
      page: newPage + 1,
      status: status ? status : "",
    });
    siteLists(data);
  };

  const handleautoSearch = (event) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      ...siteListApiData,
      search: searchText ? searchText : "",
    };
    setSiteListApiData({
      ...siteListApiData,
      search: searchText ? searchText : "",
    });
    siteLists(data);
  };

  const handleOnEditclick = (rows) => {
    if (InactiveClient()) return;
    navigate(ADMIN_ADD_SITE, {
      state: { siteid: rows.row.siteId, editMode: true, viewMode: false },
    });
  };
  const handleOnViewclick = (rows) => {
    navigate(ADMIN_VIEW_SITE, {
      state: {
        siteid: rows.row.siteId,
        editMode: true,
        viewMode: true,
        isGlobal: rows.row.isGlobal,
      },
    });
  };

  const onRowClick = (rows) => {
    if (rows.value) {
      navigate(ADMIN_VIEW_SITE, {
        state: { siteid: rows.row.siteId, editMode: true, viewMode: true },
      });
    }
  };

  const credcloumns = [
    { field: "name", headerName: "Name", flex: 3.5 },
    { field: "activeProjects", headerName: "Active Projects", flex: 4 },
    { field: "address", headerName: "Address", flex: 3 },
    { field: "city", headerName: "City", flex: 3 },
    { field: "state", headerName: "State", flex: 3 },
    {
      field: "status",
      headerName: "Status",
      renderCell: (rows) => {
        return <div>{rows?.row?.isActive ? "Active" : "InActive"}</div>;
      },
      flex: 2.5,
    },
    {
      flex: 2,
      field: "isGlobal",
      headerName: "Global",
      renderCell: (rows) => {
        return <div>{rows?.row?.isGlobal === true ? "Global" : "N/A"}</div>;
      },
    },

    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={siteListData?.data?.siteCount ?? 0}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={siteListData?.data?.sitesData ?? []}
          fileNameXL={"SiteList"}
        />
      ),
      width: 80,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <ReportRowManipulte
            rows={rows}
            credentialLists={true}
            editOnClick={() => handleOnEditclick(rows)}
            viewOnClick={() => handleOnViewclick(rows)}
            edit={
              (parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID) ||
                !rows?.row?.isGlobal) &&
              checkPrivileges([12, 108, 109])
            }
            editLabel="Edit Site"
            download={false}
            view={true}
            viewLabel="View Site"
          />
        );
      },
    },
  ];

  return (
    <>
      <section className="grid_main_sec">
        <div className="grid_main_body">
          <Grid item container mt={2} mb={3}>
            {isShowClient && (
              <Grid item lg={4} xs={12} sm={12} md={6}>
                <FormControl fullWidth>
                  <FormLabels label="Client" isRequired={false} />
                  <SearchAutoComplete
                    name="Customer"
                    optionsData={
                      autoSearchDataLoading
                        ? []
                        : searchAllClientsReducerData?.data ?? []
                    }
                    typeOnchange={(event, value) => typeOnchange(event)}
                    isKeyValuePair={true}
                    value={changesearchauto}
                    onChange={(event, value, key) =>
                      onSelectSearchCustomer(event, value, key)
                    }
                    isLoading={autoSearchDataLoading}
                    noOptionsTextMsg="No Client"
                  />
                </FormControl>
              </Grid>
            )}
          </Grid>

          <div className="filter_of_caselist">
            <div className="searchcaseid">
              <label htmlFor="search">
                <span>
                  <SearchIcon />
                </span>
                <div>
                  <input
                    id="search"
                    placeholder="Search Sites"
                    type="text"
                    value={search}
                    className=""
                    onChange={handleautoSearch}
                    autoComplete="off"
                  />
                </div>
              </label>
            </div>

            <div className="filter_btn">
              <button
                aria-describedby={id}
                variant="contained"
                type="button"
                name="btn"
                onClick={(e) => handleClick(e)}
              >
                <FilterListIcon id="icn" /> Filters
              </button>
              <SiteFilter
                id={id}
                open={open}
                anchorEl={anchorEl}
                handleSearch={handleSearchs}
                handleClose={handleClose}
                issite={true}
                isSiteDate={false}
              />
            </div>
          </div>

          <div className="desc_box row_uniq">
            <div className="table_style_ui">
              <GridTable
                getRowId={(r) => r.siteId}
                rows={siteListData?.data?.sitesData ?? []}
                rowCount={siteListData?.data?.siteCount ?? 0}
                columns={credcloumns}
                loading={isLoading}
                checkboxSelection={false}
                page={page}
                pagination
                pageSize={PAGE_SIZE}
                onClick={(rows) => onRowClick(rows)}
                rowsPerPageOptions={[PAGE_SIZE]}
                onPageChange={handlePagignation}
                backIconButtonProps={{ disabled: false }}
                disableSelectionOnClick
                components={{
                  LoadingOverlay: TableLoader,
                  NoRowsOverlay: NORow,
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
