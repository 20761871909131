import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as userAction from "../../../redux/actions/userAction";
import * as clientAction from "../../../redux/actions/clientAction";
import { ORG_ID, PAGE_SIZE } from "../../../constant/constant";
import UserBouncedEmailList from "../component/users/userBouncedEmailList";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { ACCESS_DENIED } from "../../../constant/routeContant";
import { useNavigate } from "react-router-dom";

function UserBouncedEmailContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const bouncedEmailList = useSelector((state) => state.bouncedEmailList);
  const searchAllClientsData = useSelector(
    (state) => state.searchAllClientsReducerData
  );
  const autoSearchClientLoading = searchAllClientsData.isLoading;

  const getBouncedList = (data) => {
    dispatch(userAction.getBouncedList(data));
  };
  const searchClient = (searchText) => {
    dispatch(clientAction.searchAllClients(searchText));
  };

  useEffect(() => {
    const data = {
      page: 1,
      pageSize: PAGE_SIZE,
      search: "",
      orgId: ORG_ID(),
      bounceType: "",
      fromDate: "",
      toDate: "",
    };
    getBouncedList(data);
  }, []);

  return (
    <>
      {checkPrivileges([12]) ? (
        <UserBouncedEmailList
          loading={bouncedEmailList.isLoading}
          bouncedEmailListData={bouncedEmailList?.data}
          getBouncedList={getBouncedList}
          searchClient={searchClient}
          autoSearchDataLoading={autoSearchClientLoading}
          searchAllClientsData={searchAllClientsData}
        />
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
}

export default UserBouncedEmailContainer;
