import React, { useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import {
  ORG_ID,
  ORG_NAME,
  PAGE_SIZE,
  ROW_PER_PAGE_SIZE,
  USER_EMAIL_BOUNCE_TYPE,
} from "../../../../constant/constant";
import "./userBouncedEmailList.scss";
import FilterButton from "../../../../library/custom/filterButton/filterButton";
import FilterList from "../../../case/shared/FilterCaseList";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import { TableLoader } from "../../../../library/common/Loader";
import NORow from "../../../../library/custom/noRow/noRow";
import { FormControl } from "@mui/material";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import SearchAutoComplete from "../../../../library/custom/searchAutoComplete/SearchAutoComplete";
import { getFormatedDate } from "../../../../utils/helper";
import SettingTable from "../../../case/shared/SettingTable";

const UserBouncedEmailList = ({
  bouncedEmailListData,
  loading,
  getBouncedList,
  searchClient,
  autoSearchDataLoading,
  searchAllClientsData,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const autoCompClient = useRef(null);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [search, setSearch] = useState("");
  const [bounceType, setBounceType] = useState("");
  const [changesearchautoClient, setChangesearchautoClient] = useState({
    key: ORG_ID(),
    value: ORG_NAME(),
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    window.close();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const dateFromHandler = (e) => {
    setDateFrom(e);
  };

  const dateToHandler = (e) => {
    setDateTo(e);
  };

  const handleautoSearch = (event) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      page: 1,
      pageSize: PAGE_SIZE,
      search: searchText ? searchText : "",
      orgId: changesearchautoClient?.key || ORG_ID(),
      bounceType: bounceType ? bounceType : "",
      fromDate: getFormatedDate(dateFrom),
      toDate: getFormatedDate(dateTo),
    };
    getBouncedList(data);
  };

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    const data = {
      page: newPage + 1,
      pageSize: PAGE_SIZE,
      search: search ? search : "",
      orgId: changesearchautoClient?.key || ORG_ID(),
      bounceType: bounceType ? bounceType : "",
      fromDate: getFormatedDate(dateFrom),
      toDate: getFormatedDate(dateTo),
    };
    getBouncedList(data);
  };

  const handlePageSizeChange = (newPazeSize) => {
    setPageSize(newPazeSize);
    const data = {
      page: page,
      pageSize: newPazeSize,
      search: search ? search : "",
      orgId: changesearchautoClient?.key || ORG_ID(),
      bounceType: bounceType ? bounceType : "",
      fromDate: getFormatedDate(dateFrom),
      toDate: getFormatedDate(dateTo),
    };
    getBouncedList(data);
  };

  const columns = [
    { field: "emailID", headerName: "Email ID", flex: 2.5 },
    { field: "userName", headerName: "User Name", flex: 2 },
    { field: "userType", headerName: "User Type", flex: 2 },
    { field: "clientName", headerName: "Client Name", flex: 2 },
    { field: "bounceType", headerName: "Bounce Type", flex: 2 },
    {
      field: "date",
      headerName: "Date",
      flex: 2.5,
      renderCell: (rows) => {
        return (
          <div>{rows.row?.date ? getFormatedDate(rows.row?.date) : ""}</div>
        );
      },
    },
    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={bouncedEmailListData?.recordsCount ?? 0}
          numberOfItemsPerPage={pageSize}
          incidentLists={bouncedEmailListData?.records ?? []}
          fileNameXL={"bounced-email-list"}
        />
      ),
      width: 70,
      sortable: false,
      disableClickEventBubbling: true,
    },
  ];
  const onChangeSelectBounceType = (event) => {
    setBounceType(event.target.value);
  };

  const handleClear = () => {
    setAnchorEl(null);
    window.close();
    setBounceType("");
    setDateTo("");
    setDateFrom("");
    const data = {
      page: 1,
      pageSize: PAGE_SIZE,
      search: "",
      orgId: changesearchautoClient?.key || ORG_ID(),
      bounceType: "",
      fromDate: "",
      toDate: "",
    };
    getBouncedList(data);
  };

  const handleApply = () => {
    setAnchorEl(null);
    window.close();
    const data = {
      page: page,
      pageSize: pageSize,
      search: search ? search : "",
      orgId: changesearchautoClient?.key || ORG_ID(),
      bounceType: bounceType ? bounceType : "",
      fromDate: getFormatedDate(dateFrom),
      toDate: getFormatedDate(dateTo),
    };
    getBouncedList(data);
  };

  const typeOnchange = (event) => {
    if (event.target?.value?.trim()) {
      searchClient(event.target?.value?.trim());
    }
  };

  const onSelectSearchCustomer = (e, value, s) => {
    setChangesearchautoClient({
      key: value?.key ?? "",
      value: value?.value ?? "",
    });
    const data = {
      page: page,
      pageSize: pageSize,
      orgId: value?.key || ORG_ID(),
      search: search ? search : "",
      bounceType: bounceType ? bounceType : "",
      fromDate: dateFrom,
      toDate: dateTo,
    };
    getBouncedList(data);
  };

  return (
    <>
      <section className="grid_main_sec">
        <div className="grid_main_header two_grid">
          <div className="grid_heading_style">Bounced Email List</div>
        </div>
        <div className="bounced_email_search">
          <FormControl fullWidth>
            <FormLabels label="Client" isRequired={false} />
            <SearchAutoComplete
              name="Customer"
              optionsData={
                autoSearchDataLoading ? [] : searchAllClientsData?.data ?? []
              }
              typeOnchange={(event, value) => typeOnchange(event)}
              isKeyValuePair={true}
              value={changesearchautoClient ? changesearchautoClient : ""}
              onChange={(event, value, key) =>
                onSelectSearchCustomer(event, value, key)
              }
              isLoading={autoSearchDataLoading}
              noOptionsTextMsg="No Client"
              autoCompClear={autoCompClient}
            />
          </FormControl>
        </div>
        <div className="grid_main_body">
          <div className="div-filter-container">
            <div className="filter_of_caselist">
              <div className="searchcaseid">
                <label htmlFor="search">
                  <span>
                    <SearchIcon />
                  </span>
                  <div>
                    <input
                      id="search"
                      placeholder="Search Client"
                      type="text"
                      value={search}
                      className=""
                      onChange={handleautoSearch}
                      autoComplete="off"
                    />
                  </div>
                </label>
              </div>
              <div className="filterbtn">
                <FilterButton
                  label={"Filters"}
                  handleClick={(e) => handleClick(e)}
                />
                <FilterList
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  dateOfCaseFrom={dateFrom}
                  dateOfCaseHandlerFrom={dateFromHandler}
                  dateOfCaseTo={dateTo}
                  dateOfCaseHandlerTO={dateToHandler}
                  resetFilter={handleClear}
                  handleSearch={handleApply}
                  caseStatus={bounceType}
                  caseListStatus={USER_EMAIL_BOUNCE_TYPE}
                  caseLabel="Bounce Type"
                  onChangeSelectUserType={onChangeSelectBounceType}
                />
              </div>
            </div>
          </div>
          <div className="desc_box row_uniq">
            <div
              className="table_style_ui"
              style={{ height: 400, width: "100%" }}
            >
              <GridTable
                getRowId={(r) => r.emailID}
                rows={bouncedEmailListData?.records || []}
                rowCount={bouncedEmailListData?.recordsCount}
                columns={columns}
                loading={loading}
                checkboxSelection={false}
                page={page}
                pagination
                pageSize={pageSize}
                rowsPerPageOptions={ROW_PER_PAGE_SIZE}
                onPageSizeChange={handlePageSizeChange}
                onPageChange={handlePagignation}
                backIconButtonProps={{ disabled: false }}
                disableSelectionOnClick
                components={{
                  LoadingOverlay: TableLoader,
                  NoRowsOverlay: NORow,
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UserBouncedEmailList;
