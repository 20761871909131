import React, { useEffect } from "react";
import SiteList from "../components/siteList";
import SiteProjectHeader from "../components/siteProjectHeader";
import {
  ADMIN_ADD_SITE,
  ADMIN_SITE_PROJECTS_LIST,
  ADMIN_PROJECTS_LIST,
  ACCESS_DENIED,
} from "../../../../constant/routeContant";
import { useDispatch, useSelector } from "react-redux";
import * as siteAction from "../../../../redux/storm-water/actions/siteAction";
import { LOGIN_ORG_ID, ORG_ID, PAGE_SIZE } from "../../../../constant/constant";
import * as clientAction from "../../../../redux/actions/clientAction";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

function SiteListContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dataHeader = {
    title: LOGIN_ORG_ID() == 1 ? "Sites and Projects" : "Projects",
    btnLink: ADMIN_ADD_SITE,
    menu: [
      {
        route: ADMIN_SITE_PROJECTS_LIST,
        text: "Sites",
        isshow: checkPrivileges([12, 108, 109, 110]),
      },
      {
        route: ADMIN_PROJECTS_LIST,
        text: "Projects",
        isshow: checkPrivileges([12, 111, 112, 113]),
      },
    ],
  };
  if (checkPrivileges([12, 108, 109])) {
    dataHeader.btnText = "Add Site";
  }
  // LOGIN_ORG_ID() == 1 &&
  //   checkPrivileges([12, 108, 109, 110]) &&
  //   dataHeader.menu.unshift({
  //     route: ADMIN_SITE_PROJECTS_LIST,
  //     text: "Sites",
  //   });
  const siteListData = useSelector((state) => state.siteListData);

  const siteLists = (data) => {
    dispatch(siteAction.siteList(data));
  };
  const defaultData = {
    page: 1,
    pageSize: PAGE_SIZE,
    customerId: ORG_ID(),
    startDate: "",
    endDate: "",
    search: "",
    status: "",
  };
  useEffect(() => {
    siteLists(defaultData);
  }, []);
  const searchAllClientsReducerData = useSelector(
    (state) => state.searchAllClientsReducerData
  );
  const autoSearchDataLoading = searchAllClientsReducerData.isLoading;

  const searchClient = (searchText) => {
    dispatch(clientAction.searchAllClients(searchText));
  };

  return (
    <>
      {checkPrivileges([12, 108, 109, 110]) ? (
        <>
          <SiteProjectHeader dataHeader={dataHeader} />
          <SiteList
            siteLists={siteLists}
            siteListData={siteListData}
            isLoading={siteListData.isLoading}
            searchClient={searchClient}
            autoSearchDataLoading={autoSearchDataLoading}
            searchAllClientsReducerData={searchAllClientsReducerData}
          />
          <ToastContainer />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
}

export default SiteListContainer;
