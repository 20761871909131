import { getApiUrl } from "../config";
import { ORG_ID, USER_ID } from "../constant/constant";
import { get, post } from "./axiosRequest";

export const addJhaCategory = async ({ data }) => {
  const url = `${getApiUrl().adminApiUrl}/JHAConfiguration/AddJHACategory`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getJhaCategoryList = async ({ data }) => {
  const { pageSize, page, search, isAll = 1 } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/JHAConfiguration/GetJHACategoriesList?search=${search}${
    page === undefined ? "" : `&page=${page}`
  }${pageSize === undefined ? "" : `&pageSize=${pageSize}`}&isAll=${isAll}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateJhaCategory = async ({ data }) => {
  const url = `${getApiUrl().adminApiUrl}/JHAConfiguration/UpdateJHACategory`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const addJhaActivity = async ({ data }) => {
  const url = `${getApiUrl().adminApiUrl}/JHAConfiguration/AddActivity`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getJhaActivtyList = async ({ data }) => {
  const { categoryId, pageSize, page, search, isAll = 1 } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/JHAConfiguration/GetActivitiesList?categoryId=${categoryId}&search=${search}${
    page === undefined ? "" : `&page=${page}`
  }${pageSize === undefined ? "" : `&pageSize=${pageSize}`}&isAll=${isAll}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateJhaActivity = async ({ data }) => {
  const url = `${getApiUrl().adminApiUrl}/JHAConfiguration/UpdateActivity`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const addJhaHazard = async ({ data }) => {
  const url = `${getApiUrl().adminApiUrl}/JHAConfiguration/AddHazards`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getJhaHazardsList = async ({ data }) => {
  const { activityId, pageSize, page, search, status = "" } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/JHAConfiguration/GetHazardsList?activityId=${activityId}&status=${status}&search=${search}${
    page === undefined ? "" : `&page=${page}`
  }${pageSize === undefined ? "" : `&pageSize=${pageSize}`}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateJhaHazard = async ({ data }) => {
  const url = `${getApiUrl().adminApiUrl}/JHAConfiguration/UpdateHazards`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getJhaControlList = async ({ data }) => {
  const { pageSize, page, search, hazardId, status = "" } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/JHAConfiguration/GetControlsList?hazardId=${hazardId}${
    page === undefined ? "" : `&page=${page}`
  }${pageSize === undefined ? "" : `&pageSize=${pageSize}`}&status=${status}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const addJhaControl = async ({ data }) => {
  const url = `${getApiUrl().adminApiUrl}/JHAConfiguration/AddControls`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateJhaControl = async ({ data }) => {
  const url = `${getApiUrl().adminApiUrl}/JHAConfiguration/UpdateControls`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getConductJhalList = async ({ data }) => {
  const {
    pageSize,
    page,
    search,
    endDate,
    userId,
    startDate,
    assetType,
    isSendMail,
  } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/JHAConfiguration/GetJHAConductList?userId=${userId}&isSendMail=${isSendMail}&assetType=${assetType}&startDate=${startDate}&endDate=${endDate}&search=${search}&page=${page}&pageSize=${pageSize}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const addJhaTemplate = async ({ data }) => {
  const url = `${getApiUrl().adminApiUrl}/JHAConfiguration/AddJHATemplate`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getJhaTemplateList = async ({ data }) => {
  const { pageSize, page, search, assetType = 0 } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/JHAConfiguration/GetJHATemplateList?search=${search}&assetType=${assetType}${
    page === undefined ? "" : `&page=${page}`
  }${pageSize === undefined ? "" : `&pageSize=${pageSize}`}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateJhaTemplateStatus = async ({ data }) => {
  const url = `${
    getApiUrl().adminApiUrl
  }/JHAConfiguration/ActiveInactiveJHATemplate`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getJhaControlTypeList = async ({ data }) => {
  const { pageSize, page, search } = data;
  const url = `${getApiUrl().adminApiUrl}/JHAConfiguration/GetControlTypeList`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getJhaControlDetails = async ({ data }) => {
  const { controlId } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/JHAConfiguration/ControlDetails?controlId=${controlId}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const addJhaConduct = async ({ data }) => {
  const url = `${getApiUrl().adminApiUrl}/JHAConfiguration/AddJHAConduct`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getJhaTempleteDetsils = async ({ data }) => {
  console.log("data", data);
  const url = `${
    getApiUrl().adminApiUrl
  }/JHAConfiguration/GetJHATemplateDetail?TemplateId=${data}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getJhaConductDetails = async ({ data }) => {
  const url = `${
    getApiUrl().adminApiUrl
  }/JHAConfiguration/JHAConductDetail?conductId=${data}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
export const updateJhaTemplate = async ({ data }) => {
  const url = `${getApiUrl().adminApiUrl}/JHAConfiguration/UpdateJHATemplate`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const addJHASetup = async ({ data }) => {
  const url = `${getApiUrl().adminApiUrl}/JHAConfiguration/AddJHASetup`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getJhaSetupList = async ({ data }) => {
  const {
    userId = USER_ID(),
    assetType,
    frequencyId,
    statusId,
    pageSize,
    page,
    search,
  } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/JHAConfiguration/GetJHASetupList?userId=${userId}${
    assetType && `&assetType=${assetType}`
  }${frequencyId && `&frequencyId=${frequencyId}`}${
    statusId && `&statusId=${statusId}`
  }&search=${search}&page=${page}&pageSize=${pageSize}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const activeInactiveJHASetup = async ({ data }) => {
  const url = `${
    getApiUrl().adminApiUrl
  }/JHAConfiguration/ActiveInactiveJHASetup`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getJHAReferenceData = async ({ data }) => {
  const url = `${
    getApiUrl().adminApiUrl
  }/JHAConfiguration/GetReferenceData?isActive=true`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getJHASetupDetail = async ({ data }) => {
  const url = `${
    getApiUrl().adminApiUrl
  }/JHAConfiguration/JHASetupDetail?setupId=${data}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateJHASetup = async ({ data }) => {
  const url = `${getApiUrl().adminApiUrl}/JHAConfiguration/UpdateJHASetup`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getJHASetupDashboard = async ({ data }) => {
  const { userId = USER_ID(), pageSize, page } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/JHAConfiguration/GetJHADashboard?userId=${userId}&page=${page}&pageSize=${pageSize}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getSaftyLogJHAConductList = async ({ data }) => {
  const { userId = USER_ID(), pageSize, page, isSendMail } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/JHAConfiguration/GetSaftyLogJHAConductList?userId=${userId}&page=${page}&pageSize=${pageSize}&isSendMail=${
    isSendMail ? isSendMail : false
  }`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getJhaActiveTemplateList = async ({ data }) => {
  const { assetType = 0 } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/JHAConfiguration/GetJHATemplate?assetType=${assetType}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
